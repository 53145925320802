<template>
  <vs-alert
    :active="showStartTime"
    icon-pack="feather"
    icon="icon-clock">
    {{ startTimeMsg }}
  </vs-alert>
</template>

<script>
import moment from 'moment';
import 'moment-timezone';

export default {
  name: 'CampaignScheduleTimeAlert',
  i18n: {
    messages: {
      en: {
        $CampaignSendTime: 'The campaign will be sent approximately in {time}',
        $CampaignSendImmediately: 'The campaign will be sent immediately',
        $QuickCampaignSendTime: 'The message will be sent approximately in {time}',
        $QuickCampaignSendImmediately: 'The message will be sent immediately',
      },
    },
  },
  props: {
    localStartDate: {
      type: String,
      required: true,
    },
    timeZone: {
      type: String,
      required: true,
    },
    isQuick: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      currentDate: new Date(),
      currentDateIntervalId: null,
    };
  },
  computed: {
    showStartTime() {
      return !!this.localStartDate && !!this.timeZone;
    },
    startOffsetInMinutes() {
      return this.showStartTime
        ? this.getStartOffset('minutes')
        : 0;
    },
    startOffsetInHours() {
      return this.showStartTime
        ? this.getStartOffset('hours')
        : 0;
    },
    startOffsetInDays() {
      return this.showStartTime
        ? this.getStartOffset('days')
        : 0;
    },
    startOffsetInWeeks() {
      return this.showStartTime
        ? this.getStartOffset('weeks')
        : 0;
    },
    startOffsetInMonths() {
      return this.showStartTime
        ? this.getStartOffset('months')
        : 0;
    },
    startOffsetInYears() {
      return this.showStartTime
        ? this.getStartOffset('years')
        : 0;
    },
    startTimeFormat() {
      if (this.startOffsetInHours < 1) {
        return `${this.startOffsetInMinutes} minute${this.startOffsetInMinutes > 1 ? 's' : ''}`;
      }

      if (this.startOffsetInDays < 1) {
        return this.getStartOffsetHumanText(
          this.startOffsetInHours,
          'hour',
          this.startOffsetInMinutes,
          'minute',
          60,
        );
      }

      if (this.startOffsetInWeeks < 1) {
        return this.getStartOffsetHumanText(
          this.startOffsetInDays,
          'day',
          this.startOffsetInHours,
          'hour',
          24,
        );
      }

      if (this.startOffsetInMonths < 1) {
        return this.getStartOffsetHumanText(
          this.startOffsetInWeeks,
          'week',
          this.startOffsetInDays,
          'day',
          7,
        );
      }

      if (this.startOffsetInYears < 1) {
        return this.getStartOffsetHumanText(
          this.startOffsetInMonths,
          'month',
          this.startOffsetInWeeks,
          'week',
          4,
        );
      }

      return this.getStartOffsetHumanText(
        this.startOffsetInYears,
        'year',
        this.startOffsetInMonths,
        'month',
        12,
      );
    },
    campaignWillStartImmediately() {
      return this.startOffsetInMinutes <= 0;
    },
    startTimeMsg() {
      const prefix = this.isQuick ? 'Quick' : '';

      return this.campaignWillStartImmediately
        ? this.$t(`$${prefix}CampaignSendImmediately`)
        : this.$t(`$${prefix}CampaignSendTime`, { time: this.startTimeFormat });
    },
  },
  watch: {
    campaignWillStartImmediately: {
      immediate: true,
      handler(val) {
        this.$emit('start-immediately', val);
      },
    },
  },
  created() {
    this.currentDateIntervalId = setInterval(() => {
      this.updateCurrentDate();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.currentDateIntervalId);
  },
  methods: {
    updateCurrentDate() {
      this.currentDate = new Date();
    },
    getStartOffsetHumanText(majorUnit, majorUnitText, minorUnit, minorUnitText, convFactor) {
      const $majorUnitText = `${majorUnitText}${majorUnit > 1 ? 's' : ''}`;
      const $minorUnit = minorUnit - (majorUnit * convFactor);
      const $minorUnitText = ` ${minorUnitText}${$minorUnit > 1 ? 's' : ''}`;
      let text = `${majorUnit} ${$majorUnitText}`;

      if ($minorUnit > 0) {
        text += ` ${$minorUnit} ${$minorUnitText}`;
      }

      return text;
    },
    getStartOffset(unit) {
      const currentDate = moment.tz(this.currentDate, this.timeZone);
      const startDate = moment.tz(this.localStartDate, this.timeZone);

      return startDate.utc().diff(currentDate.utc(), unit);
    },
  },
};
</script>

<style scoped>

</style>
