<template>
  <vx-card :no-shadow="!cardStyle">
    <form-wizard
      ref="wizard"
      class="wizard-no-title"
      color="rgba(var(--vs-primary), 1)"
      errorColor="rgba(var(--vs-danger), 1)"
      transition="zoom-fade"
      :title="null"
      :subtitle="null"
      :start-index="0"
      @on-change="onChangeTab">

      <tab-content
        :title="$t('$ReviewsSettingsModule.$BusinessTab.TabTitle')"
        class="mb-5 pt-4"
        icon="fas fa-store"
        :before-change="validateBusinessSelection">
        <div>
          <business-tab
            @place-updated="placeUpdated"/>
        </div>
      </tab-content>

      <tab-content
        :title="$t('$ReviewsSettingsModule.$ConfirmBusinessTab.TabTitle')"
        class="mb-5 pt-4"
        icon="fas fa-check-double"
        :before-change="saveReviewsSettings"
        lazy>
        <div
          v-if="tabIndex === 1"
          class="lg:px-32">
          <confirm-business-tab
            :place-id="placeId"
            :place-name="placeName"
            :place-address="placeAddress"
            @back="$refs.wizard.prevTab()"
            @continue="$refs.wizard.nextTab()"/>
        </div>
      </tab-content>

      <tab-content
        :title="$t('$ReviewsSettingsModule.$SetupCompletedTab.TabTitle')"
        class="mb-5 pt-4"
        icon="fas fa-star-half-alt"
        lazy>
        <div
          v-if="tabIndex === 2"
          class="lg:px-32">
          <setup-completed-tab
            :rating="placeRating"
            :review-link="`https://search.google.com/local/writereview?placeid=${this.placeId}`"
            :hide-request-review-button="hideRequestReviewButton"
            @request-reviews="requestReviews()"/>
        </div>
      </tab-content>

      <template
        slot="footer"
        slot-scope="props">
        <wizard-buttons
          :wizard-props="props"
          @cancel="$emit('cancel')"/>
      </template>
    </form-wizard>
  </vx-card>
</template>

<script>
import { mapActions } from 'vuex';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';

// Components
import ReviewsSettingsCreateOrEditWizardButtons from '@/views/modules/reviews/ReviewsSettingsCreateOrEditWizardButtons.vue';
import ReviewsSettingsCreateOrEditBusinessTab from '@/views/modules/reviews/ReviewsSettingsCreateOrEditBusinessTab.vue';
import ReviewsSettingsCreateOrEditConfirmBusinessTab from '@/views/modules/reviews/ReviewsSettingsCreateOrEditConfirmBusinessTab.vue';
import ReviewsSettingsCreateOrEditSetupCompletedTab from '@/views/modules/reviews/ReviewsSettingsCreateOrEditSetupCompletedTab.vue';

/**
 * Reviews settings create or edit
 *
 * @module views/modules/reviews/ReviewsSettingsCreateOrEdit
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} [cardStyle=true] - indicate if user card style or no
 * @vue-data {boolean} [hideRequestReviewButton=false] - indicate if hide request review button
 * @vue-data {string} [placeId=''] - place id of business
 * @vue-data {string} [placeName=''] - place name of business
 * @vue-data {string} [placeAddress=''] - place address of business
 * @vue-data {number} [placeRating=0] - place rating of business
 * @vue-event {void} onChangeTab - called on change tab
 * @vue-event {void} requestReviews - called on request reviews button
 * @vue-event {void} placeUpdated - called on place updated
 * @vue-event {void} validateBusinessSelection - validate the business selection
 * @vue-event {void} saveReviewsSettings - save the reviews settings
 */
export default {
  name: 'ReviewsSettingsCreateOrEdit',
  components: {
    FormWizard,
    TabContent,
    BusinessTab: ReviewsSettingsCreateOrEditBusinessTab,
    ConfirmBusinessTab: ReviewsSettingsCreateOrEditConfirmBusinessTab,
    SetupCompletedTab: ReviewsSettingsCreateOrEditSetupCompletedTab,
    WizardButtons: ReviewsSettingsCreateOrEditWizardButtons,
  },
  props: {
    cardStyle: {
      type: Boolean,
      required: false,
      default: true,
    },
    hideRequestReviewButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      placeId: '',
      placeName: '',
      placeAddress: '',
      placeRating: 0,
      tabIndex: 0,
    };
  },
  methods: {
    ...mapActions({
      updateTenantAccountReviewSettings: 'auth/updateTenantAccountReviewSettings',
      fetchTenantOfUser: 'auth/fetchTenantOfUser',
    }),
    onChangeTab(prevIndex, nextIndex) {
      this.tabIndex = nextIndex;
      this.$refs.wizard.$el.scrollIntoView(true);
    },
    requestReviews() {
      if (this.cardStyle) {
        this.$router.push(`/sms-campaigns/create?messageType=${this.$enums.Campaign.MessageType.TRANSACTIONAL}`);
      } else {
        this.$emit('request-reviews');
      }
    },
    placeUpdated(place) {
      this.placeId = place.id;
      this.placeName = place.name;
      this.placeAddress = place.address;
      this.placeRating = place.rating;
    },
    async validateBusinessSelection() {
      if (this.placeId) {
        return true;
      }

      this.$showGeneralWarningNotification(
        {
          title: this.$t('$ReviewsSettingsModule.$ReviewsSettingsCreateOrEdit.SaveReviewsSettingsNotifyTitle'),
          text: this.$t('$ReviewsSettingsModule.$ReviewsSettingsCreateOrEdit.SaveReviewsSettingsNotifyErrorMsg'),
        },
      );

      return false;
    },
    async saveReviewsSettings() {
      if (!this.placeId) {
        this.$showGeneralWarningNotification(
          {
            title: this.$t('$ReviewsSettingsModule.$ReviewsSettingsCreateOrEdit.SaveReviewsSettingsNotifyTitle'),
            text: this.$t('$ReviewsSettingsModule.$ReviewsSettingsCreateOrEdit.SaveReviewsSettingsNotifyErrorMsg'),
          },
        );

        return false;
      }

      this.$vs.loading({ type: 'radius' });
      await this.updateTenantAccountReviewSettings({
        placeId: this.placeId,
        placeName: this.placeName,
        placeAddress: this.placeAddress,
      });
      this.$vs.loading.close();

      this.$showSuccessActionNotification({
        title: this.$t('$ReviewsSettingsModule.$ReviewsSettingsCreateOrEdit.SaveReviewsSettingsNotifyTitle'),
        text: this.$t('$ReviewsSettingsModule.$ReviewsSettingsCreateOrEdit.SaveReviewsSettingsNotifyOkMsg'),
      });

      await this.fetchTenantOfUser();
      this.$emit('settings-updated');
      return true;
    },
  },
};
</script>
