<template>
  <span :title="name">
    {{ `${name || ''}` | truncateEllipsis(70) }}
    <span v-if="phoneInternational"> ({{ phoneInternational }})</span>
    <span v-if="status">
       -
      <span :class="`text-${textColor}`">
        <strong>{{ $t(`$Enums.Sender.Status.${status}`) }}</strong>
        <i class="ml-1" :class="['feather', icon]"></i>
      </span>
    </span>
  </span>
</template>

<script>
/**
 * Component to show options for phone number in custom v-select
 *
 * @module views/modules/campaigns/components/campaign-information/CampaignInformationVSelectPhoneNumberOption
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object} option - option to show
 * @vue-computed {string} name - option name
 * @vue-computed {string} status - option status
 * @vue-computed {string} phoneInternational - option phone international
 * @vue-computed {string} textColor - text color to show option status
 * @vue-computed {string} icon - icon =to show in the option status
 */
export default {
  name: 'CampaignInformationVSelectPhoneNumberOption',
  props: {
    option: {
      type: Object,
      require: true,
    },
  },
  computed: {
    name() {
      return this.option ? this.option.name || '' : '';
    },
    status() {
      return this.option ? this.option.status || '' : '';
    },
    phoneInternational() {
      return this.option ? this.option.phoneInternational || '' : '';
    },
    textColor() {
      if (this.status) {
        return this.status === this.$enums.Sender.Status.VERIFIED ? 'success' : 'danger';
      }

      return 'normal';
    },
    icon() {
      if (this.status) {
        return this.status === this.$enums.Sender.Status.VERIFIED ? 'icon-check' : 'icon-x';
      }

      return '';
    },
  },
};
</script>
