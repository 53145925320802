<template>
<div>
  <h3 class="text-center">
    {{ $t('$ReviewsSettingsModule.$SetupCompletedTab.Title') }}
  </h3>
  <h3 class="text-center mt-5">
    {{ $t('$ReviewsSettingsModule.$SetupCompletedTab.Subtitle') }}
  </h3>

  <h1 class="text-center text-6xl mt-5">{{ rating }}</h1>
  <div class="flex justify-center">
    <star-rating
      :rating="rating"
      :increment="0.01"
      :round-start-rating="false"
      :show-rating="false"
      read-only
      :star-size="20"/>
  </div>

  <div class="flex justify-center items-center mt-base">
    <vs-button
      v-if="!hideRequestReviewButton"
      @click="$emit('request-reviews')">
      {{ $t('$ReviewsSettingsModule.$SetupCompletedTab.RequestReviewButtonText') }}
    </vs-button>
    <a
      href="#"
      class="ml-5 link-cta"
      v-clipboard:copy="reviewLink"
      v-clipboard:success="onCopyLink"
      v-clipboard:error="onCopyLinkError"
      @click.prevent>
      {{ $t('$ReviewsSettingsModule.$SetupCompletedTab.CopyReviewLinkLinkText') }}
    </a>
  </div>
</div>
</template>

<script>
import StarRating from 'vue-star-rating';

/**
 * Show on review setting completed
 *
 * @module views/modules/reviews/ReviewsSettingsCreateOrEditSetupCompletedTab
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {number} rating - rating to show
 * @vue-prop {string} reviewLink - review link
 * @vue-data {boolean} [hideRequestReviewButton=false] - indicate if hide request review button
 * @vue-event {void} onCopyLink - call on copy review link to notify
 * @vue-event {void} onCopyLinkError - call on copy review link error to notify
 */
export default {
  name: 'ReviewsSettingsCreateOrEditSetupCompletedTab',
  components: {
    StarRating,
  },
  props: {
    rating: {
      type: Number,
      required: true,
    },
    reviewLink: {
      type: String,
      required: true,
    },
    hideRequestReviewButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onCopyLink() {
      this.$showSuccessActionNotification({
        title: this.$t('$ReviewsSettingsModule.$SetupCompletedTab.CopyReviewLinkCopiedNotifyTitle'),
        text: this.$t('$ReviewsSettingsModule.$SetupCompletedTab.CopyReviewLinkCopiedNotifyOkMsg'),
      });
    },
    onCopyLinkError() {
      this.$showGeneralErrorNotification({
        title: this.$t('$ReviewsSettingsModule.$SetupCompletedTab.CopyReviewLinkCopiedNotifyTitle'),
        text: this.$t('$ReviewsSettingsModule.$SetupCompletedTab.CopyReviewLinkCopiedNotifyErrorMsg'),
      });
    },
  },
};
</script>
