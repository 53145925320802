<template>
  <vue-simple-suggest
    ref="suggest"
    v-model="businessName"
    :list="getSuggestList"
    :filter-by-query="false"
    :prevent-submit="true"
    mode="input"
    @select="validateBusinessName()">
    <vs-input
      ref="input"
      :value="businessName"
      class="w-full"
      type="text"
      :name="inputName"
      :placeholder="$t('Placeholder')"
      v-validate="'required|max:11|businessName'"
      data-vv-validate-on="input|blur|change"
      icon="icon icon-user"
      icon-pack="feather"
      :danger="errors.has(inputName)"
      :danger-text="errors.first(inputName)"
      val-icon-danger="clear"/>
  </vue-simple-suggest>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css';

/**
 * Component to create or edit SMS Campaigns
 *
 // eslint-disable-next-line max-len
 * @module views/modules/campaigns/components/campaign-information/CampaignInformationCallerIdBusinessName.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} value - entry business name
 * @vue-prop {string} inputName - the name for input element
 * @vue-data {string} businessName - entered business name
 * @vue-event {void} validateBusinessName - validate the business name
 * @vue-event {Promise<string[]>} getSuggestList - return the suggestion list
 */
export default {
  name: 'CampaignInformationCallerIdBusinessName',
  i18n: {
    messages: {
      en: {
        Placeholder: 'Enter business name',
      },
    },
  },
  components: {
    VueSimpleSuggest,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      businessName: this.value,
    };
  },
  computed: {
    ...mapGetters({
      tenantAccountBusinessName: 'auth/tenantAccountBusinessName',
    }),
  },
  watch: {
    businessName(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    ...mapActions({
      fetchAllCallerIds: 'callerId/fetchAllCallerIds',
    }),
    validateBusinessName() {
      setTimeout(() => {
        this.$validator.validateAll();
      });
    },
    async getSuggestList(inputValue) {
      const data = await this.fetchAllCallerIds({
        sortBy: [{ colId: 'createdAt', sort: this.$enums.AppSortBy.DESC }],
        filters: {
          name: {
            filterType: 'text',
            type: 'contains',
            filter: inputValue,
          },
        },
        skip: 0,
        limit: 10,
      });

      const list = this.tenantAccountBusinessName
        ? [
          this.tenantAccountBusinessName,
          ...data.data.map((caller) => caller.name),
        ] : data.data.map((caller) => caller.name);

      return list.map((option) => this.$options.filters.sender_business_name(option));
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/extraComponents/autocomplete.scss";
</style>
