<template>
  <vs-popup
    :title="$t('Title')"
    :active="activeModal"
    button-close-hidden>
    <transition name="zoom-fade">
      <div>
        <h5 class="mb-5">
          {{ $t('Subtitle') }}
        </h5>

        <div
          v-for="(item, index) in content"
          :key="index"
          class="mb-5">
          <h6 class="mb-1">
            <i :class="`feather icon-${item.icon} pr-1`"></i>
            {{ item.title }}
          </h6>

          <div
            v-html="item.body"
            class="text-sm">
          </div>

          <a
            v-for="(link, indexLinks) in item.links"
            :key="indexLinks"
            :href="link.url"
            target="_blank"
            class="text-sm block mt-1">
            <span>{{ link.description }}</span>
            <i class="feather icon-external-link pl-1"></i>
          </a>
        </div>

        <vs-divider></vs-divider>

        <div class="flex justify-between">
          <vs-checkbox v-model="dontShowAgain">
            {{ $t('DontShowAgain') }}
          </vs-checkbox>
          <vs-button
            type="filled"
            @click="activeModal=false">
            {{ $t('$General.Continue') }}
          </vs-button>
        </div>

      </div>
    </transition>
  </vs-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

/**
 * Component to create or edit SMS Campaigns
 *
 * @module views/modules/campaigns/CampaignInformation
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} value - indicate if show or no the modal
 * @vue-data {boolean} activeModal - local flag to show or hide the modal
 * @vue-data {boolean} dontShowAgain - indicate if don't show the modal again
 * @vue-data {Object[]} content - content items to show
 */
export default {
  name: 'SMSCampaignGetStartedModal',
  i18n: {
    messages: {
      en: {
        Title: 'Set up SMS',
        Subtitle: 'A few things to know before you get started',
        DontShowAgain: 'Do not show again',
        $ExplicitConsent: {
          Title: 'Explicit consent',
          Body: 'To avoid fines, maintain a good reputation, and deliver an optimal experience for your audience, all recipients must explicitly consent to receive SMS messages from your brand and honour opt-outs.',
          HowToCollectSMSConsent: 'How to collect SMS consent',
        },
        $ProhibitedContent: {
          Title: 'Prohibited content',
          Body: `Messages containing prohibited or restricted content may be automatically filtered by wireless carriers and could harm your sending reputation. This includes content related to:
          <ol style="list-style: auto" class="pl-8">
            <li>SHAFT (Sex, Hate, Alcohol, Firearms, Tobacco)</li>
            <li>Controlled and illegal substances</li>
             <li>Multi-level marketing</li>
            <li>Third-party lead generation</li>
            <li>Loans</li>
            <li>Gambling</li>
            <li>Political content</li>
          </ol>`,
          //ProhibitedSMSContent: 'SMS prohibited content',
        },
        $AdditionalResources: {
          Title: 'Additional resources',
          Body: 'GrapeSend may cancel messages or suspend accounts that violate applicable laws, rules, and guidelines. Review all relevant documents on our Legal page and familiarize yourself with the laws in your country to ensure compliance.',
          LocalizedMessagingComplianceLaws: 'Avoid sending SPAM in Australia',
          GettingStartedWithSMSHandbook: 'Our Terms & Conditions',
        },
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      activeModal: this.value,
      dontShowAgain: false,
      content: [
        {
          title: this.$t('$ExplicitConsent.Title'),
          icon: 'file-text',
          body: this.$t('$ExplicitConsent.Body'),
          links: [{
            description: this.$t('$ExplicitConsent.HowToCollectSMSConsent'),
            url: 'https://www.acma.gov.au/avoid-sending-spam#get-consent',
          }],
        },
        {
          title: this.$t('$ProhibitedContent.Title'),
          icon: 'alert-triangle',
          body: this.$t('$ProhibitedContent.Body'),
          /*links: [{
            description: this.$t('$ProhibitedContent.ProhibitedSMSContent'),
            url: 'https://grapesend.com.au/legal',
          }],*/
        },
        {
          title: this.$t('$AdditionalResources.Title'),
          icon: 'file',
          body: this.$t('$AdditionalResources.Body'),
          links: [
            {
              description: this.$t('$AdditionalResources.LocalizedMessagingComplianceLaws'),
              url: 'https://www.acma.gov.au/avoid-sending-spam',
            },
            {
              description: this.$t('$AdditionalResources.GettingStartedWithSMSHandbook'),
              url: 'https://grapesend.com.au/legal?terms-and-conditions',
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getUserFlag: 'auth/getUserFlag',
    }),
  },
  watch: {
    activeModal(val) {
      this.$emit('input', val);

      if (!val && this.dontShowAgain) {
        this.skippedSMSCampaignGetStartedModal();
        this.dontShowAgain = false;
      }
    },
    value(val) {
      this.activeModal = val;
    },
  },
  created() {
    if (this.value === undefined || this.value === null) {
      this.activeModal = !this.getUserFlag('smsCampaignGetStartedModalSkipped');
    }
  },
  methods: {
    ...mapActions({
      skippedSMSCampaignGetStartedModal: 'auth/skippedSMSCampaignGetStartedModal',
    }),
  },
};
</script>
