import enums from '@/enums';

/**
 * This provides props used for campaign steps
 * @mixin views/modules/mixins/campaigns/campaignStep
 *
 * @vue-prop {string} campaignType - type of campaign
 * @vue-prop {string} operation - operation to make in campaign
 * @vue-prop {boolean} [compactDesign] - indicate if the design of component must be compact or no
 */
export default {
  props: {
    campaignType: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(enums.Campaign.Type).indexOf(value) !== -1;
      },
    },
    operation: {
      type: String,
      required: true,
      validator(value) {
        return [
          enums.Operation.CREATE,
          enums.Operation.EDIT,
          enums.Operation.CLONE].indexOf(value) !== -1;
      },
    },
    compactDesign: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
