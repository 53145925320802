<template>
  <div :class="{container: !compactDesign}">
    <div class="vx-row">
      <div class="vx-col w-full">
        <card-radio-toggle
          ref="deliveryTypeRadioToggle"
          v-model="deliveryTypeLocal"
          :radio-name="$t('$CampaignsModules.CampaignDeliveryType')"
          :radio-options="deliveryTypeCardRadioToggleOptions"
          :show-label="compactDesign"
          required
        />
      </div>
    </div>

    <div
      v-if="deliveryTypeLocal === $enums.Campaign.DeliveryType.LATER"
      class="mt-6">
      <div
        class="vx-row"
        :class="{'mb-base': compactDesign || $mq === this.$enums.mqBreakpoints.MOBILE}">
        <div class="vx-col w-full">
          <vs-input
            :value="timeZoneLocal"
            class="w-full required"
            type="hidden"
            :label="$tc('$General.TimeZone')"
            :name="$tc('$General.TimeZone')"/>
          <timezone-select
            v-model="timeZoneLocal"
            :border-danger="errors.has($tc('$General.TimeZone'))"
            :clearable="false"/>
          <span
            v-show="errors.has($tc('$General.TimeZone'))"
            class="text-danger text-sm">
            {{ errors.first($tc('$General.TimeZone')) }}
          </span>
        </div>
      </div>

      <div class="vx-row mt-6">
        <div
            class="vx-col w-full">
          <label class="vs-input--label required">
            {{ $tc('$CampaignsModules.LocalStartTime') }}
          </label>
          <flat-pickr
            @on-close="onCloseMethod"
            v-model="localStartDateLocal"
            :config="configDateTimePicker"
            class="w-full flatpickr-input"
            :class="{'border-danger': errors.has($t('$CampaignsModules.LocalStartTime')) }"
            :placeholder="$t('$CampaignsModules.LocalStartTime')"/>
          <vs-input
            :value="localStartDateLocal"
            type="hidden"
            :name="$t('$CampaignsModules.LocalStartTime')"
            v-validate="'required'"
            data-vv-validate-on="blur|input|change"
          />
          <span
            v-show="errors.has($t('$CampaignsModules.LocalStartTime'))"
            class="text-danger block">
            {{ errors.first($t('$CampaignsModules.LocalStartTime')) }}
          </span>
        </div>
      </div>
    </div>

    <campaign-schedule-time-alert
      v-if="deliveryTypeLocal === $enums.Campaign.DeliveryType.LATER"
      class="mt-6"
      :is-quick="isQuick"
      :local-start-date="localStartDateLocal"
      :time-zone="timeZoneLocal"
      @start-immediately="(val) => $emit('start-immediately', val)"/>

  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';

import enums from '@/enums';
import campaignStep from '@/views/modules/campaigns/common/mixins/campaignStep';

// Components
import TimezoneSelect from '@/views/modules/_components/TimezoneSelect.vue';
import CardRadioToggle from '@/views/modules/_components/CardRadioToggle.vue';
import CampaignScheduleTimeAlert from '@/views/modules/campaigns/components/CampaignScheduleTimeAlert.vue';

/**
 * Component to settings delivery type for one campaign
 *
 * @module src/views/modules/campaigns/CampaignDeliveryType
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} deliveryType - delivery type of campaign
 * @vue-prop {string} localStartDate - start date to init the campaign
 * @vue-prop {string} timeZone - time zone of campaign
 * @vue-data {string} deliveryTypeLocal - local delivery type of campaign
 * @vue-data {string} timeZoneLocal - local time zone of campaign
 * @vue-data {string} localStartDateLocal - local start date to init the campaign
 * @vue-data {Object} configDateTimePicker - configuration to datetime picker
 * @vue-data {Object[]} deliveryTypeCardRadioToggleOptions - options for delivery type radio
 * @vue-computed {boolean} isValid - indicate if the campaign settings are valid or no
 * @vue-event {void} validateLocalStartDateLocal - validate the local start date local field
 * @vue-event {boolean} validate - validate the campaign delivery type and return the result
 */
export default {
  name: 'CampaignDeliveryType',
  components: {
    flatPickr,
    CardRadioToggle,
    CampaignScheduleTimeAlert,
    TimezoneSelect,
  },
  mixins: [campaignStep],
  props: {
    deliveryType: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(enums.Campaign.DeliveryType).includes(value);
      },
    },
    localStartDate: {
      type: String,
      required: true,
    },
    timeZone: {
      type: String,
      required: true,
    },
    isQuick: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      deliveryTypeLocal: this.deliveryType,
      timeZoneLocal: this.timeZone,
      localStartDateLocal: this.localStartDate,
      configDateTimePicker: {
        defaultDate: this.localStartDate ? new Date(this.localStartDate) : '',
        enableTime: true,
        enableSeconds: false,
        locale: {
          firstDayOfWeek: 1, // start week on Monday
        },
        altInput: true,
        altFormat: 'F j, Y h:i K',
        minuteIncrement: 1,
      },
      deliveryTypeCardRadioToggleOptions: [
        {
          value: this.$enums.Campaign.DeliveryType.IMMEDIATELY,
          label: this.$t('$CampaignsModules.CampaignStartImmediately'),
        },
        {
          value: this.$enums.Campaign.DeliveryType.LATER,
          label: this.$t('$CampaignsModules.CampaignScheduleLater'),
        },
      ],
    };
  },
  computed: {
    isValid() {
      return !this.errors.any()
        && !!this.deliveryTypeLocal
        && (this.deliveryTypeLocal === this.$enums.Campaign.DeliveryType.IMMEDIATELY
          || (!!this.timeZoneLocal && !!this.localStartDateLocal));
    },
  },
  watch: {
    deliveryTypeLocal(val) {
      this.timeZoneLocal = '';
      this.localStartDateLocal = '';

      this.$emit('update:delivery-type', val);
    },
    localStartDateLocal(val) {
      this.validateLocalStartDateLocal();
      this.$emit('update:local-start-date', val);
    },
    timeZoneLocal(val) {
      this.$emit('update:time-zone', val);
    },
    isValid: {
      immediate: true,
      handler(val) {
        this.$emit('validate', val);
      },
    },
  },
  methods: {
    onCloseMethod() {
      this.$emit('close-flat-picker');
    },
    validateLocalStartDateLocal() {
      setTimeout(() => this.$validator.validate(this.$t('$CampaignsModules.LocalStartTime')));
    },
    async validate() {
      const campaignDeliveryTypeResult = await this.$validator.validateAll();
      const deliveryTypeResult = await this.$refs.deliveryTypeRadioToggle.$validator.validateAll();
      return campaignDeliveryTypeResult && deliveryTypeResult;
    },
  },
};
</script>
