import singleCreateOrEdit from '@/views/modules/_mixins/singleCreateOrEdit';

/**
 * Cell renderer to Sender ID actions
 *
 * @module src/views/modules/sender-ids/mixins/commonCreateOrEditSenderId
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} nameToCreate - name to create a new sender id
 * @vue-computed  {string} status - status of caller
 * @vue-computed  {string} type - type of caller
 * @vue-computed  {boolean} isDedicatedNumber - indicate if caller is dedicated number
 * @vue-computed  {boolean} isPhoneNumberVerified - indicate if caller is verified phone number
 * @vue-computed  {boolean} isDedicatedNumberMissingPayment - indicate if caller is missing payment dedicated number
 * @vue-computed  {boolean} isDedicatedNumberExpiredPayment - indicate if caller is expired payment dedicated number
 * @vue-computed  {boolean} isDedicatedNumberMissingOrExpiredPayment - indicate if caller is missing or expired payment dedicated number
 * @vue-computed  {Object[]} filteredActionsByType - actions filtered by type of caller
 * @vue-event  {Object[]} filteredActionsByPhoneNumberStatus - actions filtered by status of phone number
 * @vue-event  {Object[]} filteredActionsByDedicatedNumberStatus - actions filtered by status of dedicated number
 */
export default {
  mixins: [singleCreateOrEdit],
  props: {
    nameToCreate: {
      type: String,
      required: false,
    },
  },
  computed: {
    status() {
      return this.model && this.model.status
        ? this.model.status
        : '';
    },
    type() {
      return this.model
        ? this.model.type : '';
    },
    isDedicatedNumber() {
      return this.type === this.$enums.Sender.Caller.Type.DEDICATED_NUMBER;
    },
    isPhoneNumberVerified() {
      return this.status === this.$enums.Sender.Status.VERIFIED;
    },
    isDedicatedNumberMissingPayment() {
      return this.status === this.$enums.Sender.Caller.DedicatedNumberStatus.MISSING_PAYMENT;
    },
    isDedicatedNumberExpiredPayment() {
      return this.status === this.$enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT;
    },
    isDedicatedNumberMissingOrExpiredPayment() {
      return this.isDedicatedNumberMissingPayment || this.isDedicatedNumberExpiredPayment;
    },
    filteredActionsByType() {
      if (this.isDedicatedNumber) {
        // eslint-disable-next-line no-underscore-dangle
        const _actions = this.mappedActions.filter(
          (action) => action.name !== 'check'
            && action.name !== this.$enums.Operation.DELETE,
        );

        return this.filteredActionsByDedicatedNumberStatus(_actions);
      }

      // eslint-disable-next-line no-underscore-dangle
      const _actions = this.mappedActions.filter(
        (action) => action.name !== 'pay'
        && action.name !== 'subscription',
      );
      return this.filteredActionsByPhoneNumberStatus(_actions);
    },
  },
  methods: {
    filteredActionsByPhoneNumberStatus(actions) {
      if (this.isPhoneNumberVerified) {
        return actions.filter(
          (action) => action.name !== 'check',
        );
      }

      return actions;
    },
    filteredActionsByDedicatedNumberStatus(actions) {
      if (!this.isDedicatedNumberMissingOrExpiredPayment) {
        return actions.filter(
          (action) => action.name !== 'pay',
        );
      }

      return actions;
    },
  },
};
