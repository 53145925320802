var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-select-server-phone-number',{ref:"vSelectServerPhoneNumber",attrs:{"placeholder":_vm.$t('$SenderCallerNumber.Placeholder'),"show-label":false,"select-name":_vm.$t('$General.From'),"fetch-function":_vm.fetchAllCallerIds,"filters-match":_vm.$enums.AppFilterMathType.ALL,"filter-params":_vm.filterParamsToFetchPhoneNumbers,"search-filters":_vm.searchFiltersToSendersIds,"use-default-search-filters":false,"close-on-select":"","taggable":"","show-create":true,"create-text":_vm.$t('CreateNewSenderIDLink', {
              name: this.$tc(`$Entities.${this.$enums.Entity.CALLER_ID}`),
            }),"create-new-modal-entity":_vm.$tc(`$Entities.${this.$enums.Entity.CALLER_ID}`),"manage-route-name":"caller-ids","manage-text":_vm.$t('$CallerIdModule.ManageCallerIds'),"option-emphasis":'phoneInternational'},on:{"input":function($event){return _vm.onVSelectPhoneNumberSelection()}},scopedSlots:_vm._u([{key:"option",fn:function({ item }){return [_c('v-select-phone-number-option',{attrs:{"option":item}})]}},{key:"selected-option",fn:function({ item }){return [_c('v-select-phone-number-option',{attrs:{"option":item}})]}},{key:"create",fn:function({ nameToCreate }){return [_c('caller-id-list-create-or-edit-from-phone-number',{attrs:{"entity":_vm.$enums.Entity.CALLER_ID,"operation":_vm.$enums.Operation.CREATE,"name-to-create":nameToCreate},on:{"saved":_vm.callerCreatedFromCallerIdCreateModal,"close":function($event){return _vm.$refs.vSelectServerPhoneNumber.closeCreateModal()}}})]}}]),model:{value:(_vm.senderIdLocal),callback:function ($$v) {_vm.senderIdLocal=$$v},expression:"senderIdLocal"}}),_c('from-phone-number-verification-modal',{attrs:{"phone-number":_vm.senderIdLocal.phoneInternational || _vm.senderIdLocal},on:{"verified":_vm.afterVerifyPhoneNumber,"caller-retrieved":_vm.onCallerRetrieved},model:{value:(_vm.activeModalFromPhoneNumberVerification),callback:function ($$v) {_vm.activeModalFromPhoneNumberVerification=$$v},expression:"activeModalFromPhoneNumberVerification"}}),_c('vs-popup',{attrs:{"title":_vm.$t('$SenderIdsModules.VerificationModalTitle', {
        entity: this.$tc(`$Entities.${this.$enums.Entity.CALLER_ID}`),
        name: this.recordToVerify ? this.recordToVerify.name : '',
        value: this.recordToVerify ? this.recordToVerify.phoneInternational : ''
      }),"active":_vm.activeModalVerify},on:{"update:active":function($event){_vm.activeModalVerify=$event}}},[_c('transition',{attrs:{"name":"zoom-fade"}},[(_vm.activeModalVerify)?_c('sender-id-verification',{attrs:{"model-payload":_vm.recordToVerify,"message":_vm.$t('$CallerIdModule.VerifyPhoneMsg'),"entity":_vm.$enums.Entity.CALLER_ID,"send-code":_vm.sendVerificationCode},on:{"close":function($event){_vm.activeModalVerify=false},"verified":_vm.afterVerifySenderId}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }