<template>
  <div class="px-0 lg:px-32 mx-0 lg:mx-32">

    <h4>{{ $t('sendTo') }}</h4>

    <template v-if="!showSegmentCreateOrEdit">
      <v-select-server
        ref="vSelectServer"
        v-model="selectedSegment"
        class="contact-list-toolbar-select mt-2"
        :fetch-function="fetchAllSegments"
        :placeholder="$t('SelectSegmentPlaceholder')"
        close-on-select
        manage-route-name="segments"
        :manage-text="$t('$ContactModule.ManageSegments')"
        :create-text="$t('createContactText')"
        show-create
        :default-options="defaultSegmentsOptions"
        @input="onSelectedSegment()"
        @create="createNewSegment"/>

      <h6 class="text-center mt-3">{{ $t('$General.Or') }}</h6>

      <div class="text-center mt-3">
        <a
          href="#"
          class="link-cta text-center"
          @click.prevent="createNewSegment">
          {{ $t('createSegmentText') }}
        </a>
      </div>
    </template>

    <div
      v-else
      class="mt-3">
      <h3 class="mr-3 inline-block">{{ senToToText }}</h3>
      <a
        href="#"
        class="link-cta"
        @click.prevent="confirmBackToSelectSelection">
        {{ backToSelectText }}
      </a>
    </div>

    <template v-if="showSegmentCreateOrEdit">
      <vs-divider class="my-base"/>

      <template v-if="selectedSegmentWasAllContacts">
        <div class="flex justify-between items-center mt-5">
          <contacts-match-count
            :key="contactsMatchCountKey"
            :filters-match="selectedSegment.filtersMatch"
            :get-valid-filters="() => selectedSegment.filters"
            :is-there-any-valid-filter="true"
            :show-refresh="false"
            :selected-from-campaign="true"
            :manage-contacts-links="true"
            :show-contacts-list-link="true"
            :campaign-message-type="campaignMessageType"
            :count-message="$tc('ContactsMatchMsg', contactsFilteredCount)"
            @count-changed="contactsFilteredCount = $event"
            @create-contact="activeModalCreateOrEditContact = true"
          />
        </div>
      </template>

      <template v-else>
        <segment-list-create-or-edit
          ref="segmentCreateOrEdit"
          :key="`segmentCreateOrEditKey:${segmentCreateOrEditKey}`"
          :entity="$enums.Entity.SEGMENT"
          :model-payload="selectedSegment"
          :selected-from-campaign="true"
          :operation="operation"
          :filters="initialFilters"
          :filters-match="initialFiltersMatch"
          :prioritize-filters="prioritizeFilters"
          :campaign-message-type="campaignMessageType"
          @model-was-changed="(val) => this.createOrEditModelWasChanged=val"
          @contacts-count-changed="contactsFilteredCount = $event"
          @filters-changed="onFiltersChanged"
          @create-contact="activeModalCreateOrEditContact = true"
        >
          <template
            v-if="operation === $enums.Operation.CREATE && createOrEditModelWasChanged"
            v-slot:contacts-count-right>
            <a
              href="#"
              class="link-plain"
              @click.prevent="showSaveSegmentNameModal">
              + {{ $t('SaveFilteredContactsAsNewSegment') }}
            </a>
          </template>
        </segment-list-create-or-edit>

        <template v-if="operation === $enums.Operation.EDIT && filtersChanged">
          <vs-divider class="my-base"/>

          <div class="flex justify-between items-center">
            <vs-button
              @click="updateSegment()">
              {{ $t('UpdateSegment') }}
            </vs-button>

            <a
              href="#"
              class="link-plain"
              @click.prevent="showSaveSegmentNameModal">
              + {{ $t('SaveFilteredContactsAsNewSegment') }}
            </a>
          </div>
        </template>
      </template>
    </template>

    <campaign-contacts-save-segment-modal
      v-model="activeSaveSegmentModal"
      :key="`SaveSegmentModalKey:${SaveSegmentModalKey}`"
      :title="$t('SaveFilteredContactsAsNewSegment')"
      @save="saveSegment($event)"/>

    <vs-popup
      :title="$t('$Modals.CreateModalTitle', { entity: this.$tc('$Entities.Contact') })"
      :active.sync="activeModalCreateOrEditContact">
      <transition name="zoom-fade">
        <contact-list-create-or-edit
          v-if="showCreateOrEditContactComponent"
          :entity="this.$tc('$Entities.Contact')"
          :operation="$enums.Operation.CREATE"
          :all-contact-attributes="allContactAttributes"
          @saved="contactSaved()"
          @close="activeModalCreateOrEditContact=false">
        </contact-list-create-or-edit>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isEmpty as _isEmpty } from 'underscore';

import VSelectServer from '@/views/modules/_components/v-select-server/VSelectServer.vue';
import SegmentListCreateOrEdit from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEdit.vue';
import CampaignContactsSaveSegmentModal from '@/views/modules/campaigns/components/campaign-contacts/CampaignContactsSaveSegmentModal.vue';

import segmentsAreEqual from '@/views/modules/segment/segment-comparator';
import enums from '@/enums';
import ContactListCreateOrEdit from '@/views/modules/contacts/ContactListCreateOrEdit.vue';
import ContactsMatchCount
from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditContactsMatchCount.vue';

export default {
  name: 'CampaignContacts',
  i18n: {
    messages: {
      en: {
        SelectSegmentPlaceholder: 'Select segment',
        createSegmentText: 'Create new segment',
        createContactText: 'Create contact',
        sendTo: 'Send to',
        ANewSegment: 'A new segment',
        SaveFilteredContactsAsNewSegment: 'Save filtered contacts as a new segment',
        SelectOneExistingSegmentInstead: 'Select one existing segment instead',
        SelectAnotherSegment: 'Select another segment',
        ConfirmBackWithoutSaveChangesTitle: 'Confirm back without save changes',
        ConfirmBackWithoutSaveChangesMsg: 'You are about to back to select segment and lose the changes you have made',
        AlertNoContactsTitle: 'You can\'t send a campaign without contacts',
        AlertNoContactsMsg: 'You must select a segment that contains at least one contact to send the campaign.',
        UpdateSegment: 'Update segment',
        ContactsMatchMsg: 'There are no contacts | contact in total | contacts in total',
      },
    },
  },
  components: {
    ContactsMatchCount,
    ContactListCreateOrEdit,
    VSelectServer,
    SegmentListCreateOrEdit,
    CampaignContactsSaveSegmentModal,
  },
  props: {
    value: {
      required: true,
    },
    initialFilters: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    initialFiltersMatch: {
      type: String,
      required: false,
      default: enums.AppFilterMathType.ALL,
    },
    campaignMessageType: {
      type: String,
      required: false,
      validator(type) {
        return Object.values(enums.Campaign.MessageType).includes(type);
      },
    },
    allContacts: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedSegment: this.value,
      showSegmentCreateOrEdit: false,
      segmentCreateOrEditKey: 0,
      SaveSegmentModalKey: 0,
      operation: this.$enums.Operation.CREATE,
      createOrEditModelWasChanged: false,
      activeSaveSegmentModal: false,
      contactsFilteredCount: 0,
      prioritizeFilters: false,
      filtersChanged: false,

      activeModalCreateOrEditContact: false,
      showCreateOrEditContactComponent: false,
      allContactAttributes: [],

      defaultSegmentsOptions: [
        {
          filtersMatch: this.$enums.AppFilterMathType.ALL,
          id: this.$enums.DEFAULT_ALL_CONTACTS_SEGMENT_ID,
          name: 'All contacts',
          filters: {},
          firstsOption: true,
        },
      ],
      contactsMatchCountKey: 1000,

    };
  },
  computed: {
    senToToText() {
      return this.selectedSegment
        ? this.selectedSegment.name
        : this.$t('ANewSegment');
    },
    backToSelectText() {
      return this.operation === this.$enums.Operation.CREATE && !this.selectedSegmentWasAllContacts
        ? this.$t('SelectOneExistingSegmentInstead')
        : this.$t('SelectAnotherSegment');
    },
    selectedSegmentWasAllContacts() {
      return this.selectedSegment && this.selectedSegment.id === this.defaultSegmentsOptions[0].id;
    },
  },
  async created() {
    await Promise.all([
      this.fetchContactAttributes(),
      this.initComponent(),
    ]);
  },
  watch: {
    activeModalCreateOrEditContact(val) {
      if (!val) {
        setTimeout(() => {
          this.showCreateOrEditContactComponent = false;
        }, 500);
      } else {
        this.showCreateOrEditContactComponent = true;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchAllSegments: 'segment/fetchAllSegments',
      fetchSegment: 'segment/fetchSegment',
      fetchAllAttributes: 'attribute/fetchAllAttributes',
    }),
    async initComponent() {
      if (this.selectedSegment) {
        if (typeof this.selectedSegment === 'string') {
          this.selectedSegment = await this.fetchSegment(this.selectedSegment);
        }

        if (!_isEmpty(this.initialFilters)) {
          this.prioritizeFilters = true;
        }

        this.showSegmentCreateOrEdit = true;
        this.operation = this.$enums.Operation.EDIT;
      } else if (!_isEmpty(this.initialFilters)) {
        this.showSegmentCreateOrEdit = true;
        this.operation = this.$enums.Operation.CREATE;
      } else if (this.allContacts) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedSegment = this.defaultSegmentsOptions[0];
        this.operation = this.$enums.Operation.CREATE;
        this.showSegmentCreateOrEdit = true;
      }
    },
    createNewSegment($event) {
      if ($event === 'footer-create') {
        this.activeModalCreateOrEditContact = true;
      } else {
        this.selectedSegment = null;

        if (this.showSegmentCreateOrEdit
          && this.operation !== this.$enums.Operation.CREATE) {
          this.segmentCreateOrEditKey += 1;
        }

        this.showSegmentCreateOrEdit = true;
        this.operation = this.$enums.Operation.CREATE;
        this.$refs.vSelectServer.closeSelect();
      }
    },
    onSelectedSegment() {
      if (this.showSegmentCreateOrEdit
        && this.operation !== this.$enums.Operation.EDIT) {
        this.segmentCreateOrEditKey += 1;
      }

      this.showSegmentCreateOrEdit = true;
      this.operation = this.$enums.Operation.EDIT;
      this.$emit('input', this.selectedSegment);
    },
    selectedSegmentWasChanged() {
      let changed = this.createOrEditModelWasChanged;

      if (this.operation === this.$enums.Operation.EDIT && !this.selectedSegmentWasAllContacts) {
        const segmentOne = this.selectedSegment;
        const segmentTwo = {
          ...this.$refs.segmentCreateOrEdit.model,
          filters: this.$refs.segmentCreateOrEdit.mappedFilterToSave(),
        };
        changed = !segmentsAreEqual(segmentOne, segmentTwo);
      }

      return changed;
    },
    confirmBackToSelectSelection() {
      const changed = this.selectedSegmentWasChanged();

      if (changed) {
        this.$showConfirmWarningDialog({
          title: this.$t('ConfirmBackWithoutSaveChangesTitle'),
          text: this.$t('ConfirmBackWithoutSaveChangesMsg'),
          accept: this.backToSelectSelection,
          acceptText: this.$t('$General.Confirm'),
          cancelText: this.$t('$General.Cancel'),
        });
      } else {
        this.backToSelectSelection();
      }
    },
    backToSelectSelection() {
      this.selectedSegment = null;
      this.showSegmentCreateOrEdit = false;
      this.contactsFilteredCount = 0;
      this.prioritizeFilters = false;
      this.filtersChanged = false;
      this.$emit('update:initial-filters', {});
      this.$emit('update:initial-filter-match', this.$enums.AppFilterMathType.ALL);
      this.$emit('update:all-contacts', false);
    },
    async showSaveSegmentNameModal() {
      const filtersAreValid = await this.$refs.segmentCreateOrEdit.validateSegmentFiltersToSave();

      if (filtersAreValid) {
        this.operation = this.$enums.Operation.CREATE;
        this.activeSaveSegmentModal = true;
      }
    },
    async updateSegment() {
      this.operation = this.$enums.Operation.EDIT;
      await this.saveSegment();
    },
    async saveSegment(name = '') {
      this.activeSaveSegmentModal = false;
      this.selectedSegment = await this.$refs.segmentCreateOrEdit.saveSegment(name);
      this.createOrEditModelWasChanged = false;
      // this.segmentCreateOrEditKey += 1;
      this.SaveSegmentModalKey += 1;
      this.prioritizeFilters = false;
      this.filtersChanged = false;
    },
    async validate() {
      const filtersAreValid = this.$refs.segmentCreateOrEdit
        ? await this.$refs.segmentCreateOrEdit.validateSegmentFiltersToSave()
        : this.selectedSegment && this.selectedSegmentWasAllContacts;
      const existContacts = this.contactsFilteredCount > 0;

      if (!existContacts) {
        this.$showAlertWarningDialog({
          title: this.$t('AlertNoContactsTitle'),
          text: this.$t('AlertNoContactsMsg'),
        });
      }

      return filtersAreValid && existContacts;
    },
    async getFiltersAndFilterMatch() {
      return {
        filtersMatch: this.selectedSegmentWasAllContacts
          ? this.selectedSegment.filtersMatch
          : this.$refs.segmentCreateOrEdit.model.filtersMatch,
        filters: this.selectedSegmentWasAllContacts
          ? this.selectedSegment.filters
          : this.$refs.segmentCreateOrEdit.mappedFilterToSave(),
      };
    },
    onFiltersChanged() {
      this.filtersChanged = true;
      this.$emit('update:initial-filters', this.$refs.segmentCreateOrEdit.mappedFilterToSave());
      this.$emit('update:initial-filter-match', this.$refs.segmentCreateOrEdit.model.filtersMatch);
    },
    async fetchContactAttributes() {
      const resp = await this.fetchAllAttributes({});
      this.allContactAttributes = resp.data;
    },
    contactSaved() {
      this.contactsMatchCountKey += 1;
      this.activeModalCreateOrEditContact = false;
    },
  },
};
</script>

<style scoped>

</style>
