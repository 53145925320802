<template>
  <div class="smartphone">
    <div class="content h-full">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/**
 * Phone device preview with slot content
 *
 * @module views/modules/components/PhonePreview
 * @author Dilan Useche <dilan8810@gmail.com>
 */
export default {
  name: 'PhonePreview',
};
</script>

<style lang="scss" scoped>
/* The device with borders */
.smartphone {
  position: relative;
  width: 345px;
  height: 650px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  /*width: 100%;*/
  /*height: 100%;*/
  /*width: 360px;*/
  /*height: 640px;*/
  background: white;
  //max-height: 350px;
  overflow-y: auto;
}
</style>
