<template>
  <vs-popup
    :title="$t('Tile')"
    :active.sync="valueLocal">
    <transition name="zoom-fade">
      <div>
        <p class="text-md text-justify">
          <strong>{{ $t('$CampaignsModules.$CampaignType.Transactional') }}</strong>:
          {{ $t('$CampaignsModules.$CampaignTypeInfo.Transactional') }}
        </p>

        <p class="text-md text-justify mt-base ">
          <strong>{{ $t('$CampaignsModules.$CampaignType.Review') }}</strong>:
          {{ $t('$CampaignsModules.$CampaignTypeInfo.Review') }}
        </p>

        <p class="text-md text-justify mt-base ">
          <strong>{{ $t('$CampaignsModules.$CampaignType.Marketing') }}</strong>:
          {{ $t('$CampaignsModules.$CampaignTypeInfo.Marketing') }}
        </p>

        <div class="mt-base text-center text-md">
          <a
            href="https://grapesend.com.au/missing-link"
            target="_blank"
            class="link-contextual">
            {{ $t("$General.MoreInfo") }}
          </a>
        </div>
      </div>
    </transition>
  </vs-popup>
</template>

<script>
/**
 * Modal to show campaign type information
 *
 * @module views/modules/campaigns/components/campaign-information/CampaignInformationCampaignTypeInfoModal
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} value - value to show modal
 * @vue-data {boolean} valueLocal - value local to show modal
 * */
export default {
  name: 'CampaignInformationCampaignTypeInfoModal',
  i18n: {
    messages: {
      en: {
        Tile: 'About campaign types',
      },
    },
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valueLocal: this.value,
    };
  },
  watch: {
    value(val) {
      this.valueLocal = val;
    },
    valueLocal(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style scoped>

</style>
