<template>
  <div>
    <vs-alert
      color="primary"
      active="true"
      icon-pack="feather"
      icon="icon-send">
      <div>{{ message }} {{ $t('$General.Or') | lowercase }}
        <a
          href="#"
          @click.prevent="requestVerifySender()">
          {{ $t('GenerateANewCode') | lowercase }}
        </a>.
      </div>
    </vs-alert>
    <form @submit.prevent>
      <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full">
          <vs-input
            v-model="localCode"
            class="w-full required"
            :label="$t('VerifyCodeLabel')"
            :name="$t('$General.Code')"
            v-validate="'required|digits:6'"
            data-vv-validate-on="blur|input"
            :danger="errors.has($t('$General.Code')) || !!invalidVerificationCodeMsg"
            :danger-text="errors.first($t('$General.Code')) || invalidVerificationCodeMsg"
            val-icon-danger="clear"
            @input="invalidVerificationCodeMsg = ''"/>
        </div>
      </div>

      <base-form-footer-action-buttons
        @save="checkEnteredCode"
        @cancel="$emit('close')">
        <template v-slot:save>
          {{ $t('$General.Verify')}}
        </template>
      </base-form-footer-action-buttons>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { deepCopy } from '@/util';
import enums from '@/enums';
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';

/**
 * Component to verify a Sender ID
 *
 * @module views/modules/sender-ids/SenderIdVerification
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object} modelPayload - entry sender to verify
 * @vue-prop {string} message - title message of verification
 * @vue-prop {string} entity - entity to verify (Caller ID or Mailer ID)
 * @vue-prop {string} code - entry verification code to sender
 * @vue-prop {boolean} sendCode - indicate if code must be sent on init
 * @vue-data {Object} model - sender to verify
 * @vue-data {Object} localCode - local verification code to sender
 * @vue-computed  {string} validateForm - validate form to send
 * @vue-event  {void} created - created hook to init verification process
 * @vue-event  {void} initVerifyPhone - init verification caller id
 * @vue-event  {void} checkVerifyPhoneCode - check entered verification code to caller id
 * @vue-event  {void} requestVerifySender - send verification code
 * @vue-event  {void} checkEnteredCode - check entered verification code
 */
export default {
  name: 'SenderIdVerification',
  i18n: {
    messages: {
      en: {
        VerificationNotification: {
          Title: '{entity} verification',
          OkMsg: 'The code was sent successfully',
          ErrorMsg: 'The code could not be sent',
        },
        VerificationCompleteNotification: {
          OkMsg: '{entity} verified successfully',
          ErrorMsg: '{entity} could not be verified',
        },
        GenerateANewCode: 'Generate a new code',
        VerifyCodeLabel: 'Enter verification code',
      },
    },
  },
  components: {
    BaseFormFooterActionButtons,
  },
  props: {
    modelPayload: {
      type: Object,
      required: false,
    },
    message: {
      type: String,
      required: true,
    },
    entity: {
      type: String,
      required: true,
      validator(entity) {
        return [enums.Entity.CALLER_ID, enums.Entity.MAILER_ID].indexOf(entity) !== -1;
      },
    },
    code: {
      type: String,
      required: false,
      default: '',
    },
    sendCode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      model: deepCopy(this.modelPayload),
      localCode: this.code,
      invalidVerificationCodeMsg: '',
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.localCode !== '';
    },
  },
  created() {
    if (this.code) {
      this.checkEnteredCode();
    } else if (this.sendCode) {
      this.requestVerifySender();
    }
  },
  methods: {
    ...mapActions({
      initVerifyPhone: 'callerId/initVerifyPhone',
      checkVerifyPhoneCode: 'callerId/checkVerifyPhoneCode',
    }),
    async requestVerifySender() {
      this.$vs.loading({ type: 'radius' });

      const resp = await this.initVerifyPhone({
        callerId: this.model.id,
      });

      if (resp.statusCode === 200 && !resp.error) {
        this.$showSuccessActionNotification({
          title: this.$t('VerificationNotification.Title', {
            entity: this.$tc(`$Entities.${this.entity}`),
          }),
          text: this.$t('VerificationNotification.OkMsg'),
        });
      } else {
        this.$showErrorActionNotification({
          title: this.$t('VerificationNotification.Title', {
            entity: this.$tc(`$Entities.${this.entity}`),
          }),
          text: this.$t('VerificationNotification.ErrorMsg'),
        });
      }

      if (!resp) {
        this.$emit('close');
      }

      this.$vs.loading.close();
    },
    async checkEnteredCode() {
      const formIsValid = await this.$validator.validate();

      if (!formIsValid) {
        this.$el.querySelector(`[name='${this.$validator.errors.items[0].field}']`).focus();
        return;
      }

      this.$vs.loading({ type: 'radius' });

      try {
        await this.checkVerifyPhoneCode({
          callerId: this.model.id,
          code: this.localCode,
        });

        this.localCode = '';
        this.$vs.loading.close();
        this.$emit('verified', this.model);

        this.$showSuccessActionNotification({
          title: this.$t('VerificationNotification.Title', {
            entity: this.$tc(`$Entities.${this.entity}`),
          }),
          text: this.$t('VerificationCompleteNotification.OkMsg', {
            entity: this.$tc(`$Entities.${this.entity}`),
          }),
        });
      } catch (error) {
        if (error.response.data.statusCode === 400) {
          this.invalidVerificationCodeMsg = error.response.data.message;
        } else {
          throw error;
        }
      }
    },
  },
};
</script>
