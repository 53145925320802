<template>
  <div>
    <v-select-server-phone-number
      ref="vSelectServerPhoneNumber"
      v-model="senderIdLocal"
      :placeholder="$t('Placeholder')"
      :show-country-code="false"
      :show-label="false"
      :select-name="$t('$General.From')"
      :fetch-function="fetchAllCallerIds"
      :filters-match="$enums.AppFilterMathType.ALL"
      :filter-params="filterParamsToFetchDedicatedNumbers"
      :search-filters="searchFiltersToFetchDedicatedNumbers"
      :use-default-search-filters="false"
      close-on-select
      manage-route-name="caller-ids"
      :manage-text="$t('$CallerIdModule.ManageCallerIds')"
      option-emphasis="phoneInternational"
      :create-modal-title="$t('CreateModalTitle')"
      :create-text="$t('CreateNewSelectTex', {
              name: this.$tc(`$Entities.${this.$enums.Entity.CALLER_ID}`),
            })"
      :show-create="true"
      @input="onVSelectDedicatedNumberSelection()">
      <template v-slot:option="{ item }">
        <v-select-dedicated-number-option :option="item"/>
      </template>
      <template v-slot:selected-option="{ item }">
        <v-select-dedicated-number-option :option="item"/>
      </template>

      <template v-slot:create="{ nameToCreate }">
        <caller-id-list-create-or-edit-from-dedicated-number
          :entity="$enums.Entity.CALLER_ID"
          :operation="$enums.Operation.CREATE"
          :dedicated-numbers="[]"
          @saved="dedicatedNumberCrated">
        </caller-id-list-create-or-edit-from-dedicated-number>
      </template>
    </v-select-server-phone-number>

    <dedicated-number-payment-checkout-modal
      v-if="senderIdLocalIsMissingOrExpiredPaymentOrCanceled"
      v-model="showMissingPaymentCheckoutModal"
      :caller-id="senderIdLocal"
      @payment="onPaymentSent"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { registerSocketEvent, unregisterSocketEvent } from '@/socket';

// Components
import VSelectServerPhoneNumber from '@/views/modules/_components/v-select-server/VSelectServerPhoneNumber.vue';
import CallerIdListCreateOrEditFromDedicatedNumber
from '@/views/modules/sender-ids/caller-id/CallerIdListCreateOrEditFromDedicatedNumber.vue';
import CampaignInformationVSelectDedicatedNumberOption
from '@/views/modules/campaigns/components/campaign-information/CampaignInformationVSelectDedicatedNumberOption.vue';
import DedicatedNumberPaymentCheckoutModal
from '@/views/modules/sender-ids/caller-id/_components/DedicatedNumberPaymentCheckoutModal.vue';

export default {
  name: 'CampaignInformationVSelectDedicatedNumber',
  i18n: {
    messages: {
      en: {
        Placeholder: 'Select a dedicated number or get a new one',
        CreateNewSelectTex: 'Get a new dedicated number',
        CreateModalTitle: 'Subscribe to dedicated number',
        $SenderDedicatedNumberProcessingPaymentDialog: {
          Title: 'The payment of the dedicated number is processing',
          Text: 'Please wait until the payment is processed and try again to use it as the sender number.',
          AcceptText: 'Close',
        },
        $SenderDedicatedNumberMissingPaymentDialog: {
          Title: 'The payment of the dedicated number is missing',
          Text: 'The dedicated number you are trying to use is missing payment and therefore you cannot use it as a valid sender number.',
          AcceptText: 'Pay now',
          CancelText: 'Use another number',
        },
        $SenderDedicatedNumberExpiredPaymentDialog: {
          Title: 'The payment of the dedicated number is expired',
          Text: 'The dedicated number you are trying to use is expired payment and therefore you cannot use it as a valid sender number.',
          AcceptText: 'Resubscribe',
          CancelText: 'Use another number',
        },
        $SenderDedicatedNumberCanceledDialog: {
          Title: 'The dedicated number is canceled',
          Text: 'The Dedicated number you are trying to use is canceled and therefore you cannot use it as a valid from.',
          AcceptText: 'Resubscribe',
          CancelText: 'Use another number',
        },
      },
    },
  },
  components: {
    VSelectServerPhoneNumber,
    CallerIdListCreateOrEditFromDedicatedNumber,
    VSelectDedicatedNumberOption: CampaignInformationVSelectDedicatedNumberOption,
    DedicatedNumberPaymentCheckoutModal,
  },
  props: {
    value: {
      type: [Object, String],
      required: true,
    },
  },
  data() {
    return {
      socketEventsListenerId: 'update-dedicated-number-vselect',
      senderIdLocal: this.value,
      showMissingPaymentCheckoutModal: false,
      filterParamsToFetchDedicatedNumbers: {
        type: {
          filterType: this.$enums.AppFilterType.TEXT,
          type: this.$enums.AppFilter.FilterType.Text.Type.EQUALS,
          filter: this.$enums.Sender.Caller.Type.DEDICATED_NUMBER,
        },
      },
      searchFiltersToFetchDedicatedNumbers: {
        nameOrPhoneInternational: {
          filterType: this.$enums.AppFilterType.CONDITIONAL,
          filtersMatch: this.$enums.AppFilterMathType.ANY,
          filter: {
            name: {
              filterType: this.$enums.AppFilterType.TEXT,
              type: this.$enums.AppFilter.FilterType.Text.Type.CONTAINS,
              filter: '',
            },
            phoneInternationalSignificant: {
              filterType: this.$enums.AppFilterType.TEXT,
              type: this.$enums.AppFilter.FilterType.Text.Type.CONTAINS,
              filter: '',
            },
          },
        },
      },
    };
  },
  mounted() {
    registerSocketEvent(this.$enums.SocketEvents.CALLER_ID_UPDATED, (callerUpdated) => {
      // eslint-disable-next-line no-underscore-dangle
      if (callerUpdated && callerUpdated._id === this.senderIdLocal._id) {
        this.senderIdLocal = callerUpdated;
      }

      this.$refs.vSelectServerPhoneNumber.resetSelectServer();
    }, this.socketEventsListenerId,
    true);
  },
  beforeDestroy() {
    unregisterSocketEvent(
      this.$enums.SocketEvents.CALLER_ID_UPDATED,
      this.socketEventsListenerId,
    );
  },
  computed: {
    isValid() {
      return typeof this.senderIdLocal === 'object'
        && this.senderIdLocal && this.senderIdLocal.status
        && this.senderIdLocal.status === this.$enums.Sender.Caller.DedicatedNumberStatus.ACTIVE;
    },
    senderIdLocalStatus() {
      return typeof this.senderIdLocal === 'object'
      && this.senderIdLocal
      && this.senderIdLocal.status
        ? this.senderIdLocal.status
        : null;
    },
    senderIdLocalIsProcessingPayment() {
      return this.senderIdLocalStatus
        === this.$enums.Sender.Caller.DedicatedNumberStatus.PROCESSING_PAYMENT;
    },
    senderIdLocalIsMissingPayment() {
      return this.senderIdLocalStatus
        === this.$enums.Sender.Caller.DedicatedNumberStatus.MISSING_PAYMENT;
    },
    senderIdLocalIsExpiredPayment() {
      return this.senderIdLocalStatus
        === this.$enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT;
    },
    senderIdLocalIsCanceled() {
      return this.senderIdLocalStatus
        === this.$enums.Sender.Caller.DedicatedNumberStatus.CANCELED;
    },
    senderIdLocalIsMissingOrExpiredPaymentOrCanceled() {
      return this.senderIdLocalIsMissingPayment
        || this.senderIdLocalIsExpiredPayment
        || this.senderIdLocalIsCanceled;
    },
  },
  watch: {
    value(val) {
      this.senderIdLocal = val;
    },
    senderIdLocal(val) {
      this.$emit('input', val || '');
    },
    isValid: {
      immediate: true,
      handler(value) {
        this.$emit('validate', value);
      },
    },
  },
  methods: {
    ...mapActions({
      fetchAllCallerIds: 'callerId/fetchAllCallerIds',
    }),
    async validate() {
      const result = typeof this.senderIdLocal === 'object'
        && this.senderIdLocal && this.senderIdLocal.status
        && this.senderIdLocal.status === this.$enums.Sender.Caller.DedicatedNumberStatus.ACTIVE;

      if (!this.senderIdLocal) {
        await this.$refs.vSelectServerPhoneNumber.$validator.validateAll();
      } else {
        this.showSenderDedicatedNumberInvalidDialog();
      }

      return result;
    },
    onVSelectDedicatedNumberSelection() {
      this.showSenderDedicatedNumberInvalidDialog();
    },
    showSenderDedicatedNumberInvalidDialog() {
      if (this.senderIdLocalIsProcessingPayment) {
        this.showSenderDedicatedNumberProcessingPaymentDialog();
      }

      if (this.senderIdLocalIsMissingPayment) {
        this.showSenderDedicatedNumberMissingPaymentDialog();
      }

      if (this.senderIdLocalIsExpiredPayment) {
        this.showSenderDedicatedNumberExpiredPaymentDialog();
      }

      if (this.senderIdLocalIsCanceled) {
        this.showSenderDedicatedNumberCanceledDialog();
      }
    },
    showSenderDedicatedNumberProcessingPaymentDialog() {
      this.$showAlertActionDialog({
        title: this.$t('$SenderDedicatedNumberProcessingPaymentDialog.Title'),
        text: this.$t('$SenderDedicatedNumberProcessingPaymentDialog.Text'),
        acceptText: this.$t('$SenderDedicatedNumberProcessingPaymentDialog.AcceptText'),
        accept: () => this.$refs.vSelectServerPhoneNumber.$el.scrollIntoView(false),
      });
    },
    showSenderDedicatedNumberMissingPaymentDialog() {
      this.$showConfirmWarningDialog({
        title: this.$t('$SenderDedicatedNumberMissingPaymentDialog.Title'),
        text: this.$t('$SenderDedicatedNumberMissingPaymentDialog.Text'),
        acceptText: this.$t('$SenderDedicatedNumberMissingPaymentDialog.AcceptText'),
        cancelText: this.$t('$SenderDedicatedNumberMissingPaymentDialog.CancelText'),
        accept: this.payDedicatedNumber,
        cancel: () => this.$refs.vSelectServerPhoneNumber.$el.scrollIntoView(false),
      });
    },
    showSenderDedicatedNumberExpiredPaymentDialog() {
      this.$showConfirmWarningDialog({
        title: this.$t('$SenderDedicatedNumberExpiredPaymentDialog.Title'),
        text: this.$t('$SenderDedicatedNumberExpiredPaymentDialog.Text'),
        acceptText: this.$t('$SenderDedicatedNumberExpiredPaymentDialog.AcceptText'),
        cancelText: this.$t('$SenderDedicatedNumberExpiredPaymentDialog.CancelText'),
        accept: this.payDedicatedNumber,
        cancel: () => this.$refs.vSelectServerPhoneNumber.$el.scrollIntoView(false),
      });
    },
    showSenderDedicatedNumberCanceledDialog() {
      this.$showConfirmWarningDialog({
        title: this.$t('$SenderDedicatedNumberCanceledDialog.Title'),
        text: this.$t('$SenderDedicatedNumberCanceledDialog.Text'),
        acceptText: this.$t('$SenderDedicatedNumberCanceledDialog.AcceptText'),
        cancelText: this.$t('$SenderDedicatedNumberCanceledDialog.CancelText'),
        accept: this.payDedicatedNumber,
        cancel: () => this.$refs.vSelectServerPhoneNumber.$el.scrollIntoView(false),
      });
    },
    payDedicatedNumber() {
      this.showMissingPaymentCheckoutModal = true;
    },
    dedicatedNumberCrated(caller) {
      this.$refs.vSelectServerPhoneNumber.ItemCreatedOnModal();
      this.senderIdLocal = caller;

      setTimeout(() => {
        this.$refs.vSelectServerPhoneNumber.$validator.validateAll();
      }, 0);
    },
    onPaymentSent() {
      this.$refs.vSelectServerPhoneNumber.resetSelectServer();
    },
  },
};
</script>

<style scoped>

</style>
