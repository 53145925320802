<template>
  <div>
    <vs-alert
      color="success"
      active="true">
      <p>
        <span v-html="$t('VerificationMsg', {
            phoneNumber: this.phoneNumber
          })"></span>
        {{ $t('$General.Or') | lowercase }}
        <a
          href="#"
          @click.prevent="generateCodeForVerification()">
          {{ $t('GenerateNewCode') | lowercase }}
        </a>.
      </p>
    </vs-alert>
    <form @submit.prevent>
      <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full">
          <vs-input
            v-model="code"
            class="w-full required"
            :label="$t('InputName')"
            :name="$t('InputName')"
            v-validate="'required|digits:6'"
            data-vv-validate-on="blur|input"
            :danger="errors.has($t('InputName'))"
            :danger-text="errors.first($t('InputName'))"
            val-icon-danger="clear"/>
        </div>
      </div>

      <div class="vx-row mb-6 mt-6">
        <div class="vx-col w-full">
          <vs-checkbox v-model="addName">
            {{ $t('SaveNumberAsCallerMsg')}}
          </vs-checkbox>
        </div>
      </div>

      <div
        v-if="addName"
        class="vx-row mb-6 mt-6">
        <div class="vx-col w-full">
          <vs-input
            v-model="name"
            class="w-full required"
            :label="$t('CallerNumberName')"
            :name="$t('CallerNumberName')"
            v-validate="'required|max:100'"
            data-vv-validate-on="blur|input"
            :danger="errors.has($t('CallerNumberName'))"
            :danger-text="errors.first($t('CallerNumberName'))"
            val-icon-danger="clear"/>
        </div>
      </div>

      <base-form-footer-action-buttons
        @save="completePhoneNumberVerification()"
        @cancel="$emit('close')">
        <template v-slot:save>
          {{ $t('$General.Verify')}}
        </template>
      </base-form-footer-action-buttons>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { parsePhoneNumber } from 'libphonenumber-js';

// Mixins
import FromPhoneNumberVerificationPropsMixin from '@/views/modules/campaigns/sms-campaign/_components/from-phone-number-verification/from-phone-number-verification-props.mixin';
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';

// Services
import callerIdService from '@/api/modules/caller-id.service';

/**
 * Component to verify a number as from and save as caller number
 *
 * @module views/modules/campaigns/sms-campaign/_components/from-phone-number-verification/FromPhoneNumberVerification
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} code - validation code
 * @vue-data {string} name - name to save caller number
 * @vue-data {boolean} addName - indicate if save the caller number with name or no
 * @vue-data {object} callerId - the caller ID retrieved or created from the phone number
 * @vue-computed  {string} internationalPhoneNumber - international phone number in e164 format
 * @vue-event  {void} showStartedVerificationNtf - show ntf for started verification
 * @vue-event  {void} startPhoneNumberVerification - start the phone number verification
 * @vue-event  {void} generateCodeForVerification - call to generate the verification code
 * @vue-event  {void} completePhoneNumberVerification -
 * verify the phone number with the code entered
 */
export default {
  name: 'FromPhoneNumberVerification',
  i18n: {
    messages: {
      en: {
        VerificationMsg: 'You will receive an SMS with a verification code in <strong>{phoneNumber}</strong>. Please enter it below to use this phone number as a sender',
        GenerateNewCode: 'Generate a new code',
        InputName: 'Verification code',
        SaveNumberAsCallerMsg: 'Save this phone number with a name',
        CallerNumberName: 'Caller number name',
        StartedVerificationNtf: {
          Title: 'Phone number verification',
          Msg: 'Verification code was sent successfully',
        },
        CompletedVerificationNtf: {
          Title: 'Phone number verification',
          Msg: 'Phone number verified successfully',
        },
        AlreadyVerifiedNumberNtf: {
          Title: 'Phone number verification',
          Msg: 'The phone number is already verified',
        },
      },
    },
  },
  components: {
    BaseFormFooterActionButtons,
  },
  mixins: [FromPhoneNumberVerificationPropsMixin],
  data() {
    return {
      code: '',
      name: '',
      addName: false,
      callerId: null,
    };
  },
  computed: {
    internationalPhoneNumber() {
      const phoneNumberParsed = parsePhoneNumber(this.phoneNumber);

      return phoneNumberParsed.number;
    },
  },
  created() {
    this.startPhoneNumberVerification();
  },
  methods: {
    ...mapActions({
      initVerifyPhone: 'callerId/initVerifyPhone',
    }),
    showStartedVerificationNtf() {
      this.$showSuccessActionNotification({
        title: this.$t('StartedVerificationNtf.Title'),
        text: this.$t('StartedVerificationNtf.Msg'),
      });
    },
    async startPhoneNumberVerification() {
      const resp = await callerIdService.startPhoneNumberVerification(
        this.internationalPhoneNumber,
      );
      this.callerId = resp.data;

      if (this.callerId.status === this.$enums.Sender.Status.VERIFIED) {
        this.$showSuccessActionNotification({
          title: this.$t('AlreadyVerifiedNumberNtf.Title'),
          text: this.$t('AlreadyVerifiedNumberNtf.Msg'),
        });

        this.$emit('verified', this.callerId);
      } else {
        this.$emit('caller-retrieved', this.callerId);
        this.showStartedVerificationNtf();
      }
    },
    async generateCodeForVerification() {
      await this.initVerifyPhone({
        callerId: this.callerId.id,
      });

      this.showStartedVerificationNtf();
    },
    async completePhoneNumberVerification() {
      const valid = await this.$validator.validateAll();

      if (valid) {
        const resp = await callerIdService.completePhoneNumberVerification({
          callerId: this.callerId.id,
          code: this.code,
          name: this.name,
        });

        this.$showSuccessActionNotification({
          title: this.$t('CompletedVerificationNtf.Title'),
          text: this.$t('CompletedVerificationNtf.Msg'),
        });

        this.$emit('verified', resp.data);
      }
    },
  },
};
</script>
