<template>
  <vx-card
    :class="cardClassColor">
    <img
      src="@/assets/img/reviews/google-my-business-logo.png"
      alt="Google my business"
      class="mx-auto"
      width="177"
      height="22">

    <div class="mt-base font-bold flex items-start">
      <div class="pt-1">
        <i class="far fa-building text-primary" style="font-size: 1.7rem"></i>
      </div>
      <div class="ml-5">
        <p class="text-dark">{{ placeName }}</p>
        <p>{{ placeAddress }}</p>
        <a
          :href="reviewLink"
          target="_blank"
          class="link-contextual">
          {{ $t('$General.ReviewLink') }}
        </a>
      </div>
    </div>

    <div class="mt-base mb-5">
      <slot></slot>
    </div>
  </vx-card>
</template>

<script>

/**
 * Card to show business review settings
 *
 * @module views/modules/reviews/components/ReviewSettingsBusinessCardDetails
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {string} [cardClassColor=''] - color class for card
 * @vue-data {string} [placeId=''] - place id of business
 * @vue-data {string} [placeName=''] - place name of business
 * @vue-data {string} [placeAddress=''] - place address of business
 * @vue-computed {string} [reviewLink='...'] - google review link
 */
export default {
  name: 'ReviewSettingsBusinessCardDetails',
  props: {
    cardClassColor: {
      type: String,
      required: false,
      default: '',
    },
    placeId: {
      type: String,
      required: true,
    },
    placeName: {
      type: String,
      required: true,
    },
    placeAddress: {
      type: String,
      required: true,
    },
  },
  computed: {
    reviewLink() {
      return `https://search.google.com/local/writereview?placeid=${this.placeId}`;
    },
  },
};
</script>
