<template>
  <div>
    <v-select-server-phone-number
      ref="vSelectServerPhoneNumber"
      v-model="senderIdLocal"
      :placeholder="$t('$SenderCallerNumber.Placeholder')"
      :show-label="false"
      :select-name="$t('$General.From')"
      :fetch-function="fetchAllCallerIds"
      :filters-match="$enums.AppFilterMathType.ALL"
      :filter-params="filterParamsToFetchPhoneNumbers"
      :search-filters="searchFiltersToSendersIds"
      :use-default-search-filters="false"
      close-on-select
      taggable
      :show-create="true"
      :create-text="$t('CreateNewSenderIDLink', {
              name: this.$tc(`$Entities.${this.$enums.Entity.CALLER_ID}`),
            })"
      :create-new-modal-entity="$tc(`$Entities.${this.$enums.Entity.CALLER_ID}`)"
      manage-route-name="caller-ids"
      :manage-text="$t('$CallerIdModule.ManageCallerIds')"
      :option-emphasis="'phoneInternational'"
      @input="onVSelectPhoneNumberSelection()">
      <template v-slot:option="{ item }">
        <v-select-phone-number-option :option="item"/>
      </template>
      <template v-slot:selected-option="{ item }">
        <v-select-phone-number-option :option="item"/>
      </template>

      <template v-slot:create="{ nameToCreate }">
        <caller-id-list-create-or-edit-from-phone-number
          :entity="$enums.Entity.CALLER_ID"
          :operation="$enums.Operation.CREATE"
          :name-to-create="nameToCreate"
          @saved="callerCreatedFromCallerIdCreateModal"
          @close="$refs.vSelectServerPhoneNumber.closeCreateModal()">
        </caller-id-list-create-or-edit-from-phone-number>
      </template>
    </v-select-server-phone-number>

    <from-phone-number-verification-modal
      v-model="activeModalFromPhoneNumberVerification"
      :phone-number="senderIdLocal.phoneInternational || senderIdLocal"
      @verified="afterVerifyPhoneNumber"
      @caller-retrieved="onCallerRetrieved"/>

    <vs-popup
      :title="$t('$SenderIdsModules.VerificationModalTitle', {
        entity: this.$tc(`$Entities.${this.$enums.Entity.CALLER_ID}`),
        name: this.recordToVerify ? this.recordToVerify.name : '',
        value: this.recordToVerify ? this.recordToVerify.phoneInternational : ''
      })"
      :active.sync="activeModalVerify">
      <transition name="zoom-fade">
        <sender-id-verification
          v-if="activeModalVerify"
          :model-payload="recordToVerify"
          :message="$t('$CallerIdModule.VerifyPhoneMsg')"
          :entity="$enums.Entity.CALLER_ID"
          :send-code="sendVerificationCode"
          @close="activeModalVerify=false"
          @verified="afterVerifySenderId">
        </sender-id-verification>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

// components
import VSelectServerPhoneNumber from '@/views/modules/_components/v-select-server/VSelectServerPhoneNumber.vue';
import CampaignInformationVSelectPhoneNumberOption from '@/views/modules/campaigns/components/campaign-information/CampaignInformationVSelectPhoneNumberOption.vue';
import CallerIdListCreateOrEditFromPhoneNumber from '@/views/modules/sender-ids/caller-id/CallerIdListCreateOrEditFromPhoneNumber.vue';
import FromPhoneNumberVerificationModal
from '@/views/modules/campaigns/sms-campaign/_components/from-phone-number-verification/FromPhoneNumberVerificationModal.vue';
import SenderIdVerification from '@/views/modules/sender-ids/_components/SenderIdVerification.vue';

// Mixins
import senderIdVerification from '@/views/modules/_mixins/senderIdVerification.mixin';

/**
 * Component select phone number in campaign information
 *
 * @module views/modules/campaigns/components/campaign-information/CampaignInformationVSelectPhoneNumber
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object | string} senderIdLocal - local sender id
 * @vue-data {boolean} activeModalFromPhoneNumberVerification -
 * indicate if show or no the modal for verification a phone number as from
 * @vue-data {Object} filterParamsToFetchPhoneNumbers - filters params to fetch phone numbers
 * @vue-data {Object} searchFiltersToSendersIds - search filters for caller numbers
 * @vue-computed {boolean} isValid - indicate is component is valid
 * @vue-computed {Object} lastCreated - last created caller id
 * @vue-event {boolean} validate - validate component
 * @vue-event {void} onVSelectPhoneNumberSelection - called on v select phone number selection
 * @vue-event {void} showSenderPhoneNumberInvalidDialog - show sender phone number invalid dialog
 * @vue-event {void} onCallerRetrieved - called on caller retrieved
 * @vue-event {void} afterVerifyPhoneNumber - called after verify a phone number
 * @vue-event {void} verifySenderIdLocal - show the verification modal for senderIdLocal
 * @vue-event {void} afterVerifySenderId - called after verify a sender id
 * @vue-event {void} callerCreatedFromCallerIdCreateModal - called after caller id created
 */
export default {
  name: 'CampaignInformationVSelectPhoneNumber',
  i18n: {
    messages: {
      en: {
        CreateNewSenderIDLink: 'Create a new {name}',
        $SenderCallerNumber: {
          Placeholder: 'Select a caller number or enter a phone number',
        },
        $SenderPhoneNumberInvalidDialog: {
          Title: 'Caller number not verified',
          Text: 'The caller number you are trying to use is not verified and therefore you cannot use it as a valid from.',
          AcceptText: 'Verify now',
          CancelText: 'Change caller number',
        },
      },
    },
  },
  mixins: [senderIdVerification],
  components: {
    VSelectServerPhoneNumber,
    vSelectPhoneNumberOption: CampaignInformationVSelectPhoneNumberOption,
    CallerIdListCreateOrEditFromPhoneNumber,
    FromPhoneNumberVerificationModal,
    SenderIdVerification,
  },
  props: {
    value: {
      type: [Object, String],
      required: true,
    },
  },
  data() {
    return {
      senderIdLocal: this.value,
      activeModalFromPhoneNumberVerification: false,
      filterParamsToFetchPhoneNumbers: {
        type: {
          filterType: this.$enums.AppFilterType.TEXT,
          type: this.$enums.AppFilter.FilterType.Text.Type.EQUALS,
          filter: this.$enums.Sender.Caller.Type.PHONE_NUMBER,
        },
      },
      searchFiltersToSendersIds: {
        nameOrPhoneInternational: {
          filterType: this.$enums.AppFilterType.CONDITIONAL,
          filtersMatch: this.$enums.AppFilterMathType.ANY,
          filter: {
            name: {
              filterType: this.$enums.AppFilterType.TEXT,
              type: this.$enums.AppFilter.FilterType.Text.Type.CONTAINS,
              filter: '',
            },
            phoneInternationalSignificant: {
              filterType: this.$enums.AppFilterType.TEXT,
              type: this.$enums.AppFilter.FilterType.Text.Type.CONTAINS,
              filter: '',
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState({
      lastCreatedCaller: (state) => state.callerId.lastCreated,
    }),
    isValid() {
      return typeof this.senderIdLocal === 'object'
        && this.senderIdLocal && this.senderIdLocal.status
        && this.senderIdLocal.status === this.$enums.Sender.Status.VERIFIED;
    },
    lastCreated() {
      return this.lastCreatedCaller;
    },
  },
  watch: {
    value(val) {
      this.senderIdLocal = val;
    },
    senderIdLocal(val) {
      this.$emit('input', val || '');
    },
    isValid: {
      immediate: true,
      handler(value) {
        this.$emit('validate', value);
      },
    },
  },
  methods: {
    ...mapActions({
      fetchAllCallerIds: 'callerId/fetchAllCallerIds',
    }),
    async validate() {
      const result = typeof this.senderIdLocal === 'object'
        && this.senderIdLocal && this.senderIdLocal.status
        && this.senderIdLocal.status === this.$enums.Sender.Status.VERIFIED;

      if (!this.senderIdLocal) {
        await this.$refs.vSelectServerPhoneNumber.$validator.validateAll();
      } else {
        this.showSenderPhoneNumberInvalidDialog();
      }

      return result;
    },
    onVSelectPhoneNumberSelection() {
      if (typeof this.senderIdLocal === 'string'
        && this.senderIdLocal) {
        this.activeModalFromPhoneNumberVerification = true;
      } else {
        this.showSenderPhoneNumberInvalidDialog();
      }
    },
    showSenderPhoneNumberInvalidDialog() {
      if (typeof this.senderIdLocal === 'object'
        && this.senderIdLocal
        && this.senderIdLocal.status
        && this.senderIdLocal.status === this.$enums.Sender.Status.UNVERIFIED) {
        this.$showConfirmWarningDialog({
          title: this.$t('$SenderPhoneNumberInvalidDialog.Title'),
          text: this.$t('$SenderPhoneNumberInvalidDialog.Text'),
          acceptText: this.$t('$SenderPhoneNumberInvalidDialog.AcceptText'),
          cancelText: this.$t('$SenderPhoneNumberInvalidDialog.CancelText'),
          accept: this.verifySenderIdLocal,
          cancel: () => this.$refs.vSelectServerPhoneNumber.$el.scrollIntoView(false),
        });
      }
    },
    onCallerRetrieved(caller) {
      this.$refs.vSelectServerPhoneNumber.resetSelectServer();
      this.senderIdLocal = caller;
    },
    afterVerifyPhoneNumber(caller) {
      this.$refs.vSelectServerPhoneNumber.resetSelectServer();
      this.senderIdLocal = caller;
    },
    verifySenderIdLocal() {
      this.sendVerificationCode = true;
      this.recordToVerify = this.senderIdLocal;
      this.activeModalVerify = true;
    },
    afterVerifySenderId(caller) {
      this.activeModalVerify = false;
      this.$refs.vSelectServerPhoneNumber.resetSelectServer();

      if (caller && caller.id && this.senderIdLocal && this.senderIdLocal.id
        && caller.id === this.senderIdLocal.id) {
        this.senderIdLocal.status = this.$enums.Sender.Status.VERIFIED;
      }
    },
    callerCreatedFromCallerIdCreateModal(caller) {
      this.$refs.vSelectServerPhoneNumber.ItemCreatedOnModal();
      this.senderIdLocal = caller;

      setTimeout(() => {
        this.$refs.vSelectServerPhoneNumber.$validator.validateAll();
      }, 0);
    },
  },
};
</script>

<style scoped>

</style>
