<template>
  <div>
    <app-loading
      :active="showAppLoading && !preventAppLoading"
      :text="fetchedData ? '' : $t('LoadingPaymentInformation')"
    />

    <div v-if="getTenantFlag('completedAccountDetails')">
      <app-checkout
        v-if="fetchedData && appSettings"
        :product-price="dedicatedNumberAppServiceItemPriceByInterval"
        :product-name="productNameToCheckout"
        :gst="appSettingsGst"
        :payment-methods="paymentMethods"
        :selected-payment-method.sync="selectedPaymentMethod"
        :extra-items="extraItems"
        recurrent
        :interval.sync="selectedInterval"
        :inactive-intervals="dedicatedNumberAppServiceItemInactiveIntervalsKeys"
        :show-input-intervals="!tenantAppServiceItemBillingInterval"
        @payment-intent="paymentIntent">
        <template v-slot:before-pay-button>
          <slot name="before-pay-button"></slot>
        </template>

        <template
          v-if="dedicatedNumberAppServiceItemIntervalDiscount > 0"
          v-slot:after-recurrent>
          <div class="text-center font-semibold">
            {{ $t('SaveAtLeast', {
              discount: dedicatedNumberAppServiceItemIntervalDiscount,
            }) }}
          </div>
        </template>
      </app-checkout>
    </div>
    <div v-else>
      <user-account-settings-details on-modal>
        <template v-slot:title>
          <h4 class="text-center mb-5">
            {{ $t('CompleteYourAccountDetailsToPay') }}
          </h4>
        </template>

        <template v-slot:buttons-wrapper="{ save }">
          <div class="flex items-center justify-end mt-12">
            <vs-button
              @click="save()">
              {{ $t('$General.GoToPayment') }}
            </vs-button>
          </div>
        </template>
      </user-account-settings-details>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

// Components
import AppCheckout from '@/views/modules/_components/app-checkout/AppCheckout.vue';
import UserAccountSettingsDetails
from '@/views/modules/user/user-account/user-account-settings/UserAccountSettingsDetails.vue';
import AppLoading from '@/views/_components/AppLoading.vue';

// Mixins
import purchaseMixin from '@/views/modules/_mixins/purchase.mixin';
import dedicatedNumberAppServiceMixin
from '@/views/modules/sender-ids/caller-id/_mixins/dedicated-number-app-service.mixin';

// Services
import tenantService from '@/api/modules/tenant.service';

/**
 * Dedicated number payment checkout
 *
 * @module views/modules/sender-ids/caller-id/_components/DedicatedNumberPaymentCheckout
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object} callerId - the value to show or not the modal
 * @vue-prop {Function || null} onPaymentIntentCallback - payment intent callback
 * @vue-data {boolean} fetchedData - indicate if initial data was fetched
 * @vue-data {Object || null} tenantAppServiceItem - the app service item in the tenant
 * @vue-computed {string} productNameToCheckout - product name to use in checkout
 * @vue-computed {Object || null} tenantAppServiceItemStatus -
 * the app service item status in the tenant
 * @vue-computed {string} tenantAppServiceItemBillingInterval -
 * billing interval from tenant app service
 * @vue-computed {string} callerIdStatus - caller status
 * @vue-computed {Array<Object>} extraItems - extra item to use in the checkout
 * @vue-event {void} fetchData - fetch initial data
 * @vue-event {void} loadTenantAppServiceItem - load tenant app service item
 * @vue-event {void} paymentIntent - called on payment intent
 * @vue-event {void} sentPaymentIntent - sent a payment intent to API
 * @vue-event {void} showPaymentIntentSentNotification - show payment intent sent notification
 */
export default {
  name: 'DedicatedNumberPaymentCheckout',
  i18n: {
    messages: {
      en: {
        Title: 'Dedicated number payment',
        LoadingPaymentInformation: 'Loading payment information',
        CompleteYourAccountDetailsToPay: 'Complete your account details to make the payment',
        SaveAtLeast: '(Save at least {discount}%!)',
        $PaymentAttemptNotification: {
          Title: 'Dedicated number payment attempt',
          Msg: 'The payment will be processed in a few seconds',
        },
      },
    },
  },
  components: {
    AppCheckout,
    AppLoading,
    UserAccountSettingsDetails,
  },
  mixins: [purchaseMixin, dedicatedNumberAppServiceMixin],
  props: {
    callerId: {
      type: Object,
      required: true,
    },
    onPaymentIntentCallback: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      fetchedData: false,
      tenantAppServiceItem: null,
    };
  },
  computed: {
    ...mapGetters({
      getTenantFlag: 'auth/getTenantFlag',
      getTenantAppServiceItemByDataAttr: 'auth/getTenantAppServiceItemByDataAttr',
    }),
    productNameToCheckout() {
      return `${this.dedicatedNumberAppServiceName} (${this.$options.filters.phone(this.callerId.phoneInternational)})`;
    },
    tenantAppServiceItemStatus() {
      return this.tenantAppServiceItem
        ? this.tenantAppServiceItem.status
        : null;
    },
    tenantAppServiceItemBillingInterval() {
      return this.tenantAppServiceItem
        ? this.tenantAppServiceItem.billingInterval
        : null;
    },
    callerIdStatus() {
      return this.callerId && this.callerId.status
        ? this.callerId.status
        : '';
    },
    extraItems() {
      // eslint-disable-next-line max-len
      return this.tenantAppServiceItemStatus !== this.$enums.Tenants.APP_SERVICE_ITEM_STATUS.PAST_DUE
        || this.callerIdStatus === this.$enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT
        ? this.firstMonthFeeCheckoutExtraItem
        : [];
    },
  },
  created() {
    this.fetchData();
  },
  watch: {
    async callerId() {
      this.loadTenantAppServiceItem();
    },
    tenantAppServiceItemBillingInterval(interval) {
      this.selectedInterval = interval;
    },
  },
  methods: {
    ...mapActions({
      fetchTenantOfUser: 'auth/fetchTenantOfUser',
    }),
    async fetchData() {
      this.showAppLoading = true;

      await this.fetchTenantOfUser();
      await this.getDedicatedNumberAppService();
      await this.getAppSettings();
      await this.getPaymentMethods();
      this.loadTenantAppServiceItem();

      this.fetchedData = true;
      this.showAppLoading = false;
    },
    loadTenantAppServiceItem() {
      if (this.dedicatedNumberAppService) {
        this.tenantAppServiceItem = this.getTenantAppServiceItemByDataAttr({
          appServiceId: this.dedicatedNumberAppService.id,
          dataAttrKey: 'callerId',
          dataAttrValue: this.callerId.id,
        });
      }
    },
    async paymentIntent(creditCardPayload) {
      try {
        this.showAppLoading = true;
        await this.addNewCreditCard(creditCardPayload);
      } catch (err) {
        this.showAppLoading = false;
        return;
      }

      try {
        if (this.onPaymentIntentCallback) {
          await this.onPaymentIntentCallback({
            selectedPaymentMethodId: this.selectedPaymentMethod.id,
            selectedBillingInterval: this.selectedInterval,
          });
        } else {
          await this.sentPaymentIntent();
        }

        this.showPaymentIntentSentNotification();
        this.$emit('payment');
        this.showAppLoading = false;
      } catch (err) {
        this.showAppLoading = false;
      }
    },
    async sentPaymentIntent() {
      this.showAppLoading = true;

      const payload = {
        callerId: this.callerId.id,
        paymentMethod: this.selectedPaymentMethod.id,
      };

      await tenantService.payDedicatedNumber(payload);
    },
    showPaymentIntentSentNotification() {
      this.$showAsyncActionNotification({
        title: this.$t('$PaymentAttemptNotification.Title'),
        text: this.$t('$PaymentAttemptNotification.Msg'),
      });
    },
  },
};
</script>

<style scoped>

</style>
