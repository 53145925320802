import { parsePhoneNumber } from 'libphonenumber-js';

export default {
  props: {
    phoneNumber: {
      type: String,
      required: true,
      validate(phoneNumber) {
        try {
          const phoneNumberParsed = parsePhoneNumber(phoneNumber);
          return phoneNumberParsed && phoneNumberParsed.isValid();
        } catch (e) {
          return false;
        }
      },
    },
  },
};
