<template>
  <div>
    <div>
      <label class="vs-input--label">
        {{ $t('$SMSCampaignModule.InsertSMSTemplate') }}
      </label>
    </div>

    <div class="flex items-center input-sms-template-insert">
      <v-select-server
        :key="selectSmsTemplateKey"
        v-model="selectedTemplateToInsert"
        :fetch-function="fetchAllSMSTemplates"
        closeOnSelect
        :clearable="false"
        manage-route-name="sms-templates"
        :manage-text="$t('$SMSCampaignModule.ManageSMSTemplates')"
        :placeholder="$t('$SMSCampaignModule.SelectSMSTemplatePlaceholder')"
        @create="(name) => createNewTemplate(name)"/>

      <div class="btn-group flex dropdown-button-container ml-3">
        <vs-button
          :disabled="!selectedTemplateToInsert"
          icon-pack="feather"
          icon="icon-message-circle"
          size="small"
          @click="insertTemplate()">
          {{ $t('$SMSCampaignModule.InsertSMSTemplateButton') }}
        </vs-button>
        <vs-button
          :disabled="!selectedTemplateToInsert"
          icon-pack="feather"
          icon="icon-eye"
          size="small"
          @click="activeModalViewTemplate=true">
          {{ $t('$General.View') }}
        </vs-button>
      </div>
    </div>

    <vs-popup
      :title="viewSmsTemplateTitle"
      :active.sync="activeModalViewTemplate">
      <transition name="zoom-fade">
        <div>
          {{ selectedTemplateToInsertMessage }}
        </div>
      </transition>
    </vs-popup>

    <vs-popup
      :title="$t('$Modals.CreateModalTitle', {
          entity: this.$tc('$Entities.SMSTemplate'),
        })"
      :active.sync="activeModalCreateOrEditTemplate">
      <transition name="zoom-fade">
        <s-m-s-template-list-create-or-edit
          v-if="activeModalCreateOrEditTemplate"
          ref="createOrEdit"
          :entity="$enums.Entity.SMS_TEMPLATE"
          :operation="$enums.Operation.CREATE"
          :name="smsTemplateName"
          @saved="savedTemplate()"
          @close="activeModalCreateOrEditTemplate=false">
        </s-m-s-template-list-create-or-edit>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import VSelectServer from '@/views/modules/_components/v-select-server/VSelectServer.vue';
import SMSTemplateListCreateOrEdit from '@/views/modules/templates/sms-template/SMSTemplateListCreateOrEdit.vue';

/**
 * Component to select and insert SMS templates
 *
 * @module views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEditMessageInsertTemplate
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object} selectedTemplateToInsert - selected template
 * @vue-data {boolean} activeModalViewTemplate - indicate if show or no the view template modal
 * @vue-computed {string} viewSmsTemplateTitle - view template title
 * @vue-computed {string} selectedTemplateToInsertName - selected template name
 * @vue-computed {string} selectedTemplateToInsertMessage - selected template message
 * @vue-event {void} insertTemplate - emit event to insert the template message
 */
export default {
  name: 'SMSCampaignListCreateOrEditMessageInsertTemplate',
  components: {
    VSelectServer,
    SMSTemplateListCreateOrEdit,
  },
  data() {
    return {
      selectedTemplateToInsert: null,
      activeModalViewTemplate: false,
      activeModalCreateOrEditTemplate: false,
      selectSmsTemplateKey: 0,
      smsTemplateName: '',
    };
  },
  computed: {
    viewSmsTemplateTitle() {
      return `${this.$tc('$Entities.SMSTemplate')} - ${this.selectedTemplateToInsertName}`;
    },
    selectedTemplateToInsertName() {
      return this.selectedTemplateToInsert
        ? this.selectedTemplateToInsert.name
        : '';
    },
    selectedTemplateToInsertMessage() {
      return this.selectedTemplateToInsert
        ? this.selectedTemplateToInsert.message
        : '';
    },
  },
  methods: {
    ...mapActions({
      fetchAllSMSTemplates: 'smsTemplate/fetchAllSMSTemplates',
    }),
    insertTemplate() {
      if (this.selectedTemplateToInsert) {
        this.$emit('insert', this.selectedTemplateToInsertMessage);
      }

      this.selectedTemplateToInsert = null;
    },
    createNewTemplate(name = '') {
      this.smsTemplateName = name;
      this.activeModalCreateOrEditTemplate = true;
    },
    savedTemplate() {
      this.activeModalCreateOrEditTemplate = false;
      this.selectSmsTemplateKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-button-container .vs-button {
  padding: 0.715rem 1rem !important;
  max-height: 38px;
}

@media (max-width: 500px) {
  .input-sms-template-insert {
    flex-wrap: wrap;

    .dropdown-button-container {
      margin-left: 0 !important;
      margin-top: 0.5rem !important;
      width: 100%;
      > .vs-button {
        width: 100% !important;
      }
    }
  }
}

</style>
