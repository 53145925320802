<template>
  <div
    style="width: 350px"
    class="mx-auto">
    <p class="text-center font-medium">
      {{ $t('$ReviewsSettingsModule.$ConfirmBusinessTab.Title') }}
    </p>

    <p class="text-center font-bold">
      {{ $t('$ReviewsSettingsModule.$ConfirmBusinessTab.Subtitle') }}
    </p>

    <business-card-details
      class="mt-5"
      :place-id="placeId"
      :place-address="placeAddress"
      :place-name="placeName"
      card-class-color="bg-theme-background">
      <vs-button
        class="w-full"
        @click.prevent="$emit('continue')">
        {{ $t('$ReviewsSettingsModule.$ConfirmBusinessTab.ConfirmButtonText') }}
      </vs-button>

      <div class="mt-base text-center">
        <a
          href="#"
          class="link-cta"
          @click.prevent="$emit('back')">
          {{ $t('$ReviewsSettingsModule.$ConfirmBusinessTab.CancelButtonText') }}
        </a>
      </div>
    </business-card-details>
  </div>
</template>

<script>
import ReviewSettingsBusinessCardDetails from '@/views/modules/reviews/components/ReviewSettingsBusinessCardDetails.vue';
/**
 * Confirm business for reviews create or edit review settings
 *
 * @module views/modules/reviews/ReviewsSettingsCreateOrEditConfirmBusinessTab
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} placeId - place id of business in Google
 * @vue-prop {string} placeName - name of business in Google
 * @vue-prop {string} placeAddress - address of business in Google
 * @vue-computed {string} reviewLink - reviews link of business in Google
 */
export default {
  name: 'ReviewsSettingsCreateOrEditConfirmBusinessTab',
  components: {
    BusinessCardDetails: ReviewSettingsBusinessCardDetails,
  },
  props: {
    placeId: {
      type: String,
      required: true,
    },
    placeName: {
      type: String,
      required: true,
    },
    placeAddress: {
      type: String,
      required: true,
    },
  },
};
</script>
