<template>
  <div>
    <div class="mb-base">
      <h2
        class="text-center mb-3"
        v-html="$t(`$CampaignsModules.$CampaignConfirm.CampaignReadyTo${deliveryTypeText}Title`, {
          cost: this.$options.filters.dollar(costInfo.campaignTotalPrice),
        })">
      </h2>
      <h5 class="text-center">
        {{ $t(`$CampaignsModules.$CampaignConfirm.CampaignReadyTo${deliveryTypeText}Subtitle`) }}
      </h5>
    </div>

    <div class="confirm-wrapper">
      <campaign-confirm-item
        :title="$t('$CampaignsModules.CampaignInfo')"
        first
        @return="$emit('return', 0)">
        <p>
          <span class="font-bold">{{ $t('$General.Name') }}:</span>
          <span class="pl-2">{{ model.name }}</span>
        </p>

        <p class="mt-3">
          <span class="font-bold">{{ $t('$General.From') }}:</span>
          <span class="pl-2">{{ senderIdText }}</span>
        </p>

        <p class="mt-3">
          <span class="font-bold">{{ $tc('$General.Type') }}:</span>
          <span class="pl-2">{{ $t(`$CampaignsModules.$CampaignType.${model.messageType}`) }}</span>
        </p>
        <slot name="info"></slot>
      </campaign-confirm-item>

      <campaign-confirm-item
        :title="messageTitle"
        @return="$emit('return', 1)">
        <template
          v-slot:subtitle
          v-if="messagePreviewLink">
          <a
            href="#"
            @click.prevent="$emit('message-preview')">
            {{ $t('$General.Preview') }}
          </a>
        </template>
        <slot name="message"></slot>
      </campaign-confirm-item>

      <campaign-confirm-item
        :title="$tc('$Entities.Contact', 2)"
        @return="$emit('return', 2)">
        <template v-slot:subtitle>
          <contacts-match-count
            :filters-match="model.filtersMatch"
            :get-valid-filters="() => model.filters"
            :is-there-any-valid-filter="true"
            :show-refresh="false"
            :selected-from-campaign="true"
            :show-contacts-list-link="true"
            :manage-contacts-links="false"
            :campaign-message-type="model.messageType"
            :count-message="$t('$CampaignsModules.$CampaignConfirm.ContactsStepSubtitle', {
              count: contactsFilteredCount,
            })"
            :hide-count="true"
            @count-changed="contactsFilteredCount = $event"
          />
        </template>
        <vs-alert
          active="true"
          :color="contactsAlertColor"
          icon-pack="feather"
          :icon="contactsAlertIcon"
          style="height: auto">
          <strong>
            {{ $t('$CampaignsModules.$CampaignConfirm.ContactsYouCanSend', {
              count: this.costInfo.contactsCanSend,
              total: this.costInfo.totalContactsToSend,
            }) }}
          </strong>
          <vs-progress
            :height="8"
            :percent="contactsAlertPercent"
            :color="contactsAlertColor"></vs-progress>

          <div v-if="!costInfo.userHasSufficientBalance">
            {{ $t('$CampaignsModules.$CampaignConfirm.InsufficientBalanceMsg1') }}
            <a
              class="link-decorate ml-0"
              href="#"
              @click.prevent="showCheckout=true">{{ $t('$General.Here') | lowercase }}</a>
            {{ $t('$CampaignsModules.$CampaignConfirm.InsufficientBalanceMsg2') }}
          </div>
        </vs-alert>

        <slot
          name="contacts"
          v-bind:costInfo="costInfo">
        </slot>
      </campaign-confirm-item>

      <campaign-confirm-item
        :title="$t('$CampaignsModules.CampaignDeliveryType')"
        @return="$emit('return', 3)">

        <p>
          <span class="font-bold">{{ $t('$General.DeliveryTime') }}:</span>
          <span class="pl-2">{{ deliveryTypeValue }}</span>
        </p>

        <div v-if="this.model.deliveryType === this.$enums.Campaign.DeliveryType.LATER">
          <p class="mt-3">
            <span class="font-bold">{{ $tc('$General.TimeZone') }}:</span>
            <span class="pl-2">{{ $t(`$TimeZones.${model.timeZone}`) }}</span>
          </p>

          <p class="mt-3">
            <span class="font-bold">{{ $t('$CampaignsModules.LocalStartTime') }}:</span>
            <span class="pl-2">
              {{ model.localStartDate | date(true, true, true, false, false, false) }}
            </span>
          </p>

          <campaign-schedule-time-alert
            class="mt-3"
            :is-quick="model.isQuick"
            :local-start-date="model.localStartDate"
            :time-zone="model.timeZone"
            @start-immediately="(val) => $emit('start-immediately', val)"/>
        </div>
      </campaign-confirm-item>
    </div>

    <prepaid-package-checkout-modal
      :active.sync="showCheckout"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

// Components
import CampaignConfirmItem from '@/views/modules/campaigns/components/campaign-confirm/CampaignConfirmItem.vue';
import PrepaidPackageCheckoutModal from '@/views/modules/_components/PackageCheckoutModal.vue';

// Mixins
import campaignStep from '@/views/modules/campaigns/common/mixins/campaignStep';
import CampaignScheduleTimeAlert from '@/views/modules/campaigns/components/CampaignScheduleTimeAlert.vue';
import ContactsMatchCount
from '@/views/modules/segment/create-or-edit/SegmentListCreateOrEditContactsMatchCount.vue';

/**
 * Confirm to send one campaign
 *
 * @module views/modules/components/campaigns/CampaignConfirm
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object} model - campaign info
 * @vue-prop {string} messageTitle - title for message step
 * @vue-prop {Function} fetchCampaignCostInfo - function to fetch campaign cost
 * @vue-prop {bool} messagePreviewLink - indicate if show or no the message preview link
 * @vue-prop {Object} costInfo - campaign cost info
 * @vue-prop {boolean} showCheckout - indicate if show or no the checkout modal
 * @vue-computed {string} deliveryTypeText - text for delivery type
 * @vue-computed {string} senderIdText - sender ID text to show
 * @vue-computed {string} deliveryTypeValue - delivery type text to show
 * @vue-computed {string} contactsAlertColor - color of contacts alert
 * @vue-computed {string} contactsAlertIcon - icon of contacts alert
 * @vue-computed {string} contactsAlertPercent - send rate of contacts
 * @vue-event {void} fetchData - fetch data
 * @vue-event {void} getCampaignCostInfo - get the campign cost info
 */
export default {
  name: 'CampaignConfirm',
  components: {
    ContactsMatchCount,
    CampaignConfirmItem,
    PrepaidPackageCheckoutModal,
    CampaignScheduleTimeAlert,
  },
  mixins: [campaignStep],
  props: {
    model: {
      type: Object,
      required: true,
    },
    messageTitle: {
      type: String,
      required: true,
    },
    fetchCampaignCostInfo: {
      type: Function,
      required: true,
    },
    messagePreviewLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      costInfo: {
        userHasSufficientBalance: true,
        campaignTotalPrice: 0,
        totalContactsToSend: 0,
        contactsCanSend: 0,
        contactsWithCutMessage: 0,
      },
      showCheckout: false,

      contactsFilteredCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      authUserBalance: 'auth/balance',
    }),
    deliveryTypeText() {
      return this.model.deliveryType === this.$enums.Campaign.DeliveryType.LATER
        ? 'Schedule' : 'Send';
    },
    senderIdText() {
      switch (this.campaignType) {
        case this.$enums.Campaign.Type.EMAIL:
          return `${this.model.senderId.name}(${this.model.senderId.email})`;
        case this.$enums.Campaign.Type.SMS:
        case this.$enums.Campaign.Type.VOICEMAIL:
          switch (this.model.callerIdType) {
            case this.$enums.Sender.Caller.Type.SHARED_NUMBER:
              return this.$options.filters.sentencecase(this.$t('$General.SharedNumber'));
            case this.$enums.Sender.Caller.Type.PHONE_NUMBER:
              return `${this.model.senderId.name}(${this.model.senderId.phoneInternational})`;
            case this.$enums.Sender.Caller.Type.BUSINESS_NAME:
              return this.model.senderBusinessName;

            default:
              return this.$options.filters.sentencecase(this.$t('$General.ReplyNumber'));
          }

        default:
          return this.$options.filters.sentencecase(this.$t('$General.ReplyNumber'));
      }
    },
    deliveryTypeValue() {
      return this.$t(`$CampaignsModules.${
        this.model.deliveryType === this.$enums.Campaign.DeliveryType.IMMEDIATELY
          ? 'CampaignStartImmediately'
          : 'CampaignScheduleLater'}`);
    },
    contactsAlertColor() {
      return this.costInfo.userHasSufficientBalance ? 'success' : 'warning';
    },
    contactsAlertIcon() {
      return this.costInfo.userHasSufficientBalance ? 'icon-check' : 'icon-alert-circle';
    },
    contactsAlertPercent() {
      return this.costInfo
        ? (this.costInfo.contactsCanSend * 100) / this.costInfo.totalContactsToSend
        : 0;
    },
  },
  watch: {
    authUserBalance() {
      this.getCampaignCostInfo();
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.getCampaignCostInfo();
    },
    async getCampaignCostInfo() {
      this.$vs.loading({ type: 'radius' });
      this.costInfo = await this.fetchCampaignCostInfo(this.model.toCreatePayload());
      this.$vs.loading.close();
      this.$emit('const-info', this.costInfo);
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-wrapper {
  border: 1px solid rgba(var(--vs-grey),1);
  border-radius: 3px;
}
</style>
