<template>
  <div class="vx-row flex flex-wrap-reverse mx-0 w-full">
    <div class="vx-col w-full md:w-1/2 flex flex-wrap justify-between md:justify-start">
      <wizard-button
        :style="wizardProps.fillButtonStyle"
        class="w-full md:w-auto mr-0 md:mr-3 mb-3 md:mb-0 nav-prev"
        @click.native="$emit('cancel')">
        <i class="nav-icon feather icon-x"></i>
        <div class="nav-text">
          {{ $t(`$General.${wizardProps.isLastStep ? 'Close' : 'Cancel'}`) }}
        </div>
      </wizard-button>

      <wizard-button
        v-if="wizardProps.activeTabIndex > 0"
        :style="wizardProps.fillButtonStyle"
        class="w-full md:w-auto mr-0 md:mr-3 mb-3 md:mb-0 nav-prev"
        @click.native="wizardProps.prevTab()">
        <i class="nav-icon feather icon-arrow-left"></i>
        <div class="nav-text">
          {{ $t('$General.Back') }}
        </div>
      </wizard-button>

      <wizard-button
        v-if="!wizardProps.isLastStep"
        :style="wizardProps.fillButtonStyle"
        class="w-full md:w-auto nav-next"
        @click.native="wizardProps.nextTab()">
        <i class="nav-icon feather icon-arrow-right"></i>
        <div class="nav-text">
          {{ $t('$General.Continue') }}
        </div>
      </wizard-button>
    </div>
  </div>
</template>

<script>
import { WizardButton } from 'vue-form-wizard';

/**
 * Reviews settings create or edit wizard buttons
 *
 * @module views/modules/reviews/ReviewsSettingsCreateOrEditWizardButtons
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object} wizardProps - wizard props
 */
export default {
  name: 'ReviewsSettingsCreateOrEditWizardButtons',
  components: {
    WizardButton,
  },
  props: {
    wizardProps: {
      type: Object,
      required: true,
    },
  },
};
</script>
