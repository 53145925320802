<template>
  <div class="v-select-server-phone-number">
    <div
      class="flex county-select-box gap-2 md:gap-0"
      :class="showLabel ? 'items-end' : 'items-start'">
      <label
        v-if="showLabel"
        class="vs-input--label required">
        {{ selectName }}
      </label>
      <div
        v-if="showCountryCode"
        class="mr-1 county-select-list">
        <vue-country-code
          :class="{ 'border-danger': errors.has(selectName) }"
          enabled-country-code
          disabled-fetching-country
          :defaultCountry="defaultCountry"
          :preferredCountries="['AU', 'CO']"
          @onSelect="selectedDefaultCountry">
        </vue-country-code>
      </div>
      <div class="w-full phone-input-box">
        <label
          v-if="showLabel"
          class="vs-input--label required">
          {{ selectName }}
        </label>
        <v-select-server
          ref="vSelectServer"
          :key="vSelectServerKey"
          v-model="selection"
          :class="{ 'border-danger': errors.has(selectName) }"
          :fetch-function="fetchFunction"
          :filter-by="localFilterBy"
          :filters-match="filtersMatch"
          :filter-params="filterParams"
          :search-filters="searchFilters"
          :use-default-search-filters="useDefaultSearchFilters"
          :multiple="multiple"
          :taggable="taggable"
          :select-on-tab="selectOnTab"
          :close-on-select="closeOnSelect"
          :placeholder="placeholder"
          :manage-route-name="manageRouteName"
          :manage-text="manageText"
          :pagination-page-size="paginationPageSize"
          :permission-to-manage="permissionToManage"
          :option-emphasis="optionEmphasis"
          :selectable="localSelectable"
          :create-option="localCreateOption"
          :create-text="createText"
          :show-create="showCreate"
          :search-on-key-up="true"
          @create="onSelectCreate"
          @input="onSelectInput()"
          @blur="onSelectBlur()">
          <template v-slot:option="{ item }">
            <slot name="option" :item="item"></slot>
          </template>
          <template v-slot:selected-option="{ item }">
            <slot name="selected-option" :item="item"></slot>
          </template>

          <template
            v-if="taggable"
            v-slot:no-options="{ search }">
            <div>{{ $t('$General.NotFound') }}</div>
            <template v-if="localSelectable(search)">
              <div>
                <a
                  href="#"
                  class="link-plain"
                  @click.prevent="selectPhoneNumber(search)">
                  {{ $t('$General.Select') }}
                  <span class="font-bold italic">{{ search }}</span>
                </a>
              </div>
            </template>
          </template>
        </v-select-server>

        <div>
          <a
            v-if="createNewLinkText"
            href="#"
            class="link-plain"
            @click.prevent="activeModalCreateOrEdit=true">
            {{ createNewLinkText }}
          </a>
        </div>
      </div>
    </div>

    <div>
      <vs-input
        :value="selectValidationInputValue"
        type="hidden"
        :name="selectName"
        class="w-full"
        v-validate="'required'"
        data-vv-validate-on="blur|input|change"
        :danger="errors.has(selectName)"
        :danger-text="errors.first(selectName)"
      />
    </div>

    <vs-popup
      :title="createModalTitle
        || $t('$Modals.CreateModalTitle', { entity: createNewModalEntity }) | sentencecase"
      :active.sync="activeModalCreateOrEdit">
      <transition name="zoom-fade">
        <div v-if="activeModalCreateOrEdit">
          <slot
            name="create"
            :nameToCreate="nameToCreate">
          </slot>
        </div>
      </transition>
    </vs-popup>
  </div>
</template>

<script>
import VueCountryCode from 'vue-country-code';
import { parsePhoneNumber } from 'libphonenumber-js';

// components
import VSelectServer from '@/views/modules/_components/v-select-server/VSelectServer.vue';

// mixins
import vSelectServerProps from '@/views/modules/_components/v-select-server/v-select-server-props.mixin';

/**
 * v-select server with option for enter phone numbers
 *
 * @module views/modules/components/v-select-server/VSelectServerPhoneNumber
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} selectName - name to identify the select and use as name in validations
 * @vue-prop {boolean} showLabel - indicate if show or no the label with selectName
 * @vue-prop {boolean} showCountryCode - indicate if show country code select or no
 * @vue-prop {string} createModalTitle - create modal title
 * @vue-prop {string} createNewLinkText - create new link text to show
 * @vue-prop {string} createNewModalEntity - create new modal entity name to use
 * @vue-prop {string} createOptionObjectName - name to use in object option creation
 * @vue-data {object | string} selection - local select selection
 * @vue-data {number} vSelectServerKey - select component key
 * @vue-data {string} defaultCountry - default country selected to enter phone numbers
 * @vue-data {boolean} activeModalCreateOrEdit - indicate if show or no the creation modal
 * @vue-data {string} nameToCreate - name to use in items creations in create modal
 * @vue-computed {string} selectValidationInputValue - select validation input value
 * @vue-event  {void} onSelectCreate - called on select create for create item
 * @vue-event  {void} onSelectInput - called on select input for validation
 * @vue-event  {void} onSelectBlur - called on select blur for validation
 * @vue-event  {void} validateSelect - validates the select
 * @vue-event  {void} selectedDefaultCountry - called on default country changed to set
 * @vue-event  {boolean} localSelectable - define selectable options in the select
 * @vue-event  {boolean} localFilterBy - define if show or no one option
 * @vue-event  {object | string} localCreateOption - return the new created options for select
 * @vue-event  {void} closeCreateModal - close teh create modal
 * @vue-event  {void} ItemCreatedOnModal -
 * called on item created to close the modal an refresh the select
 * @vue-event  {void} resetSelectServer - refresh the select
 */
export default {
  name: 'VSelectServerPhoneNumber',
  components: {
    VSelectServer,
    VueCountryCode,
  },
  mixins: [vSelectServerProps],
  props: {
    selectName: {
      type: String,
      required: false,
      default: '',
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCountryCode: {
      type: Boolean,
      required: false,
      default: true,
    },
    createModalTitle: {
      type: String,
      required: false,
      default: '',
    },
    createNewLinkText: {
      type: String,
      required: false,
      default: '',
    },
    createNewModalEntity: {
      type: String,
      required: false,
      default: '',
    },
    createOptionObjectName: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selection: this.value,
      vSelectServerKey: 0,
      defaultCountry: 'AU',
      activeModalCreateOrEdit: false,
      nameToCreate: '',
    };
  },
  computed: {
    selectValidationInputValue() {
      if (this.multiple) {
        return Array.isArray(this.selection)
          ? this.selection.length || ''
          : '';
      }

      if (typeof this.selection === 'object' && this.selection) {
        return Object.values(this.selection).length || '';
      }

      return this.selection || '';
    },
  },
  watch: {
    value() {
      this.selection = this.value;
    },
    activeModalCreateOrEdit(val) {
      if (!val) {
        this.nameToCreate = '';
      }
    },
  },
  methods: {
    selectPhoneNumber(phoneNumber) {
      const phoneNumberOption = this.localCreateOption(phoneNumber);
      this.selection = [...this.selection, phoneNumberOption];
      this.$refs.vSelectServer.clearSearch();
      this.onSelectInput();
    },
    onSelectCreate(name = '') {
      this.nameToCreate = name;
      this.activeModalCreateOrEdit = true;
    },
    onSelectInput() {
      this.$emit('input', this.selection);
      this.validateSelect();
    },
    onSelectBlur() {
      this.$emit('blur', this.selection);
      this.validateSelect();
    },
    async validateSelect() {
      return new Promise((resolve) => {
        setTimeout(async () => {
          const validation = await this.$validator.validate(this.selectName);
          resolve(validation);
        }, 0);
      });
    },
    selectedDefaultCountry({ iso2 }) {
      this.defaultCountry = iso2;
    },
    localSelectable(option) {
      if (option && option.id) {
        return true;
      }

      const optionValue = typeof option === 'object'
        ? option[this.createOptionObjectName]
        : option;

      try {
        const phoneNumber = parsePhoneNumber(optionValue, this.defaultCountry);

        if (phoneNumber && phoneNumber.isValid()) {
          const options = this.$refs.vSelectServer.allOptions;
          const duplicated = options.some(
            (opt) => opt.phoneInternationalSignificant === phoneNumber.number,
          );

          return !duplicated;
        }

        return false;
      } catch (e) {
        return false;
      }
    },
    localFilterBy(option, label, search) {
      return (option.name && option.name.search(search) !== -1)
        || (option.phoneInternationalSignificant
          && option.phoneInternationalSignificant.search(search) !== -1);
    },
    localCreateOption(newOption) {
      try {
        const phoneNumber = parsePhoneNumber(newOption, this.defaultCountry);

        if (phoneNumber && phoneNumber.isValid()) {
          // eslint-disable-next-line no-param-reassign
          return this.createOptionObjectName
            ? { [this.createOptionObjectName]: phoneNumber.formatInternational() }
            : phoneNumber.formatInternational();
        }

        return this.createOptionObjectName
          ? { [this.createOptionObjectName]: newOption }
          : newOption;
      } catch (e) {
        return this.createOptionObjectName
          ? { [this.createOptionObjectName]: newOption }
          : newOption;
      }
    },
    closeCreateModal() {
      this.activeModalCreateOrEdit = false;
    },
    ItemCreatedOnModal() {
      this.activeModalCreateOrEdit = false;
      this.vSelectServerKey += 1;
    },
    resetSelectServer() {
      this.vSelectServerKey += 1;
    },
  },
};
</script>

<style lang="scss">
.v-select-server-phone-number {
  .vs__dropdown-option--disabled {
    display: none;
  }

  .vs__dropdown-menu {
    z-index: 1001;
  }

  .county-select-box {
    > .vs-input--label {
      display: none;
    }

    .vue-country-select {
      border-color: rgba(0, 0, 0, 0.2);

      .dropdown {
        padding: 0.7em;
      }
      .dropdown-list {
        background-color: rgba(var(--vs-white), 1);
        z-index: 10 !important;
      }
    }
  }

  .phone-input-box {
    .vs__dropdown-menu {
      li:hover strong, li:hover i {
        color: #ffffff !important;
      }
    }
  }

  .phone-input-box {
    .vs__dropdown-menu {
      .vs__dropdown-option--highlight strong, .vs__dropdown-option--highlight i {
        color: #ffffff !important;
      }
    }
  }

  @media (max-width: 450px) {
    .county-select-box {
      flex-wrap: wrap;
      > .vs-input--label {
        display: block;
        width: 100%;
      }
      .w-full {
        margin-top: 0px;
      }
      .w-full .vs-input--label {
        display: none;
      }
      .w-full {
        margin-top: 4px;
      }

      .county-select-list {
        width: 100%;
        margin-right: 0px !important;
        .vue-country-select {
          border-right: 1px rgba(0, 0, 0, 0.2) solid !important;
        }
      }

      .phone-input-box {
        width: 100% !important;
        margin-top: -2px;

        .v-select {
          .vs__dropdown-toggle {
            border-left: 1px rgba(60, 60, 60, 0.26) solid !important;
            border-radius: 4px 4px 4px 4px;
            .vs__search {
              padding-left: 8px;
              padding-right: 8px;
            }
          }
        }

      .v-select.border-danger {
        .vs__dropdown-toggle {
            border-left: 1px rgba(var(--vs-danger), 1) solid !important;
          }
        }
      }

      .vue-country-select {
        width: 100%;
        border-right: 1px;
        border-radius: 4px 4px 4px 4px;
        margin-top: -8px;

        .dropdown-list {
          min-width: 270px;
          max-width: 100%;

          .dropdown-item:not(.dropdown-item:nth-child(1)) {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding: 8px;
          }
        }
      }
    }
  }

  @media (max-width: 380px) {
    .county-select-box {
      .vue-country-select {
        .dropdown-list {
          min-width: 250px;
          max-width: 260px;
        }
      }
    }
  }

  @media (max-width: 320px) {
    .county-select-box {
      .vue-country-select {
        .dropdown-list {
          min-width: 220px;
          max-width: 240px;
        }
      }
    }
  }

  @media (max-width: 294px) {
    .county-select-box {
      .county-select-list {
        margin-top: -34px;
      }

      .vue-country-select {
        .dropdown-list {
          min-width: 200px;
          max-width: 220px;
        }
      }
    }
  }
}
</style>
