import moment from 'moment';

export default {
  watch: {
    'model.messageType': async function (newVal) {
      this.verifyRestrictionSchedulesSendCampaign(newVal, this.model.localStartDate);
    },
  },
  methods: {
    handleCloseFlatPicker() {
      this.verifyRestrictionSchedulesSendCampaign(
        this.model.messageType,
        this.model.localStartDate,
      );
    },
    verifyRestrictionSchedulesSendCampaign(messageType, localStartDate) {
      if (messageType === this.$enums.Campaign.MessageType.MARKETING) {
        const weekDayName = moment(localStartDate).format('dddd');
        let hourDate = null;
        let minuteDate = null;
        let restrictionWeekDays = false;
        let restrictionSaturdayDays = false;

        if (localStartDate) {
          hourDate = moment(localStartDate).hour();
          minuteDate = moment(localStartDate).minute();
        }

        if (localStartDate && hourDate && minuteDate) {
          if (weekDayName !== 'Sunday' && weekDayName !== 'Saturday') {
            if (hourDate < 9 || hourDate > 20) {
              restrictionWeekDays = true;
            } else if (hourDate === 20 && minuteDate > 0) {
              restrictionWeekDays = true;
            }
          } else if (weekDayName === 'Saturday') {
            if (hourDate < 9 || hourDate > 17) {
              restrictionSaturdayDays = true;
            } else if (hourDate === 17 && minuteDate > 0) {
              restrictionWeekDays = true;
            }
          }

          if (weekDayName === 'Sunday' || restrictionWeekDays || restrictionSaturdayDays) {
            this.$showAlertWarningDialog({
              title: this.$t('$SMSCampaignModule.WeekDayRestrictionTimeAlert.Title'),
              text: this.$t('$SMSCampaignModule.WeekDayRestrictionTimeAlert.Text'),
            });
          }
        }
      }
    },

  },

};
