<template>
  <vs-popup
    :title="this.checkoutModalTitle"
    :active.sync="showCheckout"
    v-modal-no-scroll-body>
    <transition name="zoom-fade">
      <template v-if="showCheckout">
        <div v-if="getTenantFlag('completedAccountDetails')">
          <label>
            {{ $t('$Components.$PackageCheckoutModal.SelectPrepaidPackageLabel') }}
          </label>
          <v-select
            class="w-full"
            v-model="checkoutAppServicePackage"
            label="price"
            :multiple="false"
            :closeOnSelect="true"
            :options="appSettingsPackages"
            :clearable="false">
            <template #option="{price}">
              <strong>
                {{ $t(`$Components.$PackageCheckoutModal.${selectOptionText}`, {
                price: $options.filters.dollar(price),
              }) }}
              </strong>
            </template>
            <template #selected-option="{price}">
              <strong>
                {{ $t(`$Components.$PackageCheckoutModal.${selectOptionText}`, {
                price: $options.filters.dollar(price),
              }) }}
              </strong>
            </template>
          </v-select>

          <vs-divider></vs-divider>

          <transition name="zoom-fade">
            <app-checkout
              v-if="appSettings && checkoutAppServicePackage"
              :product-name="checkoutAppServicePackageName"
              :product-price="checkoutAppServicePackagePrice"
              :gst="appSettingsGst"
              :payment-methods="paymentMethods"
              :selected-payment-method.sync="selectedPaymentMethod"
              :recurrent="payPeriod === $enums.StripeEnums.Checkout.Mode.SUBSCRIPTION"
              :payment-failed-msg="paymentFailedMsg"
              @payment-intent="purchasePackage"
              @input-payment-method="paymentFailedMsg=''"/>
          </transition>
        </div>
        <div v-else>
          <user-account-settings-details on-modal>
            <template v-slot:title>
              <h4 class="text-center mb-5">
                {{ $t('$Components.$PackageCheckoutModal.CompleteYourAccountDetailsToPay') }}
              </h4>
            </template>

            <template v-slot:buttons-wrapper="{ save }">
              <div class="flex items-center justify-end mt-12">
                <vs-button
                  @click="save()">
                  {{ $t('$General.GoToPayment') }}
                </vs-button>
              </div>
            </template>
          </user-account-settings-details>
        </div>
      </template>
    </transition>
  </vs-popup>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import enums from '@/enums';
import purchasePackage from '@/views/modules/_mixins/purchasePackage.mixin';
import AppCheckout from '@/views/modules/_components/app-checkout/AppCheckout.vue';
import UserAccountSettingsDetails from '@/views/modules/user/user-account/user-account-settings/UserAccountSettingsDetails.vue';

/**
 * Modal of checkout to purchase prepaid packages
 *
 * @module views/modules/components/PackageCheckoutModal
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} active - indicate if show or no the modal
 * @vue-prop {string} payPeriod - pay period of package to purchase
 * @vue-data {Object} [checkoutAppServicePackage=null] - app service package to checkout
 * @vue-data {boolean} showCheckout - indicate if show or no the checkout
 * @vue-data {string} selectedPayPeriod - selected pay period of package to purchase
 * @vue-event {void} fetchData - fetch component data
 */
export default {
  name: 'PackageCheckoutModal',
  components: {
    AppCheckout,
    vSelect,
    UserAccountSettingsDetails,
  },
  mixins: [purchasePackage],
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    payPeriod: {
      type: String,
      required: false,
      default: enums.StripeEnums.Checkout.Mode.PAYMENT,
      validator(val) {
        return [
          enums.StripeEnums.Checkout.Mode.PAYMENT,
          enums.StripeEnums.Checkout.Mode.SUBSCRIPTION,
        ].indexOf(val) !== -1;
      },
    },
  },
  data() {
    return {
      checkoutAppServicePackage: null,
      showCheckout: this.active,
      selectedPayPeriod: this.payPeriod,
    };
  },
  computed: {
    ...mapGetters({
      getTenantFlag: 'auth/getTenantFlag',
    }),
    selectOptionText() {
      return this.selectedPayPeriod === this.$enums.StripeEnums.Checkout.Mode.PAYMENT
        ? 'PrepaidPackageOptionText'
        : 'MonthlyPackageOptionText';
    },
  },
  watch: {
    checkoutAppServicePackage(val) {
      const index = this.appSettingsPackages.findIndex(
        // eslint-disable-next-line no-underscore-dangle
        (p) => p._id === val._id,
      );

      this.checkoutAppServicePackageNumber = index !== -1 ? index : null;
    },
    active(val) {
      this.showCheckout = val;
      this.paymentFailedMsg = '';
    },
    showCheckout(val) {
      this.$emit('update:active', val);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.getAppSettings();
      await this.getPaymentMethods();

      if (this.appSettingsPackages.length > 0) {
        [this.checkoutAppServicePackage] = this.appSettingsPackages;
      }
    },
  },
};
</script>
