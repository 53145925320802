import BaseConstructor from '@/views/modules/_common/base.constructor';
import enums from '@/enums';
import { isEmpty as _isEmpty } from 'underscore';

/**
 * Create a new Campaign
 * @class CampaignConstructor
 */

export default class CampaignConstructor extends BaseConstructor {
  name = '';

  messageType = '';

  step = 0;

  deliveryType = enums.Campaign.DeliveryType.IMMEDIATELY;

  timeZone = '';

  filters = {};

  filtersMatch = enums.AppFilterMathType.ALL;

  quickContacts = [];

  segment = null;

  allContacts = false;

  status = enums.Campaign.Status.RUNNING;

  localStartDate = '';

  senderId = '';

  isQuick = false;

  isDraft = false;

  constructor(
    operation = enums.Operation.CREATE,
    payload = null,
    isDraft = false,
    isQuick = false,
    messageType = null,
    filters = {},
    filtersMatch = enums.AppFilterMathType.ALL,
    segment = null,
  ) {
    super(operation, payload);

    this.name = operation === enums.Operation.EDIT || operation === enums.Operation.CLONE || isDraft
      ? payload.name || ''
      : '';
    this.messageType = operation === enums.Operation.EDIT || operation === enums.Operation.CLONE
    || isDraft
      ? payload.messageType || ''
      : messageType || '';
    // this.step = operation === enums.Operation.EDIT || isDraft ? payload.step || 0 : 0;
    this.deliveryType = operation === enums.Operation.EDIT || operation === enums.Operation.CLONE
    || isDraft
      ? payload.deliveryType || enums.Campaign.DeliveryType.IMMEDIATELY
      : enums.Campaign.DeliveryType.IMMEDIATELY;
    this.timeZone = operation === enums.Operation.EDIT || operation === enums.Operation.CLONE
    || isDraft
      ? payload.timeZone || ''
      : '';

    if (isDraft || operation === enums.Operation.EDIT || operation === enums.Operation.CLONE) {
      this.segment = segment || payload.segment || null;
      this.filters = _isEmpty(filters)
        ? payload.filters || filters || {}
        : filters || payload.filters || {};
      this.filtersMatch = filtersMatch || payload.filtersMatch || enums.AppFilterMathType.ALL;
    } else {
      this.segment = segment || null;
      this.filters = !this.segment
        ? filters || {}
        : {};
      this.filtersMatch = filtersMatch || enums.AppFilterMathType.ALL;
    }

    this.allContacts = operation === enums.Operation.EDIT || operation === enums.Operation.CLONE
    || isDraft
      ? payload.allContacts || false : false;

    this.quickContacts = operation === enums.Operation.EDIT || operation === enums.Operation.CLONE
    || isDraft
      ? payload.quickContacts || [] : [];
    this.status = operation === enums.Operation.EDIT || operation === enums.Operation.CLONE
    || isDraft
      ? payload.status || enums.Campaign.Status.RUNNING
      : enums.Campaign.Status.RUNNING;
    this.localStartDate = operation === enums.Operation.EDIT || operation === enums.Operation.CLONE
    || isDraft
      ? payload.localStartDate || '' : '';
    this.senderId = operation === enums.Operation.EDIT || operation === enums.Operation.CLONE
    || isDraft
      ? payload.senderId || '' : '';
    this.isQuick = isQuick;
    this.isDraft = isDraft;

    if (isDraft && !this.id && payload && payload.id) {
      this.id = this.payload.id;
    }
  }

  toCreatePayload() {
    const payload = {
      name: this.name,
      messageType: this.messageType,
      step: this.step,
      filters: this.segment ? this.filters || this.segment.filters : this.filters,
      filtersMatch: this.segment
        ? this.filtersMatch || this.segment.filtersMatch
        : this.filtersMatch,
      segment: this.segment ? this.segment.id || null : null,
      allContacts: this.allContacts || false,
      deliveryType: this.deliveryType,
      timeZone: this.timeZone,
      status: this.status,
      localStartDate: this.localStartDate || '',
      senderId: this.senderId.id || this.senderId || null,
      quickContacts: this.quickContacts.map((d) => (d.id ? d.id : d.name)),
      isQuick: this.isQuick,
    };

    if (payload.segment && payload.segment === enums.DEFAULT_ALL_CONTACTS_SEGMENT_ID) {
      payload.segment = null;
      payload.allContacts = true;
    }

    return payload;
  }

  toEditPayload() {
    return {
      id: this.payload.id,
      ...this.toCreatePayload(),
    };
  }

  toTestPayload() {
    return {
      filters: this.filters,
      filtersMatch: this.filtersMatch,
      senderId: this.senderId.id || this.senderId || null,
    };
  }
}
