import enums from '@/enums';
import CampaignConstructor from '@/views/modules/campaigns/common/campaign.constructor';

/**
 * Create a new sms campaign
 * @class SmsCampaignConstructor
 */
export default class SmsCampaignConstructor extends CampaignConstructor {
  message = '';

  hasInterpolations = false;

  interpolations = [];

  callerIdType = enums.Sender.Caller.Type.SHARED_NUMBER;

  senderBusinessName = '';

  constructor(
    operation = enums.Operation.CREATE,
    payload = null,
    isDraft = false,
    isQuick = false,
    messageType = null,
    filters = {},
    filtersMatch = enums.AppFilterMathType.ALL,
    segment = null,
  ) {
    super(operation, payload, isDraft, isQuick, messageType, filters, filtersMatch, segment);

    this.message = operation === enums.Operation.EDIT
    || operation === enums.Operation.CLONE || isDraft ? payload.message || '' : '';
    this.hasInterpolations = operation === enums.Operation.EDIT
    || operation === enums.Operation.CLONE || isDraft
      ? payload.hasInterpolations || false : false;
    this.interpolations = operation === enums.Operation.EDIT
    || operation === enums.Operation.CLONE || isDraft
      ? payload.interpolations || [] : [];
    this.callerIdType = operation === enums.Operation.EDIT
    || operation === enums.Operation.CLONE || isDraft
      ? payload.callerIdType || enums.Sender.Caller.Type.SHARED_NUMBER
      : enums.Sender.Caller.Type.SHARED_NUMBER;
    this.senderBusinessName = operation === enums.Operation.EDIT
    || operation === enums.Operation.CLONE || isDraft ? payload.senderBusinessName || '' : '';
  }

  toCreatePayload() {
    return {
      message: this.message,
      callerIdType: this.callerIdType,
      senderBusinessName: this.senderBusinessName || '',
      hasInterpolations: this.hasInterpolations,
      interpolations: this.interpolations,
      ...super.toCreatePayload(),
    };
  }

  toEditPayload() {
    return {
      id: this.payload.id,
      ...this.toCreatePayload(),
    };
  }

  toDraftPayload() {
    return {
      id: this.id,
      ...this.toCreatePayload(),
    };
  }

  toTestPayload() {
    return {
      ...super.toTestPayload(),
      message: this.message,
      messageType: this.messageType,
      hasInterpolations: this.hasInterpolations,
      interpolations: this.interpolations,
      callerIdType: this.callerIdType,
      senderBusinessName: this.senderBusinessName || '',
    };
  }
}
