<template>
  <span :title="name">
    {{ `${name || ''}` | truncateEllipsis(70) }}
    <span v-if="phoneInternational"> ({{ phoneInternational }})</span>
    <span v-if="status">
       -
      <span :class="`text-${textColor}`">
        <strong>{{ $t(`$Enums.Sender.Caller.DedicatedNumberStatus.${status}`) }}</strong>
        <i class="ml-1" :class="['feather', icon]"></i>
      </span>
    </span>
  </span>
</template>

<script>
/**
 * Component to show options for dedicated number in custom v-select
 *
 * @module views/modules/campaigns/components/campaign-information/CampaignInformationVSelectDedicatedNumberOption
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {Object} option - option to show
 * @vue-computed {string} name - option name
 * @vue-computed {string} status - option status
 * @vue-computed {string} phoneInternational - option phone international
 * @vue-computed {string} textColor - text color to show option status
 * @vue-computed {string} icon - icon =to show in the option status
 */
export default {
  name: 'CampaignInformationVSelectDedicatedNumberOption',
  props: {
    option: {
      type: Object,
      require: true,
    },
  },
  computed: {
    name() {
      return this.option ? this.option.name || '' : '';
    },
    status() {
      return this.option ? this.option.status || '' : '';
    },
    phoneInternational() {
      return this.option ? this.option.phoneInternational || '' : '';
    },
    textColor() {
      switch (this.status) {
        case this.$enums.Sender.Caller.DedicatedNumberStatus.PROCESSING_PAYMENT:
          return 'primary';
        case this.$enums.Sender.Caller.DedicatedNumberStatus.ACTIVE:
          return 'success';
        case this.$enums.Sender.Caller.DedicatedNumberStatus.MISSING_PAYMENT:
          return 'warning';
        case this.$enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT:
          return 'danger';
        default:
          return 'normal';
      }
    },
    icon() {
      switch (this.status) {
        case this.$enums.Sender.Caller.DedicatedNumberStatus.PROCESSING_PAYMENT:
          return 'icon-clock';
        case this.$enums.Sender.Caller.DedicatedNumberStatus.ACTIVE:
          return 'icon-check';
        case this.$enums.Sender.Caller.DedicatedNumberStatus.MISSING_PAYMENT:
          return 'icon-x';
        case this.$enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT:
          return 'icon-x';
        default:
          return '';
      }
    },
  },
};
</script>
