<template>
  <div>
    <vue-element-loading
      :active="fetchingNumbers"
      color="rgba(var(--vs-primary), 1)"
      spinner="mini-spinner"/>

    <vs-table
      max-items="5"
      pagination
      stripe
      :no-data-text="$t('NoDataText')"
      :data="availableNumbers">
      <template slot="header">
        <slot name="header"></slot>
      </template>
      <template slot="thead">
        <vs-th>{{ $tc('$General.PhoneNumber') }}</vs-th>
        <vs-th>{{ $tc('$General.Price') }}</vs-th>
        <vs-th>{{ $tc('$General.Action', 2) }}</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr]">
            {{ data[indextr] | phone }}
          </vs-td>

          <vs-td>
            {{ price | dollar }}
          </vs-td>

          <vs-td :data="data[indextr]">
            <vs-button
              size="small"
              @click="$emit('buy', data[indextr])">
              {{ $t('$General.Buy') }}
            </vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import callerIdService from '@/api/modules/caller-id.service';

/**
 * Available dedicated numbers list to buy
 *
 * @module views/modules/sender-ids/caller-id/CallerIdListCreateOrEditFromDedicatedNumberList
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {number} price - price to show in numbers
 * @vue-prop {string[]} dedicatedNumbers - dedicated numbers info
 * @vue-prop {string[]} availableNumbers - dedicated numbers info
 * @vue-prop {boolean} fetchingNumbers - indicate if number are fetching from API
 * @vue-event {void} getAvailableDedicatedNumbers - fetch the available dedicated numbers
 */
export default {
  name: 'CallerIdListCreateOrEditFromDedicatedNumberList',
  i18n: {
    messages: {
      en: {
        NoDataText: 'No dedicated numbers available at this time',
      },
    },
  },
  props: {
    price: {
      type: Number,
      required: false,
      default: 0,
    },
    dedicatedNumbers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      availableNumbers: [],
      fetchingNumbers: false,
    };
  },
  created() {
    if (this.dedicatedNumbers.length === 0) {
      this.getAvailableDedicatedNumbers();
    } else {
      this.availableNumbers = this.dedicatedNumbers;
    }
  },
  methods: {
    async getAvailableDedicatedNumbers() {
      this.fetchingNumbers = true;
      const resp = await callerIdService.getAvailableDedicatedNumbers();
      this.availableNumbers = resp.data;
      this.fetchingNumbers = false;
    },
  },
};
</script>

<style scoped>

</style>
