import { mapActions, mapMutations } from 'vuex';

// components
import { FormWizard, TabContent } from 'vue-form-wizard';

// mixins
import singleCreateOrEdit from '@/views/modules/_mixins/singleCreateOrEdit';

// custom components
import CampaignInformation from '@/views/modules/campaigns/components/campaign-information/CampaignInformation.vue';
import CampaignContacts from '@/views/modules/campaigns/components/campaign-contacts/CampaignContacts.vue';
import CampaignSettings from '@/views/modules/campaigns/components/CampaignDeliveryType.vue';
import CampaignConfirm from '@/views/modules/campaigns/components/campaign-confirm/CampaignConfirm.vue';
import PackageCheckoutModal from '@/views/modules/_components/PackageCheckoutModal.vue';
import CampaignWizardFooterButtons from '@/views/modules/campaigns/components/CampaignWizardFooterButtons.vue';

/**
 * This provides props used for campaign steps
 * @mixin views/modules/mixins/campaigns/commonCampaigns
 *
 * @vue-data {Object} model - campaign model to save
 * @vue-data {boolean} loadedCampaign - indicate if campaign is loaded
 * @vue-data {boolean} loadContacts - indicate if contacts is loaded
 * @vue-data {boolean} loadSettings - indicate if settings is loaded
 * @vue-data {boolean} loadConfirm - indicate if confirm is loaded
 * @vue-computed {boolean} verifyProperties - indicate if form is valid to save
 * @vue-computed {number} startIndex - start index of wizard
 * @vue-event {void} created - created hook to init campaign
 * @vue-event {void} beforeDestroy - beforeDestroy hook to save campaign as draft
 * @vue-event {void} initCampaign - init and set campaign on init
 * @vue-event {void} initCampaignModelFunction - init the model of campaign
 * @vue-event {void} additionalInitCampaignFunction - additional init campaign
 * @vue-event {void} loadResourcesFromCampaign - set loaded variables
 * @vue-event {void} additionalValidateCampaignInfoFunction -
 * additional validate to campaign info step
 * @vue-event {void} validateCampaignInfo - validate to campaign info step
 * @vue-event {void} campaignInfoIsValid - called after validate campaign info step
 * @vue-event {void} campaignContactsIsValid - called after validate select contact step
 * @vue-event {void} validateCampaignSettings - validate campaign settings step
 * @vue-event {void} campaignSettingsIsValid - called after validate campaign settings step
 * @vue-event {void} saveCampaignAsDraft - save campaign as draft
 * @vue-event {void} restartCampaign - restart campaign
 * @vue-event {void} confirmTestCampaign - called to confirm test campaign
 * @vue-event {void} sendTestCampaign - called to send test campaign
 * @vue-event {void} confirmSendCampaign - called to confirm send campaign
 * @vue-event {void} saveCampaign - send and save campaign
 */
export default {
  components: {
    FormWizard,
    TabContent,
    CampaignContacts,
    CampaignSettings,
    CampaignConfirm,
    CampaignInformation,
    PackageCheckoutModal,
    CampaignWizardFooterButtons,
  },
  mixins: [singleCreateOrEdit],
  data() {
    return {
      model: null,
      loadedCampaign: false,
      loadMessage: false,
      loadContacts: false,
      loadSettings: false,
      loadConfirm: false,
      showCheckout: false,
      costInfo: null,
      campaignBaseRoute: '',
      campaignType: '',
      campaignInfoKey: 0,

      initCampaignModelFunction: null,
      additionalInitCampaignFunction: () => {},
      setCampaignPayloadFunction: () => {},
      additionalValidateCampaignInfoFunction: async () => true,
    };
  },
  computed: {
    verifyProperties() {
      return this.model.name !== '';
    },
    startIndex() {
      return this.loadedCampaign
      && (this.model.status === this.$enums.Campaign.Status.DRAFT || this.isEdition)
        ? this.model.step : 0;
    },
  },
  created() {
    this.initCampaign();
  },
  beforeDestroy() {
    this.setCampaignPayloadFunction(null);
  },
  methods: {
    ...mapActions({
      saveDraftCampaign: 'user/saveDraftCampaign',
      discardDraftCampaign: 'user/discardDraftCampaign',
    }),
    ...mapMutations({
      deleteCampaignDraftAuthUser: 'auth/DELETE_CAMPAIGN_DRAFT',
    }),
    async initCampaign() {
      const isDraft = (!this.modelPayload && !!this.draftCampaign)
        || (!!this.modelPayload && !!this.modelPayload.id
          && this.modelPayload.status === this.$enums.Campaign.Status.DRAFT
          && this.operation === this.$enums.Operation.EDIT);
      const payload = !this.modelPayload && !!this.draftCampaign
        ? this.draftCampaign : this.modelPayload;

      if ((this.operation === this.$enums.Operation.EDIT
        || this.operation === this.$enums.Operation.CLONE)
        && !this.modelPayload && this.campaignBaseRoute) {
        await this.$router.push(`${this.campaignBaseRoute}/create`);
      }

      if (this.initCampaignModelFunction) {
        await this.initCampaignModelFunction({ isDraft, payload });
      } else {
        throw new Error('initCampaignModelFunction must be defined');
      }

      if (this.isEdition || this.isCloning || this.draftCampaign) {
        this.additionalInitCampaignFunction();
        this.loadedCampaign = true;
        this.loadResourcesFromCampaign();
      } else {
        this.loadedCampaign = true;
      }
    },
    loadResourcesFromCampaign() {
      this.loadMessage = this.model.step > 0;
      this.loadContacts = this.model.step > 1;
      this.loadSettings = this.model.step > 2;
      this.loadConfirm = this.model.step > 3;
    },
    async validateCampaignInfo() {
      const stepInfoValid = await this.$refs.campaignInfo.validate();
      const AdditionalStepInfoValid = await this.additionalValidateCampaignInfoFunction();

      if (stepInfoValid && AdditionalStepInfoValid) {
        await this.continueCampaignStep();
        this.campaignInfoIsValid();
        return true;
      }

      return false;
    },
    campaignInfoIsValid() {
      this.loadMessage = true;
    },
    async validateCampaignMessage() {
      await this.continueCampaignStep();
      this.campaignMessageIsValid();
      return true;
    },
    campaignMessageIsValid() {
      this.loadContacts = true;
    },
    campaignContactsIsValid() {
      this.loadSettings = true;
    },
    async validateCampaignSettings() {
      const result = await this.$refs.campaignSettings.validate();

      if (result) {
        await this.continueCampaignStep();
        await this.campaignSettingsIsValid();
        return true;
      }

      return false;
    },
    async campaignSettingsIsValid() {
      this.loadConfirm = true;
    },
    async continueCampaignStep() {
      if (this.model.id) {
        if (this.model.status !== this.$enums.Campaign.Status.PENDING
          && this.model.status !== this.$enums.Campaign.Status.CANCELED) {
          await this.saveCampaignAsDraft();
        }
      } else {
        await this.saveCampaignAsDraft();
      }
    },
    async confirmSaveAndQuitCampaign() {
      this.$showConfirmWarningDialog({
        title: this.$t('$CampaignsModules.ConfirmSaveAndQuitCampaignTitle'),
        text: this.$t('$CampaignsModules.ConfirmSaveAndQuitCampaignMsg'),
        accept: this.saveAndQuitCampaign,
      });
    },
    async saveAndQuitCampaign() {
      await this.saveCampaignAsDraft(true);
      await this.deleteCampaignDraftAuthUser();
      await this.restartCampaign(false);

      this.$showSuccessActionNotification({
        title: this.$t('$CampaignsModules.ConfirmSaveAndQuitCampaignNtfTitle'),
        text: this.$t('$CampaignsModules.ConfirmSaveAndQuitCampaignNtfMsg'),
      });
    },
    async saveCampaignAsDraft(hardDraft = false) {
      this.model.status = this.$enums.Campaign.Status.DRAFT;
      const payload = this.model.toDraftPayload();

      const resp = await this.saveDraftCampaign({
        campaignDraft: payload,
        hardDraft,
      });

      this.model.id = resp ? resp.id || null : null;
    },
    returnToStep(step) {
      this.$refs.wizard.changeTab(this.model.step, step);
    },
    async confirmRestartCampaign() {
      let restartType = 'StartOver';

      if (this.model.isDraft) {
        restartType = 'DiscardDraft';
      }

      if (this.operation === this.$enums.Operation.EDIT
        && this.model.deliveryType === this.$enums.Campaign.DeliveryType.LATER) {
        restartType = 'CancelScheduled';
      }

      this.$showConfirmWarningDialog({
        title: this.$t(`$CampaignsModules.Confirm${restartType}CampaignTitle`),
        text: this.$t(`$CampaignsModules.Confirm${restartType}CampaignMsg`),
        accept: () => this.restartCampaign(this.model.isDraft),
      });
    },
    async restartCampaign(discardDraft = true) {
      if (discardDraft) {
        await this.discardDraftCampaign({
          campaignId: this.model.id,
        });

        this.$showSuccessActionNotification({
          title: this.$t('$CampaignsModules.ConfirmDiscardDraftCampaignNtfTitle'),
          text: this.$t('$CampaignsModules.ConfirmDiscardDraftCampaignNtfMsg'),
        });
      }

      this.setCampaignPayloadFunction(null);

      if (this.isEdition || this.isCloning) {
        await this.$router.push(`${this.campaignBaseRoute}/create`);
      } else {
        this.loadedCampaign = false;
        this.loadMessage = false;
        this.loadContacts = false;
        this.loadSettings = false;
        this.loadConfirm = false;
        this.showCheckout = false;
        this.costInfo = null;

        this.campaignInfoKey += 1;
        await this.initCampaign();
        this.$refs.wizard.reset();
      }
    },
    confirmTestCampaign({ campaignType, senderId, cost }) {
      this.$showConfirmActionDialog({
        title: this.$t('$CampaignsModules.ConfirmTestCampaignTitle'),
        text: this.$t('$CampaignsModules.ConfirmTestCampaignMsg', {
          type: campaignType,
          senderId,
          cost: this.$options.filters.dollar(cost),
        }),
        accept: () => this.sendTestCampaign(senderId),
        acceptText: this.$t('$General.Send'),
      });
    },
    async sendTestCampaign(sender) {
      await this.testCampaign({
        ...this.model.toTestPayload(),
        sender,
      });

      this.$showSuccessActionNotification({
        title: this.$t('$CampaignsModules.TestCampaignSuccessNotifyTitle'),
        text: this.$t('$CampaignsModules.TestCampaignSuccessNotifyMsg'),
      });
    },
    confirmSendCampaign(contacts, cost) {
      if (!this.costInfo.userHasSufficientBalance) {
        this.$showConfirmWarningDialog({
          title: this.$t('$CampaignsModules.InSufficientBalanceToSendCampaignNtf.Title'),
          text: this.$t('$CampaignsModules.InSufficientBalanceToSendCampaignNtf.Text'),
          acceptText: this.$t('$CampaignsModules.InSufficientBalanceToSendCampaignNtf.AcceptText'),
          cancelText: this.$t('$CampaignsModules.InSufficientBalanceToSendCampaignNtf.CancelText'),
          accept: () => {
            this.showCheckout = true;
          },
          cancel: () => {
            this.returnToStep(2);
          },
        });
      } else {
        const deliveryText = this.model.deliveryType === this.$enums.Campaign.DeliveryType.LATER
          ? 'Schedule' : 'Send';

        this.$showConfirmActionDialog({
          title: this.$t(`$CampaignsModules.Confirm${deliveryText}CampaignTitle`),
          text: this.$t(`$CampaignsModules.Confirm${deliveryText}CampaignMsg`, {
            contacts,
            entity: this.$tc(`$Entities.${this.entity}`),
            cost: this.$options.filters.dollar(cost),
          }),
          accept: this.saveCampaign,
          acceptText: this.$t(`$General.${deliveryText}`),
        });
      }
    },
    async saveCampaign() {
      if (this.model.deliveryType === this.$enums.Campaign.DeliveryType.IMMEDIATELY) {
        this.model.status = this.$enums.Campaign.Status.RUNNING;
      } else {
        this.model.status = this.$enums.Campaign.Status.PENDING;
      }

      await this.save(this.model.toSavePayload());

      if (this.campaignBaseRoute) {
        const path = this.model.status === this.$enums.Campaign.Status.PENDING
          ? 'scheduled' : 'history';
        await this.$router.push(`${this.campaignBaseRoute}/${path}`);
      }
    },
  },
};
