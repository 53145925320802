<template>
  <phone-preview>
    <div class="text-center py-5 bg-grey-light">
      {{ senderIdLocal }}
    </div>
    <div
      v-show="messagePreviewHtml.length > 0
          || messageType === $enums.Campaign.MessageType.MARKETING"
      v-html="messagePreviewHtml"
      class="message-preview">
    </div>

    <div
      style="position: absolute; bottom: 0; border-top: 1px solid grey"
      class="flex justify-between items-center py-2 px-2">
      <div>
        <vue-tel-input
          v-model="testPhoneNumber"
          :class="{ 'border-danger': invalidPhoneNumber}"
          :disabledFetchingCountry="true"
          :dynamicPlaceholder="false"
          :enabledCountryCode="true"
          :validCharactersOnly="true"
          :preferredCountries="['AU', 'CO']"
          :defaultCountry="'AU'"
          @validate="(payload) => (this.testPhoneNumberValidate = payload)">
        </vue-tel-input>
      </div>

      <vs-button
        size="small"
        class="ml-2"
        :disabled="invalidPhoneNumber"
        @click="$emit('test', testPhoneNumberValidate.number.international)">
        {{ $t('$General.Test') }}
      </vs-button>
    </div>
  </phone-preview>
</template>

<script>
import PhonePreview from '@/views/modules/_components/PhonePreview.vue';
import { VueTelInput } from 'vue-tel-input';
import { mapActions, mapGetters } from 'vuex';
import enums from '@/enums';

/**
 * Component to view the preview of SMS
 *
 * @module views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEditMessagePreview
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} message - message of campaign
 * @vue-prop {string} messageType - message type of campaign
 * @vue-prop {Object[]} interpolations - message interpolations
 * @vue-prop {Object[]} attributesFromContacts - contacts attributes
 * @vue-prop {string} callerIdType - type of caller in the campaign
 * @vue-prop {Object | string} senderId - sender id of campaign
 * @vue-data {string} contactToInterpolate - contact to user in interpolations
 * @vue-data {string} optOutText - opt out text to use
 * @vue-data {string} testPhoneNumber - phone number to use in test
 * @vue-data {Object} testPhoneNumberValidate - phone number validations to use in test
 * @vue-computed {string} messageLocalInterpolated - message with interpolations
 * @vue-computed {string} messagePreviewHtml - message preview in html format
 * @vue-computed {string} optOutMessage - opt out message to use
 * @vue-computed {string} optOutUrl - opt out url to use
 * @vue-computed {boolean} invalidPhoneNumber -
 * indicate if phone number to use in test is valid or no
 * @vue-computed {string} senderIdLocal - sender id to show in phone preview
 * @vue-event {void} getInterpolationPlaceholder - get placeholder for interpolation
 * @vue-event {void} getInterpolationPlaceholderByDefaults -
 * get placeholder for interpolation by defaults
 * @vue-event {void} getInterpolationPlaceholderByType -
 * get placeholder for interpolation by type
 * @vue-event {void} getSpecialInterpolationPlaceholderByAttribute
 * - get placeholder for special interpolation by attribute
 * @vue-event {void} fetchContactToInterpolate - fetch the contact to use in interpolations
 */
export default {
  name: 'SMSCampaignListCreateOrEditMessagePreview',
  components: {
    VueTelInput,
    PhonePreview,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    messageType: {
      type: String,
      required: false,
      validator(type) {
        if (type === '') return true;
        return Object.values(enums.Campaign.MessageType).includes(type);
      },
    },
    interpolations: {
      type: Array,
      required: false,
      default() {
        return [];
      },
      validator(interpolations) {
        return interpolations.every((interpolation) => typeof interpolation === 'object'
          && interpolation !== null
          && 'shorthand' in interpolation
          && typeof interpolation.shorthand === 'string'
          && 'attribute' in interpolation
          && typeof interpolation.attribute === 'string');
      },
    },
    attributesFromContacts: {
      type: Array,
      required: true,
    },
    callerIdType: {
      type: String,
      required: true,
      validator(type) {
        return Object.values(enums.Sender.Caller.Type).includes(type);
      },
    },
    senderId: {
      type: [Object, String],
      required: true,
    },
    senderBusinessName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      contactToInterpolate: null,
      optOutText: '\nOpt-out',
      testPhoneNumber: '',
      testPhoneNumberValidate: null,
    };
  },
  computed: {
    ...mapGetters({
      shortReviewLink: 'auth/tenantShortReviewLink',
    }),
    messageLocalInterpolated() {
      let messageInterpolated = this.message;

      this.interpolations.forEach((interpolation) => {
        if (this.contactToInterpolate && this.contactToInterpolate[interpolation.attribute]
          && (interpolation.type !== this.$enums.Attributes.Type.CATEGORY
            || this.contactToInterpolate[interpolation.attribute].length > 0)
        ) {
          let placeholder = this.contactToInterpolate[interpolation.attribute];

          if (interpolation.type === this.$enums.Attributes.Type.CATEGORY) {
            placeholder = placeholder.map((item) => item.name).toString().replace(/,/g, ', ');
          }

          messageInterpolated = messageInterpolated.replace(
            interpolation.shorthand, placeholder,
          );
        } else {
          messageInterpolated = messageInterpolated.replace(
            interpolation.shorthand, this.getInterpolationPlaceholder(interpolation),
          );
        }
      });

      return messageInterpolated;
    },
    messagePreviewHtml() {
      const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gi;
      const message = this.messageLocalInterpolated + this.optOutMessage;
      const urls = message.match(urlRegex) || [];
      let messageHtml = message.replace('\n', '<br>');

      urls.forEach((value) => {
        messageHtml = messageHtml.replace(value, `
          <a
            href="${value}"
            target="_blank">
            ${value.replace(/https?:\/\/(www\.)?/gi, '')}
          </a>`);
      });

      return messageHtml;
    },
    optOutMessage() {
      if (this.messageType === this.$enums.Campaign.MessageType.MARKETING) {
        switch (this.callerIdType) {
          case this.$enums.Sender.Caller.Type.SHARED_NUMBER:
          case this.$enums.Sender.Caller.Type.DEDICATED_NUMBER:
            return `${this.optOutText} reply STOP`;

          default:
            return `${this.optOutText} ${this.optOutUrl}`;
        }
      }

      return '';
    },
    optOutUrl() {
      return this.contactToInterpolate && this.contactToInterpolate.optOutUrl
        ? this.contactToInterpolate.optOutUrl : 'https://g2.is/XXXX';
    },
    invalidPhoneNumber() {
      return this.testPhoneNumber
        && this.testPhoneNumberValidate
        && !this.testPhoneNumberValidate.isValid;
    },
    senderIdLocal() {
      switch (this.callerIdType) {
        case this.$enums.Sender.Caller.Type.SHARED_NUMBER:
          return this.$options.filters.sentencecase(this.$t('$General.SharedNumber'));
        case this.$enums.Sender.Caller.Type.PHONE_NUMBER:
          return this.senderId.phoneNational;
        case this.$enums.Sender.Caller.Type.BUSINESS_NAME:
          return this.senderBusinessName;

        default:
          return this.$options.filters.sentencecase(this.$t('$General.ReplyNumber'));
      }
    },
  },
  watch: {
    optOutMessage(val) {
      this.$emit('opt-out-message-length', val.length);
    },
  },
  created() {
    this.fetchContactToInterpolate();
  },
  methods: {
    ...mapActions({
      fetchAllContacts: 'contact/fetchAllContacts',
    }),
    getInterpolationPlaceholder(interpolation) {
      return interpolation.type === this.$enums.Attributes.Type.DEFAULT
        ? this.getInterpolationPlaceholderByDefaults(interpolation)
        : this.getInterpolationPlaceholderByType(interpolation);
    },
    getInterpolationPlaceholderByDefaults(interpolation) {
      switch (interpolation.attribute) {
        case 'name':
          return 'Jhon Doe';

        case 'firstName':
          return 'Jhon';

        case 'lastName':
          return 'Doe';

        case 'phoneSignificant':
          return '444 44 44';

        case 'email':
          return 'name@email.com';

        default:
          return interpolation.shorthand;
      }
    },
    getInterpolationPlaceholderByType(interpolation) {
      switch (interpolation.type) {
        case this.$enums.Attributes.Type.TEXT:
          return interpolation.name;

        case this.$enums.Attributes.Type.BOOL:
          return 'Yes/No';

        case this.$enums.Attributes.Type.DATE:
          return 'DD/MM/YYYY';

        case this.$enums.Attributes.Type.NUMBER:
          return ' 0000';

        case this.$enums.Attributes.Type.CATEGORY:
          // eslint-disable-next-line no-underscore-dangle,no-case-declarations
          const attr = this.attributesFromContacts.find((a) => a._id === interpolation.attribute);

          if (attr) {
            return attr.items.map((item) => item.name).toString().replace(/,/g, ', ');
          }
          return interpolation.shorthand;

        case this.$enums.Attributes.Type.SPECIAL:
          return this.getSpecialInterpolationPlaceholderByAttribute(interpolation);

        default:
          return interpolation.shorthand;
      }
    },
    getSpecialInterpolationPlaceholderByAttribute(interpolation) {
      switch (interpolation.attribute) {
        case this.$enums.Campaign.SpecialPlaceholders.REVIEW_LINK:

          if (this.shortReviewLink && !/^https?:\/\//gi.test(this.shortReviewLink)) {
            return `http://${this.shortReviewLink}`;
          }

          return this.shortReviewLink || interpolation.shorthand;

        default:
          return '';
      }
    },
    async fetchContactToInterpolate() {
      const contacts = await this.fetchAllContacts({
        limit: 1,
      });

      this.contactToInterpolate = contacts && contacts.data && Array.isArray(contacts.data)
        ? contacts.data[0] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-preview {
  background: rgba(var(--vs-grey-light), 0.5) !important;
  margin: 20px 20px 40px 30px;
  padding: 1rem;
  border-radius: 10px;
  line-break: anywhere;
}
</style>
