<template>
  <vs-popup
    :title="title"
    :active.sync="valueLocal"
    v-modal-no-scroll-body>
    <transition name="zoom-fade">
      <dedicated-number-payment-checkout
        v-show="valueLocal"
        :caller-id="callerId"
        :on-payment-intent-callback="onPaymentIntentCallback"
        @payment="onPayment"/>
    </transition>
  </vs-popup>
</template>

<script>
// Components
import DedicatedNumberPaymentCheckout from '@/views/modules/sender-ids/caller-id/_components/DedicatedNumberPaymentCheckout.vue';

/**
 * Dedicated number payment checkout modal
 *
 * @module views/modules/sender-ids/caller-id/_components/DedicatedNumberPaymentCheckoutModal
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} value - the value to show or not the modal
 * @vue-prop {Object} callerId - caller id to pay
 * @vue-prop {Function || null} onPaymentIntentCallback - on payment callback function
 * @vue-data {Object} valueLocal - the value local to show or not the modal
 * @vue-computed {string} callerIdStatus - status of caller id
 * @vue-computed {string} title - title of modal
 * @vue-event {void} onPayment - called after payment
 */
export default {
  name: 'DedicatedNumberMissingPaymentCheckoutModal',
  i18n: {
    messages: {
      en: {
        Pay: 'Dedicated number payment',
        Resubscribe: 'Resubscribe to dedicated number',
      },
    },
  },
  components: {
    DedicatedNumberPaymentCheckout,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    callerId: {
      type: Object,
      required: true,
    },
    onPaymentIntentCallback: {
      type: Function,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      valueLocal: this.value,
    };
  },
  computed: {
    callerIdStatus() {
      return this.callerId.status
        ? this.callerId.status
        : '';
    },
    isExpiredOrCanceled() {
      return this.callerIdStatus === this.$enums.Sender.Caller.DedicatedNumberStatus.EXPIRED_PAYMENT
        || this.callerIdStatus === this.$enums.Sender.Caller.DedicatedNumberStatus.CANCELED;
    },
    title() {
      // eslint-disable-next-line max-len
      if (this.isExpiredOrCanceled) {
        return this.$t('Resubscribe');
      }

      return this.$t('Pay');
    },
  },
  watch: {
    value(val) {
      this.valueLocal = val;
    },
    valueLocal(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    onPayment() {
      this.$emit('payment');
      this.valueLocal = false;
    },
  },
};
</script>

<style scoped>

</style>
