import { mapActions } from 'vuex';

// Services
import tenantService from '@/api/modules/tenant.service';

// Mixins
import purchaseMixin from '@/views/modules/_mixins/purchase.mixin';

/**
 * mixins for purchase packages
 *
 * @module views/modules/mixins/purchasePackage.mixin
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {number} [checkoutAppServicePackageNumber=null] - package number selected to checkout
 * @vue-data {string} selectedPayPeriod - selected paid period to purchase
 * @vue-data {Object} [checkoutAppServicePackage=null] - package selected to checkout
 * @vue-computed {Object[]} appSettingsPackages - packages from app settings
 * @vue-computed {string} checkoutAppServicePackageName -
 * product name for package to purchase in the checkout
 * @vue-computed {number} checkoutAppServicePackagePrice -
 * price of package to purchase in the checkout
 * @vue-computed {number} appSettingsMonthlyDiscountServices -
 * monthly discount services from app settings
 * @vue-computed {string} checkoutModalTitle - checkout modal title
 * @vue-event {void} purchasePackage - make payment intent for selected package
 */
export default {
  mixins: [purchaseMixin],
  data() {
    return {
      checkoutAppServicePackageNumber: null,
      selectedPayPeriod: this.$enums.StripeEnums.Checkout.Mode.PAYMENT,
      checkoutAppServicePackage: null,
      paymentFailedMsg: '',
    };
  },
  computed: {
    appSettingsPackages() {
      return this.appSettings && this.appSettings.packages
      && Array.isArray(this.appSettings.packages)
        ? this.appSettings.packages
        : [];
    },
    checkoutAppServicePackageName() {
      const price = this.checkoutAppServicePackage ? this.checkoutAppServicePackage.price : null;
      return price ? this.$options.filters.dollar(price) : '';
    },
    checkoutAppServicePackagePrice() {
      return this.checkoutAppServicePackage ? this.checkoutAppServicePackage.price : 0;
    },
    appSettingsMonthlyDiscountServices() {
      return this.appSettings ? this.appSettings.monthlyDiscountServices : 0;
    },
    checkoutModalTitle() {
      const locale = this.selectedPayPeriod === this.$enums.StripeEnums.Checkout.Mode.PAYMENT
        ? 'PayModalTitle'
        : 'SubscriptionModalTitle';

      return this.$t(`$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.${locale}`, {
        item: this.checkoutAppServicePackageName,
      });
    },
  },
  methods: {
    ...mapActions({
      fetchBalanceInformation: 'auth/fetchBalanceInformation',
    }),
    async purchasePackage(creditCardPayload) {
      this.$vs.loading({ type: 'radius' });

      try {
        await this.addNewCreditCard(creditCardPayload);
      } catch (err) {
        this.$vs.loading.close();
        return;
      }

      try {
        await tenantService.paymentTopUpAccount({
          paymentMethodId: this.selectedPaymentMethod.id,
          packageNumber: this.checkoutAppServicePackageNumber,
          mode: this.selectedPayPeriod,
        });

        this.showCheckout = false;
        this.$showAsyncActionNotification({
          title: this.$t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.PackagePurchaseNotificationTitle'),
          text: this.$t('$UserSettings.$Shop.$ManageTopUps.$TopUpAccount.PackagePurchaseNotificationMsg'),
        });

        await this.fetchBalanceInformation();
      } catch (err) {
        this.paymentFailedMsg = err.response.data.message;
      } finally {
        this.$vs.loading.close();
      }
    },
  },
};
