var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();}}},[_c('div',{staticClass:"vx-row mb-3"},[_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/img/reviews/google-my-business-logo.svg"),"alt":"Google my business","width":"auto","height":"120"}}),_c('p',{staticClass:"text-center mt-base"},[_vm._v(" "+_vm._s(_vm.$t('$ReviewsSettingsModule.$BusinessTab.AutocompleteLabel'))+" ")]),_c('GmapAutocomplete',{staticClass:"vs-inputx vs-input--input normal mt-3",attrs:{"value":_vm.autocompleteDescription,"placeholder":_vm.$t('$ReviewsSettingsModule.$BusinessTab.AutocompletePlaceholder'),"bounds":_vm.autocompleteBounds},on:{"place_changed":_vm.onPlaceSelected}}),_c('p',{staticClass:"text-center mt-base"},[_vm._v(" "+_vm._s(_vm.$t('$ReviewsSettingsModule.$BusinessTab.IsYourBusinessMissing'))+" ")]),_c('div',{staticClass:"text-center mt-3"},[_c('a',{staticClass:"link-contextual",attrs:{"href":"https://business.google.com/create","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('$ReviewsSettingsModule.$BusinessTab.CreateGoogleListing'))+" ")])]),_c('p',{staticClass:"mt-5 text-center"},[_c('small',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.$t('$ReviewsSettingsModule.$BusinessTab.CreateGoogleListingMsg'))+" ")])])],1),_c('div',{staticClass:"vx-col w-full md:w-1/2"},[_c('vs-divider',{staticClass:"block md:hidden my-base"}),_c('div',{staticClass:"mt-5"},[_c('small',{staticClass:"font-bold"},[_vm._v(" "+_vm._s(_vm.$t('$ReviewsSettingsModule.$BusinessTab.MapLabel'))+" ")]),_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"350px"},attrs:{"zoom":_vm.zoom,"center":_vm.mapCenter,"options":{
              zoomControl: true,
              scaleControl: true,
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
              disableDefaultUi: true
            }},on:{"click":_vm.onMapClicked,"center_changed":_vm.updateCenter,"idle":_vm.sync}},[_c('gmap-info-window',{attrs:{"options":_vm.infoWindowOptions,"position":_vm.infoWindowPos,"opened":_vm.infoWindowOpen},on:{"closeclick":function($event){_vm.infoWindowOpen=false}}}),(_vm.placeLatLng)?_c('GmapMarker',{attrs:{"position":_vm.placeLatLng,"clickable":""},on:{"click":_vm.onMarkClicked}}):_vm._e()],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }