<template>
  <vue-element-loading
    :active="active"
    color="rgba(var(--vs-primary), 1)"
    spinner="mini-spinner"
    :class="{active: active}"
    :text="text"
    :text-style="{'font-weight': 'bold'}"/>
</template>

<script>

/**
 * App loading
 *
 * @module src/views/_components/AppLoading.vue
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} active - indicate if active or not the loading
 * @vue-prop {string} [text= ''] - text to show below loading icon
 */
export default {
  name: 'AppLoading',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.vs-component.con-vs-popup {
  .vs-popup--content {
    .velmld-overlay {
      &.active {
        padding-top: 4rem;
      }
    }
  }
}
</style>
