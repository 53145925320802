<template>
  <vs-popup
    :title="title"
    :active="value"
    @update:active="$emit('input', $event)">
    <transition name="zoom-fade">
      <form @submit.prevent>
        <div class="vx-row mb-2">
          <div class="vx-col w-full">
            <vs-input
              v-model="name"
              class="w-full required"
              :name="$t('$General.Name')"
              :label="$t('$General.Name')"
              type="text"
              v-validate="'required|max:100'"
              data-vv-validate-on="blur|input"
              :danger="errors.has($t('$General.Name'))"
              :danger-text="errors.first($t('$General.Name'))"
              val-icon-danger="clear"/>
          </div>
        </div>

        <base-form-footer-action-buttons
          @save="$emit('save', name)"
          @cancel="$emit('input', false)">
        </base-form-footer-action-buttons>
      </form>
    </transition>
  </vs-popup>
</template>

<script>
import BaseFormFooterActionButtons
from '@/views/modules/_components/BaseFormFooterActionButtons.vue';

export default {
  name: 'CampaignContactsSaveSegmentModal',
  components: {
    BaseFormFooterActionButtons,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
    };
  },
};
</script>

<style scoped>

</style>
