import { omit, isEqual } from 'underscore';

const omitKeys = ['id', '_id'];

export default (segmentOne, segmentTwo) => {
  if (segmentOne.filtersMatch !== segmentTwo.filtersMatch) {
    return false;
  }

  const segmentOneFilters = omit(segmentOne.filters, omitKeys);
  const segmentTwoFilters = omit(segmentTwo.filters, omitKeys);

  return isEqual(segmentOneFilters, segmentTwoFilters);
};
