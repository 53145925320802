<template>
  <form @submit.prevent>
    <slot name="top"></slot>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <label
          class="vs-input--label"
          :class="{ required: !disabledEditPhone }">
          {{ $tc('$General.Phone') }}
        </label>
        <vue-tel-input
          v-model="model.phoneNational"
          :class="{ 'border-danger': invalidPhoneNumber || requiredPhoneNumber }"
          :disabled="disabledEditPhone"
          :disabledFetchingCountry="true"
          :dynamicPlaceholder="false"
          :enabledCountryCode="true"
          :validCharactersOnly="true"
          :preferredCountries="preferredCountries"
          :defaultCountry="defaultCountry"
          @validate="(payload) => (this.model.phoneValidate = payload)"
          @input="onPhoneInput()">
        </vue-tel-input>
        <div
          v-show="invalidPhoneNumber"
          class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
          style="height: 19px;">
          <span class="span-text-validation">
          {{ $t('$CallerIdModule.InvalidPhoneNumberMsg') }}
          </span>
        </div>
        <div
          v-show="requiredPhoneNumber"
          class="con-text-validation span-text-validation-danger vs-input--text-validation-span"
          style="height: 19px;">
          <span class="span-text-validation">
          {{ $t('$CallerIdModule.PhoneNumberRequiredMsg') }}
          </span>
        </div>
      </div>
    </div>

    <div class="vx-row mb-6">
      <div class="vx-col w-full">
        <vs-input
          v-model="model.name"
          class="w-full"
          :class="{required: !isView}"
          :disabled="isView"
          :name="$t('$General.Name')"
          :label="$t('$General.Name')"
          v-validate="'required|max:100'"
          data-vv-validate-on="blur|input"
          :danger="errors.has($t('$General.Name'))"
          :danger-text="errors.first($t('$General.Name'))"
          val-icon-danger="clear"/>
      </div>
    </div>

    <base-form-footer-action-buttons
      :hide-save="isView"
      :actions="filteredActionsByType"
      @action="onAction"
      @save="save(model.toSavePayload())"
      @cancel="$emit('close')">
      <template
        v-if="isView"
        v-slot:cancel>
        {{ $t("$General.Close") }}
      </template>
    </base-form-footer-action-buttons>
  </form>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import { VueTelInput } from 'vue-tel-input';

import CallerIdConstructor from '@/views/modules/sender-ids/caller-id/caller-id.constructor';
import commonCreateOrEditSenderId from '@/views/modules/sender-ids/_common/mixins/common-create-or-edit-sender-id';

// Components
import BaseFormFooterActionButtons from '@/views/modules/_components/BaseFormFooterActionButtons.vue';

/**
 * Component to create or edit caller IDs from phone number
 *
 * @module views/modules/sender-ids/caller-id/CallerIdListCreateOrEditFromPhoneNumber
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object} [model={...}] - caller id model to save
 * @vue-data {boolean} phoneInput - indicate if phone input was changed
 * @vue-data {boolean} validateOnSave - indicate validate on save was make
 * @vue-data {Function | null} [addItemFunction=null] - function to add one sender id
 * @vue-data {Function | null} [editItemFunction=null] - function to edit one sender id
 * @vue-computed {boolean} verifyProperties - verify is form to save is valid
 * @vue-computed {boolean} invalidPhoneNumber - indicate if the phone number entered is invalid
 * @vue-computed {boolean} requiredPhoneNumber - indicate if the phone number wasn't entered
 * @vue-computed {boolean} disabledEditPhone - indicate if phone number input is disabled
 * @vue-computed {void} onPhoneInput - called on phone input
 * @vue-computed {void} additionalValidateFormToSave - additional validation on form save
 */
export default {
  name: 'CallerIdListCreateOrEditFromPhoneNumber',
  mixins: [commonCreateOrEditSenderId],
  components: {
    VueTelInput,
    BaseFormFooterActionButtons,
  },
  data() {
    return {
      model: new CallerIdConstructor(this.operation, this.modelPayload, this.nameToCreate),
      phoneInput: false,
      validateOnSave: false,
      addItemFunction: this.addCallerId,
      editItemFunction: this.editCallerId,
      focusOnInit: false,
    };
  },
  computed: {
    ...mapGetters({
      tenantDetailsCountry: 'auth/tenantAccountDetailsCountry',
    }),
    defaultCountry() {
      return this.model.codeCountry || this.tenantDetailsCountry || 'AU';
    },
    preferredCountries() {
      return [
        this.model.codeCountry,
        this.tenantDetailsCountry,
        'AU',
      ].filter((country) => !!country);
    },
    verifyProperties() {
      return this.model.name !== '' && this.model.phoneValidate.isValid;
    },
    invalidPhoneNumber() {
      return this.phoneInput && !this.model.phoneValidate.isValid && this.model.phoneNational !== '';
    },
    requiredPhoneNumber() {
      return ((this.phoneInput && !this.model.phoneValidate.isValid) || this.validateOnSave)
        && this.model.phoneNational === '';
    },
    disabledEditPhone() {
      return this.isView || this.isPhoneNumberVerified || this.isDedicatedNumber;
    },
  },
  methods: {
    ...mapActions({
      addCallerId: 'callerId/addCallerId',
      editCallerId: 'callerId/editCallerId',
    }),
    onPhoneInput() {
      this.phoneInput = true;
      this.validateOnSave = false;
    },
    async additionalValidateFormToSave() {
      this.validateOnSave = true;
      return !this.requiredPhoneNumber && !this.invalidPhoneNumber;
    },
  },
};

</script>
