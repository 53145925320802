/**
 * mixin to verify sender ids
 *
 * @module views/modules/mixins/senderIdVerification
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} [activeModalVerify=false] - to active or inactive the verify modal
 * @vue-data {boolean} sendVerificationCode - indicate if send or no the verify code
 * @vue-data {Object} recordToVerify - the Caller ID to verify
 * @vue-event {void} afterVerifyCallerId - called after verification of sender id
 */
export default {
  data() {
    return {
      activeModalVerify: false,
      sendVerificationCode: false,
      recordToVerify: null,
    };
  },
  watch: {
    lastCreated(val) {
      this.recordToVerify = val;
      this.sendVerificationCode = true;
      this.activeModalVerify = true;
    },
    activeModalVerify(val) {
      if (!val) {
        this.sendVerificationCode = false;
      }
    },
  },
  methods: {
    afterVerifySenderId() {
      this.activeModalVerify = false;
    },
  },
};
