<template>
  <vs-popup
    :title="$t('Title', { phoneNumber: this.phoneNumber })"
    :active.sync="valueLocal">
    <transition name="zoom-fade">
      <from-phone-number-verification
        v-if="valueLocal"
        :phone-number="phoneNumber"
        @close="valueLocal=false"
        @verified="(caller) => onVerified(caller)"
        @caller-retrieved="(callerId) => $emit('caller-retrieved', callerId)"/>
    </transition>
  </vs-popup>
</template>

<script>
// components
import FromPhoneNumberVerification
from '@/views/modules/campaigns/sms-campaign/_components/from-phone-number-verification/FromPhoneNumberVerification.vue';

// mixins
import FromPhoneNumberVerificationPropsMixin
from '@/views/modules/campaigns/sms-campaign/_components/from-phone-number-verification/from-phone-number-verification-props.mixin';

/**
 * Modal for FromPhoneNumberVerification component
 *
 * @module views/modules/campaigns/sms-campaign/_components/from-phone-number-verification/FromPhoneNumberVerificationModal
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {boolean} value - indicate if show or no the modal
 * @vue-data {boolean} valueLocal - indicate if show or no the modal
 * @vue-event {void} onVerified - called after completed verification
 */
export default {
  name: 'FromPhoneNumberVerificationModal',
  i18n: {
    messages: {
      en: {
        Title: 'From phone number verification ({phoneNumber})',
      },
    },
  },
  components: {
    FromPhoneNumberVerification,
  },
  mixins: [FromPhoneNumberVerificationPropsMixin],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valueLocal: this.value,
    };
  },
  watch: {
    value(val) {
      this.valueLocal = val;
    },
    valueLocal(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    onVerified(caller) {
      this.$emit('verified', caller);
      this.valueLocal = false;
    },
  },
};
</script>
