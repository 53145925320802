<template>
  <vx-card class="mb-base">
    <form-wizard
      ref="wizard"
      class="wizard-no-title"
      color="rgba(var(--vs-primary), 1)"
      errorColor="rgba(var(--vs-danger), 1)"
      :title="null"
      :subtitle="null"
      :start-index="0"
      @on-complete="sendCampaign()"
      @on-change="onChangeTab">

      <!-- Campaign info -->
      <tab-content
        :title="$t('$CampaignsModules.CampaignInfo')"
        class="mb-5 pt-4"
        icon="feather icon-info"
        :before-change="validateCampaignInfo">
        <div class="lg:px-32">
          <campaign-information
            v-if="loadedCampaign && campaignStep === 0"
            ref="campaignInfo"
            :key="campaignInfoKey"
            :campaign-type="$enums.Campaign.Type.SMS"
            :operation="operation"
            :name.sync="model.name"
            :message-type.sync="model.messageType"
            :sender-id.sync="model.senderId"
            :sender-business-name.sync="model.senderBusinessName"
            :caller-id-type.sync="model.callerIdType"
            @validate="(val) => isValidInfoStep = val"/>
        </div>
      </tab-content>

      <!-- Message -->
      <tab-content
        :title="$tc('$General.Message')"
        class="mb-5"
        icon="feather icon-message-square"
        :before-change="validateCampaignMessage">
        <campaign-message
          v-if="loadMessage && campaignStep === 1"
          ref="campaignMessage"
          :message.sync="model.message"
          :has-interpolations.sync="model.hasInterpolations"
          :interpolations.sync="model.interpolations"
          :message-type="model.messageType"
          :caller-id-type="model.callerIdType"
          :sender-id="model.senderId"
          :sender-business-name="model.senderBusinessName"
          :attributes-from-contacts="attributesFromContacts"
          :is-quick="false"
          @test="onTest"
          @change-to-transactional="changeToTransactional()"/>
      </tab-content>

      <!-- Contacts-->
      <tab-content
        :title="$tc('$Entities.Contact', 2)"
        class="mb-5 px-0"
        icon="feather icon-users"
        :before-change="validateSelectedContacts">
        <campaign-contacts
          ref="campaignContacts"
          v-if="loadContacts && campaignStep === 2"
          v-model="model.segment"
          :initial-filters.sync="model.filters"
          :initial-filter-match.sync="model.filtersMatch"
          :all-contacts.sync="model.allContacts"
          :campaign-message-type="model.messageType"
        />
      </tab-content>

      <!-- Delivery type -->
      <tab-content
        :title="$t('$CampaignsModules.CampaignDeliveryType')"
        class="mb-5 md:px-16 lg:px-32 xl:px-32"
        icon="feather icon-settings"
        :before-change="validateCampaignSettings">
        <campaign-settings
          ref="campaignSettings"
          v-if="loadSettings && loadedCampaign  && campaignStep === 3"
          :campaign-type="$enums.Campaign.Type.SMS"
          :operation="operation"
          :delivery-type.sync="model.deliveryType"
          :local-start-date.sync="model.localStartDate"
          :time-zone.sync="model.timeZone"
          :is-quick="false"
          @start-immediately="(val) => campaignWillStartImmediately = val"
          @close-flat-picker="handleCloseFlatPicker">
        </campaign-settings>
      </tab-content>

      <!-- Confirm -->
      <tab-content
        :title="$t('$CampaignsModules.ConfirmCampaign')"
        class="mb-5 md:px-16 lg:px-32 xl:px-32 py-6"
        icon="feather icon-file-text">
        <campaign-confirm
          v-if="loadConfirm && loadedCampaign && campaignStep === 4"
          :model="model"
          :campaignType="$enums.Campaign.Type.SMS"
          :fetch-campaign-cost-info="fetchCostSMSCampaign"
          :operation="operation"
          :message-title="$tc('$General.Message')"
          preview-link
          @return="returnToStep"
          @const-info="(info) => costInfo=info"
          @message-preview="showPreview=true"
          @start-immediately="(val) => campaignWillStartImmediately = val">
          <template v-slot:message>
            <div style="line-break: anywhere">
              {{ model.message }}
            </div>
          </template>

          <template v-slot:contacts>
            <vs-alert
              v-if="costInfo && costInfo.contactsWithCutMessage"
              active="true"
              color="warning"
              icon-pack="feather"
              icon="icon-alert-circle"
              class="h-auto mt-5">
              <div class="flex justify-between items-center">
                <strong>
                  {{ $t('$CampaignsModules.$CampaignConfirm.ContactsWithCutMessages', {
                  count: costInfo.contactsWithCutMessage,
                }) }}
                </strong>

                <a
                  href="#"
                  @click.prevent="returnToStep(1)">
                  Return to message
                </a>
              </div>

              <p>
                {{ $t('$CampaignsModules.$CampaignConfirm.ContactsWithCutMessagesMsg') }}
              </p>
            </vs-alert>
          </template>
        </campaign-confirm>
      </tab-content>

      <template
        slot="footer"
        slot-scope="props">
        <campaign-wizard-footer-buttons
          class="mt-base"
          :valid-send="!!costInfo"
          :wizard-props="props"
          :operation="operation"
          :is-draft="campaignIsDraft"
          :delivery-type="campaignDeliveryType"
          :valid-save="!!isValidToSaveDraft"
          @save-and-quit="confirmSaveAndQuitCampaign()"
          @reset="confirmRestartCampaign()"
          @test="showPreview=true">
        </campaign-wizard-footer-buttons>
      </template>
    </form-wizard>

    <vs-popup
      title="SMS Preview"
      :active.sync="showPreview">
      <transition name="zoom-fade">
        <message-preview
          v-if="showPreview"
          :message="model.message"
          :message-type="model.messageType"
          :interpolations="model.interpolations"
          :caller-id-type="model.callerIdType"
          :sender-id="model.senderId"
          :sender-business-name="model.senderBusinessName"
          :attributes-from-contacts="attributesFromContacts"
          @test="onTest"/>
      </transition>
    </vs-popup>

    <package-checkout-modal
      :active.sync="showCheckout"/>

    <sms-get-started-modal/>
  </vx-card>
</template>

<script>
import {
  mapActions, mapState, mapMutations, mapGetters,
} from 'vuex';
import store from '@/store/store';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import enums from '@/enums';

// Constructor
import SmsCampaignConstructor from '@/views/modules/campaigns/sms-campaign/sms-campaign.constructor';

// components
import SMSCampaignListCreateOrEditMessage from '@/views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEditMessage.vue';
import SMSCampaignListCreateOrEditMessagePreview from '@/views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEditMessagePreview.vue';
import SMSCampaignGetStartedModal from '@/views/modules/campaigns/sms-campaign/_components/SMSCampaignGetStartedModal.vue';

// Mixins
import commonCampaigns from '@/views/modules/campaigns/common/mixins/commonCampaigns';
import campaignScheduleRestrictions from '@/views/modules/campaigns/common/mixins/campaignScheduleRestrictions';

// Functions
import segmentsAreEqual from '@/views/modules/segment/segment-comparator';

/**
 * Component to create or edit SMS Campaigns
 *
 * @module views/modules/campaigns/sms-campaign/SMSCampaignListCreateOrEdit
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-computed  {Object} draftCampaign - draft campaign saved
 * @vue-computed  {Object} fromSMSTemplate - from SMS template to create a new campaign
 * @vue-computed  {Object[]} smsTemplates - SMS templates collection
 * @vue-event {void} getTemplates - get the SMS template and show modal to select
 * @vue-event {void} insertTemplate - called to insert one SMS Template to message
 * @vue-event {void} initCampaignModel - init the model of campaign
 * @vue-event {void} validateCampaignMessage - validate if sms text box is valid
 * @vue-event {void} changeToTransactional - change campaign to transactional and set to tab 1
 */
export default {
  name: 'SMSCampaignListCreateOrEdit',
  i18n: {
    messages: {
      en: {
        $InsufficientBalanceToTestNotify: {
          Title: 'Test SMS Campaign message',
          Text: 'You do not have enough balance to send this SMS test. Do you wish to recharge?',
        },
        $ScheduleTimeInPastAlert: {
          Title: 'Schedule time is in the past',
          Text: 'You have scheduled your campaign to be sent in the past. The Campaign will be sent immediately.',
        },
      },
    },
  },
  components: {
    campaignMessage: SMSCampaignListCreateOrEditMessage,
    messagePreview: SMSCampaignListCreateOrEditMessagePreview,
    smsGetStartedModal: SMSCampaignGetStartedModal,
  },
  mixins: [commonCampaigns, campaignScheduleRestrictions],
  props: {
    modelPayload: {
      type: Object,
      required: false,
      default() {
        return store.state.smsCampaign.payload
          ? store.state.smsCampaign.payload
          : null;
      },
    },
    contactsSegment: {
      required: false,
    },
    contactsFilters: {
      type: Object,
      required: false,
    },
    contactsFiltersMatch: {
      type: String,
      required: false,
      default: enums.AppFilterMathType.ALL,
    },
  },
  data() {
    return {
      showPreview: false,
      campaignBaseRoute: '/sms-campaigns',
      campaignType: this.$enums.Campaign.Type.SMS,
      addItemFunction: this.addSMSCampaign,
      editItemFunction: this.editSMSCampaign,
      attributesFromContacts: [],
      initCampaignModelFunction: this.initCampaignModel,
      setCampaignPayloadFunction: this.setSMSCampaignPayload,
      showSMSGetStarted: true,
      isValidInfoStep: false,
      campaignWillStartImmediately: false,
    };
  },
  computed: {
    ...mapState({
      draftCampaign: (state) => state.auth.user.campaignsDraft,
      fromSMSTemplate: (state) => state.smsCampaign.fromSMSTemplate,
    }),
    ...mapGetters({
      getUserFlag: 'auth/getUserFlag',
    }),
    isValidToSaveDraft() {
      return this.isValidInfoStep || (!!this.campaignStep && this.campaignStep > 0);
    },
    campaignStep() {
      return this.model ? this.model.step || 0 : 0;
    },
    campaignIsDraft() {
      return this.model ? this.model.isDraft || false : false;
    },
    campaignDeliveryType() {
      return this.model
        ? this.model.deliveryType || this.$enums.Campaign.DeliveryType.IMMEDIATELY
        : this.$enums.Campaign.DeliveryType.IMMEDIATELY;
    },
  },
  created() {
    this.fetchContactsAttributes();

    this.focusOnInit = this.getUserFlag('smsCampaignGetStartedModalSkipped');
  },
  methods: {
    ...mapActions({
      fetchItem: 'smsCampaign/fetchSMSCampaign',
      addSMSCampaign: 'smsCampaign/addSMSCampaign',
      editSMSCampaign: 'smsCampaign/editSMSCampaign',
      removeItem: 'smsCampaign/removeSMSCampaign',
      getTestCostSMSCampaign: 'smsCampaign/fetchTestCostSMSCampaign',
      testCampaign: 'smsCampaign/testSMSCampaign',
      fetchCostSMSCampaign: 'smsCampaign/fetchCostSMSCampaign',
      fetchAllAttributes: 'attribute/fetchAllAttributes',
      fetchSegment: 'segment/fetchSegment',
    }),
    ...mapMutations({
      setTemplateToCampaign: 'smsCampaign/SET_FROM_SMS_TEMPLATE',
      setSMSCampaignPayload: 'smsCampaign/SET_SMS_CAMPAIGN_PAYLOAD',
    }),
    onChangeTab(prevIndex, nextIndex) {
      this.model.step = nextIndex;
      this.$refs.wizard.$el.scrollIntoView(true);

      if (prevIndex === 2
        && nextIndex < prevIndex
        && this.$refs.campaignContacts
        && this.$refs.campaignContacts.selectedSegmentWasChanged()) {
        this.model.segment = null;
      }
    },
    async fetchContactsAttributes() {
      const resp = await this.fetchAllAttributes({});
      this.attributesFromContacts = resp.data;
    },
    async initCampaignModel({ isDraft, payload }) {
      const messageType = this.$route.query.messageType || null;
      const finalPayload = { ...payload };

      if ((isDraft || this.isEdition || this.isCloning)
        && payload && payload.segment
        && typeof payload.segment === 'string'
      ) {
        finalPayload.segment = await this.fetchSegment(payload.segment);

        const segmentOne = finalPayload.segment;
        const segmentTwo = {
          filters: finalPayload.filters,
          filtersMatch: finalPayload.filtersMatch,
        };

        if (!segmentsAreEqual(segmentOne, segmentTwo)) {
          finalPayload.segment = null;
        }
      }

      this.model = new SmsCampaignConstructor(
        this.operation,
        finalPayload,
        isDraft,
        false,
        messageType,
        this.contactsFilters,
        this.contactsFiltersMatch,
        this.contactsSegment,
      );

      if (this.fromSMSTemplate && this.operation === this.$enums.Operation.CREATE) {
        this.model.message += this.fromSMSTemplate.message;
        this.model.hasInterpolations = this.fromSMSTemplate.hasInterpolations;
        this.model.interpolations = this.fromSMSTemplate.interpolations;
        this.setTemplateToCampaign(null);
      }
    },
    async validateCampaignMessage() {
      const isValid = await this.$refs.campaignMessage.validateMessage();

      if (isValid) {
        await this.continueCampaignStep();
        this.campaignMessageIsValid();
        return true;
      }

      return false;
    },
    async validateSelectedContacts() {
      const result = await this.$refs.campaignContacts.validate();

      if (result) {
        const {
          filters,
          filtersMatch,
        } = await this.$refs.campaignContacts.getFiltersAndFilterMatch();
        this.model.filters = filters;
        this.model.filtersMatch = filtersMatch;

        if (
          (this.model.segment
          && this.model.segment.id === this.$enums.DEFAULT_ALL_CONTACTS_SEGMENT_ID)
          || this.$refs.campaignContacts.selectedSegmentWasChanged()) {
          if (this.model.segment
            && this.model.segment.id === this.$enums.DEFAULT_ALL_CONTACTS_SEGMENT_ID) {
            this.model.allContacts = true;
          }

          this.model.segment = null;
        }

        await this.continueCampaignStep();
        this.campaignContactsIsValid();
        return true;
      }

      return false;
    },
    changeToTransactional() {
      this.$refs.wizard.prevTab();
      this.model.messageType = this.$enums.Campaign.MessageType.TRANSACTIONAL;
    },
    async onTest(phoneNumber) {
      this.showPreview = false;
      this.$vs.loading({ type: 'radius' });
      const resp = await this.getTestCostSMSCampaign({
        ...this.model.toTestPayload(),
        sender: phoneNumber,
      });
      this.$vs.loading.close();

      if (resp.canPaid) {
        this.confirmTestCampaign({
          campaignType: this.$tc('$General.SMS'),
          senderId: phoneNumber,
          cost: resp.cost,
        });
      } else {
        this.$showConfirmWarningDialog({
          title: this.$t('$InsufficientBalanceToTestNotify.Title'),
          text: this.$t('$InsufficientBalanceToTestNotify.Text'),
          accept: () => {
            this.showCheckout = true;
          },
          acceptText: this.$t('$General.Recharge'),
        });
      }
    },
    async sendCampaign() {
      if (this.model.deliveryType === this.$enums.Campaign.DeliveryType.LATER
        && this.campaignWillStartImmediately) {
        this.$showAlertWarningDialog({
          title: this.$t('$ScheduleTimeInPastAlert.Title'),
          text: this.$t('$ScheduleTimeInPastAlert.Text'),
          accept: async () => this.confirmSendCampaign(
            this.costInfo.totalContactsToSend,
            this.costInfo.campaignTotalPrice,
          ),
        });
      } else {
        await this.confirmSendCampaign(
          this.costInfo.totalContactsToSend,
          this.costInfo.campaignTotalPrice,
        );
      }
    },
  },
};
</script>
