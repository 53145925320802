// Services
import callerIdService from '@/api/modules/caller-id.service';

// Constructors
import CallerIdConstructor from '@/views/modules/sender-ids/caller-id/caller-id.constructor';

/**
 * Mixins to share data and functionality to create(pay) new dedicated numbers
 *
 * @module views/modules/sender-ids/caller-id/_mixins/create-dedicated-number.mixin
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {boolean} [showCheckout=false] - indicate if show or not the checkout modal
 * @vue-data {string} [numberToBuy=''] - number to buy
 * @vue-event {void} buyDedicatedNumber - show the checkout for virtual number
 * @vue-event {void} sentPaymentIntent - sent payment intent to create a new dedicated number
 */
export default {
  data() {
    return {
      showCheckout: false,
      numberToBuy: '',
    };
  },
  methods: {
    buyDedicatedNumber(number) {
      this.numberToBuy = number;
      this.showCheckout = true;
    },
    async sentPaymentIntent({
      selectedPaymentMethodId,
      selectedBillingInterval,
    }) {
      const callerId = new CallerIdConstructor(
        this.$enums.Operation.CREATE,
        {
          name: this.numberToBuy,
          phoneInternational: this.numberToBuy,
        },
        this.numberToBuy,
      );

      const payload = callerId.toCreatePayload();
      payload.paymentMethodId = selectedPaymentMethodId;
      payload.billingInterval = selectedBillingInterval;

      const creationResp = await callerIdService.createDedicatedPhoneNumber(payload);

      this.$emit('saved', creationResp.data);
    },
  },
};
