<template>
  <form @submit.prevent>
    <div class="vx-row">
      <div
        v-if="!compactDesign"
        class="vx-col w-full sm:w-1/5 flex items-start pt-base">
        <span>{{ $t('$General.Name') }}</span>
      </div>
      <div
        class="vx-col w-full"
        :class="{'sm:w-4/5': !compactDesign}">
        <vs-input
          v-model="nameLocal"
          class="w-full required"
          :name="$t('$General.Name')"
          :label="compactDesign ? $t('$General.Name') : ' '"
          :placeholder="$t('CampaignNamePlaceholder')"
          v-validate="'required|max:100'"
          data-vv-validate-on="blur|input"
          @input="$emit('update:name', nameLocal)"
          icon="icon icon-send"
          icon-pack="feather"
          :danger="errors.has($t('$General.Name'))"
          :danger-text="errors.first($t('$General.Name'))"
          val-icon-danger="clear"/>
      </div>
    </div>

    <div
      class="vx-row"
      :class="[compactDesign ? 'mt-6' : 'mt-base']">
      <div
        v-if="!compactDesign"
        class="vx-col sm:w-1/5 w-full flex items-start pt-base">
        <span>{{ $t("$General.From") }}</span>
      </div>
      <div
        class="vx-col w-full"
        :class="{'sm:w-4/5': !compactDesign}">
        <label
          class="vs-input--label required">
          {{ compactDesign ? $t('$General.From') : ' ' }}
        </label>
        <v-select
          v-model="callerIdTypeLocal"
          class="w-full"
          :options="callerIdTypeOptions"
          :clearable="false"
          :multiple="false"
          close-on-select
          :getOptionLabel="(option) => $t(`$Enums.Sender.Caller.Type.${option}`)"/>

        <div class="mt-3">
          <caller-business-name
            v-if="callerIdTypeLocal === $enums.Sender.Caller.Type.BUSINESS_NAME"
            ref="callerBusinessName"
            v-model="senderBusinessNameLocal"
            :input-name="$t('$General.From')"/>

          <v-select-dedicated-number
            v-if="callerIdTypeLocal === $enums.Sender.Caller.Type.DEDICATED_NUMBER"
            ref="vSelectDedicatedNumber"
            v-model="senderIdLocal"
            @validate="(val) => senderDedicatedNumberComponentIsValid = val">
          </v-select-dedicated-number>

          <v-select-phone-number
            v-if="callerIdTypeLocal === $enums.Sender.Caller.Type.PHONE_NUMBER"
            ref="vSelectPhoneNumber"
            v-model="senderIdLocal"
            @validate="(val) => senderPhoneNumberComponentIsValid = val">
          </v-select-phone-number>
        </div>
      </div>
    </div>

    <div
      class="vx-row"
      :class="[compactDesign ? 'mt-6': 'mt-base']">
      <div
        v-if="!compactDesign"
        class="vx-col sm:w-1/5 w-full flex items-start pt-base">
        <span class="inline-flex">
          <span>
            {{ $tc('$General.Type') }}
          </span>
          <feather-icon
            icon="InfoIcon"
            class="cursor-pointer"
            svgClasses="h-4 w-4 ml-1"
            @click="showCampaignTypeModal=true"/>
        </span>
      </div>
      <div
        class="vx-col w-full"
        :class="{'sm:w-4/5': !compactDesign}">
        <div
          class="vx-row"
          :class="{'mt-3': compactDesign}">
          <div class="vx-col w-full">
            <card-radio-toggle
              ref="messageTypeRadioToggle"
              :key="messageTypeRadioToggleKey"
              v-model="messageTypeLocal"
              :radio-name="$tc('$General.Type')"
              :radio-options="messageTypeCardRadioToggleOptions"
              :show-label="compactDesign"
              icon="InfoIcon"
              required
              @icon-click="showCampaignTypeModal=true"/>
          </div>
        </div>
      </div>
    </div>

    <vs-alert
      color="primary"
      :active="showReviewsSettingsAlert"
      icon-pack="feather"
      icon="icon-info"
      class="text-center mt-6">
      {{ $t('NoSetUpReviewsAlertText') }}
      <a href="#"
        class="link-plain"
        @click.prevent="activeModalReviewSettings=true">
        {{ $t('NoSetUpReviewsAlertLink') }}
      </a>
    </vs-alert>

    <slot></slot>

    <campaign-type-info-modal v-model="showCampaignTypeModal"/>

    <vs-popup
      :title="$t('$Components.$GoogleReviewsSummaryCard.SetupGoogleReviews')"
      fullscreen
      :active.sync="activeModalReviewSettings"
      class="mt-0 mb-0 pt-0 pb-0">
      <transition name="zoom-fade">
        <reviews-settings-create-or-edit
          v-if="activeModalReviewSettings"
          :card-style="false"
          :hide-request-review-button="true"
          @cancel="activeModalReviewSettings=false"
          @settings-updated="showReviewsSettingsAlert=false"/>
      </transition>
    </vs-popup>
  </form>
</template>

<script>
import vSelect from 'vue-select';
import { mapActions, mapGetters } from 'vuex';
import enums from '@/enums';

// Mixins
import campaignStep from '@/views/modules/campaigns/common/mixins/campaignStep';

// Components
import CardRadioToggle from '@/views/modules/_components/CardRadioToggle.vue';
import ReviewsSettingsCreateOrEdit from '@/views/modules/reviews/ReviewsSettingsCreateOrEdit.vue';
import CampaignInformationCallerIdBusinessName from '@/views/modules/campaigns/components/campaign-information/CampaignInformationCallerIdBusinessName.vue';
import CampaignInformationVSelectPhoneNumber from '@/views/modules/campaigns/components/campaign-information/CampaignInformationVSelectPhoneNumber.vue';
import CampaignInformationVSelectDedicatedNumber
from '@/views/modules/campaigns/components/campaign-information/CampaignInformationVSelectDedicatedNumber.vue';
import CampaignInformationCampaignTypeInfoModal
from '@/views/modules/campaigns/components/campaign-information/CampaignInformationCampaignTypeInfoModal.vue';

/**
 * Component to create or edit SMS Campaigns
 *
 * @module views/modules/campaigns/CampaignInformation
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-prop {string} name - name of campaign
 * @vue-prop {string} messageType - type of message for campaign
 * @vue-prop {Object} senderId - sender id of campaign
 * @vue-prop {Object} senderBusinessName - sender business name of campaign
 * @vue-prop {string} callerIdType - the caller ID type of campaign
 * @vue-data {boolean} showReviewsSettingsAlert - indicate if show or no the reviews settings alert
 * @vue-data {string} nameLocal - name local of campaign
 * @vue-data {string} messageTypeLocal - type of message local for campaign
 * @vue-data {Object} senderIdLocal - local sender id of campaign
 * @vue-data {Object} senderBusinessNameLocal - local sender business name of campaign
 * @vue-data {string} callerIdTypeLocal - the sender type local of campaign
 * @vue-data {boolean} showCampaignTypeModal - indicate if show or no the campaign type modal
 * @vue-data {Object[]} messageTypeCardRadioToggleOptions - options for caller from id type radio
 * @vue-data {Object[]} callerIdTypeOptions - options caller ID type
 * @vue-data {boolean} activeModalReviewSettings - indicate if show or no the modal review settings
 * @vue-computed {boolean} senderSharedNumberIsValid - indicate if sender shared number is valid
 * @vue-computed {boolean} senderPhoneNumberIsValid - indicate if sender phone number is valid
 * @vue-computed {boolean} senderBusinessNameIsValid - indicate if sender business name is valid
 * @vue-computed {boolean} senderIdIsValid - indicate if sender id is valid
 * @vue-computed {boolean} isValid - indicate if teh campaign info is valid or no
 * @vue-event {void} initSenderId - init the sender id initial value
 * @vue-event {boolean} validate - validate the campaign information and return the result
 * @vue-event {boolean} focusErrorField - validate the form and return the result
 */
export default {
  name: 'CampaignInformation',
  i18n: {
    messages: {
      en: {
        CampaignNamePlaceholder: 'Enter campaign name',
        NoSetUpReviewsAlertText: 'Did you know you can also send review requests?',
        NoSetUpReviewsAlertLink: 'Start now.',
      },
    },
  },
  components: {
    vSelect,
    CardRadioToggle,
    ReviewsSettingsCreateOrEdit,
    CallerBusinessName: CampaignInformationCallerIdBusinessName,
    VSelectPhoneNumber: CampaignInformationVSelectPhoneNumber,
    VSelectDedicatedNumber: CampaignInformationVSelectDedicatedNumber,
    CampaignTypeInfoModal: CampaignInformationCampaignTypeInfoModal,
  },
  mixins: [campaignStep],
  props: {
    name: {
      type: String,
      required: true,
    },
    messageType: {
      type: String,
      required: true,
      validator(type) {
        return ['', ...Object.values(enums.Campaign.MessageType)].indexOf(type) !== -1;
      },
    },
    senderId: {
      type: [Object, String],
      required: true,
    },
    senderBusinessName: {
      type: String,
      required: true,
    },
    callerIdType: {
      type: String,
      required: true,
      validator(type) {
        return Object.values(enums.Sender.Caller.Type).indexOf(type) !== -1;
      },
    },
  },
  data() {
    return {
      showReviewsSettingsAlert: false,
      nameLocal: this.name,
      messageTypeLocal: this.messageType,
      senderIdLocal: '',
      senderBusinessNameLocal: this.senderBusinessName,
      callerIdTypeLocal: this.callerIdType,
      showCampaignTypeModal: false,
      messageTypeCardRadioToggleOptions: [
        {
          value: this.$enums.Campaign.MessageType.TRANSACTIONAL,
          label: this.$t('$CampaignsModules.$CampaignType.TransactionalAndReviews'),
        },
        {
          value: this.$enums.Campaign.MessageType.MARKETING,
          label: this.$t('$CampaignsModules.$CampaignType.Marketing'),
        },
      ],
      callerIdTypeOptions: Object.values(this.$enums.Sender.Caller.Type).filter((type) => ![
        this.$enums.Sender.Caller.Type.PHONE_NUMBER,
        this.$enums.Sender.Caller.Type.BUSINESS_NAME,
      ].includes(type)),
      activeModalReviewSettings: false,
      senderPhoneNumberComponentIsValid: false,
      senderDedicatedNumberComponentIsValid: false,
      messageTypeRadioToggleKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      getTenantFlag: 'auth/getTenantFlag',
    }),
    senderSharedNumberIsValid() {
      if (this.callerIdTypeLocal === this.$enums.Sender.Caller.Type.SHARED_NUMBER) {
        return !this.senderIdLocal && !this.senderBusinessNameLocal;
      }

      return null;
    },
    senderPhoneNumberIsValid() {
      if (this.callerIdTypeLocal === this.$enums.Sender.Caller.Type.PHONE_NUMBER) {
        return this.senderPhoneNumberComponentIsValid;
      }

      return null;
    },
    senderDedicatedNumberIsValid() {
      if (this.callerIdTypeLocal === this.$enums.Sender.Caller.Type.DEDICATED_NUMBER) {
        return this.senderDedicatedNumberComponentIsValid;
      }

      return null;
    },
    senderBusinessNameIsValid() {
      if (this.callerIdTypeLocal === this.$enums.Sender.Caller.Type.BUSINESS_NAME) {
        return !!this.senderBusinessNameLocal;
      }

      return null;
    },
    senderIdIsValid() {
      return this.senderSharedNumberIsValid
        || this.senderPhoneNumberIsValid
        || this.senderDedicatedNumberIsValid
        || this.senderBusinessNameIsValid
        || false;
    },
    isValid() {
      return !this.errors.any()
        && !!this.nameLocal
        && this.senderIdIsValid
        && !!this.messageTypeLocal;
    },
  },
  watch: {
    senderIdLocal(val) {
      this.$emit('update:sender-id', val || '');
    },
    senderBusinessNameLocal(val) {
      this.$emit('update:sender-business-name', val);
    },
    callerIdTypeLocal(val) {
      this.senderIdLocal = '';
      this.senderBusinessNameLocal = '';
      this.$emit('update:caller-id-type', val);
    },
    isValid(val) {
      this.$emit('validate', val);
    },
    messageType(val) {
      this.messageTypeLocal = val;
      this.messageTypeRadioToggleKey += 1;
    },
    messageTypeLocal: {
      immediate: true,
      handler(newVal, oldVal) {
        this.showReviewsSettingsAlert = !this.getTenantFlag('completedReviewsSettings') && newVal === this.$enums.Campaign.MessageType.TRANSACTIONAL;

        this.$emit('update:message-type', this.messageTypeLocal);

        if (oldVal !== undefined) {
          setTimeout(() => {
            this.$validator.validate(this.$tc('$General.Type'));
          }, 0);
        }
      },
    },
  },
  created() {
    this.initSenderId();
  },
  mounted() {
    this.$emit('validate', this.isValid);
  },
  methods: {
    ...mapActions({
      fetchAllCallerIds: 'callerId/fetchAllCallerIds',
      fetchCallerId: 'callerId/fetchCallerId',
    }),
    async initSenderId() {
      if (this.senderId) {
        switch (this.callerIdType) {
          case this.$enums.Sender.Caller.Type.DEDICATED_NUMBER:
          case this.$enums.Sender.Caller.Type.PHONE_NUMBER:
            if (typeof this.senderId === 'string') {
              this.senderIdLocal = await this.fetchCallerId(this.senderId);
              // eslint-disable-next-line no-underscore-dangle
            } else if (this.senderId && this.senderId._id) {
              // eslint-disable-next-line no-underscore-dangle
              this.senderIdLocal = await this.fetchCallerId(this.senderId._id);
            } else {
              this.senderIdLocal = this.senderId || '';
            }

            break;

          default:
            this.senderIdLocal = this.senderId || '';
        }
      } else {
        this.senderIdLocal = '';
      }
    },
    async validate() {
      const campaignInfoResult = await this.$validator.validateAll();
      const messageTypeResult = await this.$refs.messageTypeRadioToggle.$validator.validateAll();
      let result = campaignInfoResult && messageTypeResult;
      let fromResult = true;

      switch (this.callerIdTypeLocal) {
        case this.$enums.Sender.Caller.Type.BUSINESS_NAME:
          fromResult = await this.$refs.callerBusinessName.$validator.validateAll();
          break;
        case this.$enums.Sender.Caller.Type.PHONE_NUMBER:
          fromResult = await this.$refs.vSelectPhoneNumber.validate();
          break;
        case this.$enums.Sender.Caller.Type.DEDICATED_NUMBER:
          fromResult = await this.$refs.vSelectDedicatedNumber.validate();
          break;

        default:
      }

      result = result && fromResult;

      if (this.$validator.errors.items.length > 0) {
        this.focusErrorField(this.$validator.errors.items[0].field);
      }

      return result;
    },
    focusErrorField(fieldName) { // todo: move this to central place to reuse
      const $fieldError = this.$el.querySelector(`[name="${fieldName}"]`);
      let $fieldToFocus = $fieldError;

      if ($fieldError) {
        if ($fieldError.attributes && $fieldError.attributes.type.value === 'hidden') {
          $fieldToFocus = $fieldError.parentElement;
          $fieldToFocus.scrollIntoView(false);
        } else {
          $fieldToFocus.focus();
        }
      }
    },
  },
};
</script>

<style lang="scss">
  // todo: this what does???
  @media (max-width: 450px) {
    form {
      .vx-row.mt-base {
        .vx-col > {
          .v-select.w-full.vs--single.vs--searchable  {
            .vs__selected-options {
              .vs__search {
                margin-top: -32px !important;
              }
            }
          }

        .v-select.w-full.vs--open.vs--single.vs--searchable  {
            .vs__selected-options {
              .vs__search {
                margin-top: 4px !important;
              }
            }
          }
        }
      }
    }
  }

</style>
