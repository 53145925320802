import appServiceService from '@/api/modules/app-service.service';

/**
 * Mixin to retrieve dedicated number app service information
 *
 * @module views/modules/sender-ids/caller-id/_mixins/dedicated-number-app-service
 * @author Dilan Useche <dilan8810@gmail.com>
 *
 * @vue-data {Object | null} [dedicatedNumberAppService=null] - dedicated number app service
 * @vue-data {string} [selectedInterval='month'] - selected interval to use
 * @vue-computed {number} selectedIntervalInMonthsNumber - number of months of the selected interval
 * @vue-computed {string} dedicatedNumberAppServiceName - app service name for dedicated number
 * @vue-computed {Object[]} dedicatedNumberAppServiceItems - dedicated number app service items
 * @vue-computed {Object | null} dedicatedNumberAppServiceItem - dedicated number app service item
 * @vue-computed {string} dedicatedNumberAppServiceItemName - dedicated number app service item name
 * @vue-computed {number} dedicatedNumberAppServiceItemPrice -
 * dedicated number app service item price
 * @vue-computed {number} dedicatedNumberAppServiceItemPriceByInterval -
 * price of app service item by selected interval
 * @vue-computed {number} dedicatedNumberAppServiceItemIntervalPriceMonthEquivalent -
 * monthly equivalent price for the interval selected
 * @vue-computed {number} dedicatedNumberAppServiceItemIntervalDiscount -
 * discount by selected interval
 * @vue-computed {number} dedicatedNumberAppServiceItemFirstMonthFee -
 * dedicated number app service item first month fee
 * @vue-computed {string} dedicatedNumberAppServiceItemFirstMonthFeeName -
 * dedicated number app service item first month fee name
 * @vue-computed {Object} dedicatedNumberAppServiceItemIntervals - interval for app service item
 * @vue-computed {string} dedicatedNumberAppServiceItemInactiveIntervalsKeys - inactive interval for app service item
 * @vue-computed {Object[]} firstMonthFeeCheckoutExtraItem - checkout extra items
 * @vue-event {void} getDedicatedNumberAppService - fetch dedicated number app service
 */
export default {
  data() {
    return {
      dedicatedNumberAppService: null,
      selectedInterval: this.$enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.MONTH,
    };
  },
  computed: {
    selectedIntervalInMonthsNumber() {
      switch (this.selectedInterval) {
        case this.$enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.TRIMESTER:
          return 3;
        case this.$enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.SEMESTER:
          return 6;
        case this.$enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.YEAR:
          return 12;

        default:
          return 1;
      }
    },
    dedicatedNumberAppServiceName() {
      return this.dedicatedNumberAppService ? this.dedicatedNumberAppService.name : '';
    },
    dedicatedNumberAppServiceItems() {
      return this.dedicatedNumberAppService ? this.dedicatedNumberAppService.items : [];
    },
    dedicatedNumberAppServiceItem() {
      return this.dedicatedNumberAppServiceItems
      && this.dedicatedNumberAppServiceItems.length > 0
        ? this.dedicatedNumberAppServiceItems[0]
        : null;
    },
    dedicatedNumberAppServiceItemName() {
      return this.dedicatedNumberAppServiceItem ? this.dedicatedNumberAppServiceItem.name : '';
    },
    dedicatedNumberAppServiceItemPrice() {
      return this.dedicatedNumberAppServiceItem ? this.dedicatedNumberAppServiceItem.price : 0;
    },
    dedicatedNumberAppServiceItemPriceByInterval() {
      if (this.selectedInterval === this.$enums.Tenants.APP_SERVICE_ITEM_BILLING_INTERVAL.MONTH) {
        return this.dedicatedNumberAppServiceItemPrice;
      }

      return this.dedicatedNumberAppServiceItemIntervals[this.selectedInterval].price;
    },
    dedicatedNumberAppServiceItemIntervalPriceMonthEquivalent() {
      return this.dedicatedNumberAppServiceItemPriceByInterval
        / this.selectedIntervalInMonthsNumber;
    },
    dedicatedNumberAppServiceItemIntervalDiscount() {
      return Math.ceil(100
        - (
          (this.dedicatedNumberAppServiceItemIntervalPriceMonthEquivalent * 100)
          / this.dedicatedNumberAppServiceItemPrice));
    },
    dedicatedNumberAppServiceItemFirstMonthFee() {
      return this.dedicatedNumberAppServiceItem
        ? this.dedicatedNumberAppServiceItem.firstMonthFee : 0;
    },
    dedicatedNumberAppServiceItemFirstMonthFeeName() {
      return this.dedicatedNumberAppServiceItem
        ? this.dedicatedNumberAppServiceItem.firstMonthFeeName : '';
    },
    dedicatedNumberAppServiceItemIntervals() {
      return this.dedicatedNumberAppServiceItem
        ? this.dedicatedNumberAppServiceItem.intervals : {};
    },
    dedicatedNumberAppServiceItemInactiveIntervalsKeys() {
      return Object.keys(this.dedicatedNumberAppServiceItemIntervals).filter(
        (key) => !this.dedicatedNumberAppServiceItemIntervals[key].active,
      );
    },
    firstMonthFeeCheckoutExtraItem() {
      return this.dedicatedNumberAppServiceItemFirstMonthFee > 0
        ? [{
          name: this.dedicatedNumberAppServiceItemFirstMonthFeeName,
          price: this.dedicatedNumberAppServiceItemFirstMonthFee,
          recurrent: false,
          onlyFirstPay: true,
        }]
        : [];
    },
  },
  methods: {
    async getDedicatedNumberAppService() {
      const resp = await appServiceService.getAppServiceByKey(
        this.$enums.AppServices.DefaultServices.DEDICATED_NUMBER,
      );

      this.dedicatedNumberAppService = resp.data;
    },
  },
};
